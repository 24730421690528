// Order Detail Receipts
.dln-kenwood {
  .dln-return-order-receipt {
    background: var(--ui-03);
    margin-bottom: 20px;
    padding: 30px 20px;

    .dln-return-order__header {
      margin-bottom: 15px;
      padding: 0;
      border: none;

      .dln-return-order__section-title {
        font-size: 20px;
      }
    }

    &__content {
      margin: 0;
    }
  }

  klarna-placement {
    margin: auto 10px 10px;
  }
}

// loqate dropdown menu
.dln-kenwood {
  .pca {
    position: relative;

    .pcaautocomplete {
      z-index: 1 !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}
