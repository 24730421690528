.ken-pdp__main-info__voltage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    font-family: var(--fontNameBold);
    font-size: 14px;
    color: $ken-color-black;
    text-transform: uppercase;
    flex: 0 0 100%;
  }

  &__button {
    @include ken-button--voltage;
    font-family: var(--fontNameBold);
    font-weight: bold;
    font-size: 20px;
    color: $ken-color-black;
    flex: 0 0 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    text-decoration: none;

    &--current {
      pointer-events: none;
      opacity: 1;
    }
  }
}