.ken-pdp__compare {
  padding: 150px 0;
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
  }

  &__title {
    @include ken-pdp-components-title;
    text-align: center;
    margin-bottom: 30px;
  }

  &__table {

    &-row,
    &-key,
    &-value {
      display: flex;
      justify-content: center;
    }

    &-row {
      flex-wrap: wrap;
      @include bp('ken-breakpoint-m') {
        border-top: 1px solid $ken-color-grey-s;
        &:nth-child(odd) {
          background-color: $ken-color-grey-xs;
        }
        &:nth-child(even) {
          background-color: $ken-color-white;
        }
      }

      &:last-child {
        border-bottom: 1px solid $ken-color-grey-s;
      }
    }

    &-key,
    &-value {
      padding: 8px 0;
      text-align: center;
      @include bp('ken-breakpoint-m') {
        padding: 5px;
        min-height: 55px;
      }
    }

    &-key {
      @include ken-pdp-compare-key;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      justify-content: center;

      &:nth-child(odd) {
        background-color: $ken-color-grey-xs;
      }
      &:nth-child(even) {
        background-color: $ken-color-white;
      }
      @include bp('ken-breakpoint-m') {
        width: 34%;
        background-color: transparent;

        &:nth-child(odd),
        &:nth-child(even) {
          background-color: transparent;
        }
      }
    }

    &-value {
      @include ken-pdp-compare-value;
      align-items: center;
      width: 50%;
      background-color: $ken-color-white;
      margin: 10px 0;

      @include bp('ken-breakpoint-m') {
        background-color: transparent;
        width: 33%;
        margin: 0;
      }

      &.--current {
        border-right: 1px solid $ken-color-grey-m;
        @include bp('ken-breakpoint-m') {
          order: -1;
          border-right: 0;
        }
      }

      &[hidden] {
        display: none;
      }

      .ken-icon--check {
        width: 18px;
        height: 18px;
      }
    }

    &__container {
      &.--open {
        +.ken-pdp__compare__table__show-cta {
          &::after {
            @extend .ken-icon;
            @extend .ken-icon--arrow-up-black;
          }
        }
      }
    }

    @include bp('ken-breakpoint-max-m', 'max') {
      &__container {
        position: relative;
        margin-top: 20px;

        &.--toggle {
          max-height: 250px;
          overflow: hidden;

          &.--open {
            max-height: unset;
          }
        }
      }
    }

    &__show-cta {
      display: block;
      margin: 10px auto 0;
      @include bp('ken-breakpoint-m') {
        display: none;
      }

      &::after {
        content: "";
        @extend .ken-icon;
        @extend .ken-icon--arrow-down-black;
        width: 10px;
        height: 10px;
        margin-left: 5px;
      }
    }
  }

  &__slide {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  &__view-more {
    margin-top: 23px;
    text-transform: uppercase;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    > .ken-pdp__compare__product,
    > .ken-pdp__compare__slider {
      width: 50%;
      padding: 0 3px;
      @include bp('ken-breakpoint-m') {
        width: auto;
        width: 265px;
        order: 1;
      }
    }

    > .ken-pdp__compare__product {
      border-right: 1px solid $ken-color-grey-m;

      @include bp('ken-breakpoint-m') {
        border: 0;
      }
    }

    > .ken-pdp__compare__slider {
      @include bp('ken-breakpoint-m') {
        order: 3;
      }

      .swiper-button {
        &-prev {
          @extend .ken-icon;
          @extend .ken-icon--arrow-left-rhubarb;
          top: 62%;
          left: 0;
          height: 17px;
          width: 17px;
      
          &.swiper-button-disabled {
            @extend .ken-icon--arrow-left-grey-l;
            cursor: initial;
          }

          &:after {
            content: unset;
          }
        }
      
        &-next {
          @extend .ken-icon;
          @extend .ken-icon--arrow-right-rhubarb;
          top: 62%;
          right: 0;
          height: 17px;
          width: 17px;
      
          &.swiper-button-disabled {
            @extend .ken-icon--arrow-right-grey-l;
            cursor: initial;
          }

          &:after {
            content: unset;
          }
        }
      }

      .swiper-button-disabled {
        display: none;
      }

      .ken-pdp__compare__slider {
        justify-content: center;
      }
    }

    > .ken-pdp__compare__table {
      width: 100%;
      @include bp('ken-breakpoint-m') {
        min-width: 265px;
        flex: 1;
        margin: 0 20px;
        order: 2;
      }
    }
  }

  &__product {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      margin-bottom: 25px;

      img {
        max-width: 100%;
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;
    }

    &__reviews {
      display: flex;
      align-items: center;
      margin-top: 3px;

      .ken-review-star {
        width: 16px;
        height: 16px;
        margin-right: -1px;
      }
    }

    &__rating {
      margin: 1px 0 0 6px;
    }

    &__name {
      @include ken-pdp-description-title;
      text-align: center;
      text-decoration: none;
    }

    &__code {
      @include ken-pdp-compare-code;
    }

    &__prices {
      margin-top: 15px;
    }

    &__price {
      @include ken-pdp-compare-price;
    }

    &__oldprice {
      @include ken-pdp-compare-strike;
      margin-left: 10px;
    }
  }
}
