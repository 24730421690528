.ken-header-v2 {
  .dln-drawer {
    &__title {
      margin: 0 0 16px;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      color: $ken-color-rhubarb;
    }

    &--account {
      position: relative;
      visibility: visible;
      flex-direction: column;
      height: fit-content;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid $ken-color-grey-m;
      border-top: 2px solid $ken-color-rhubarb;
      text-align: start;

      [dir="rtl"] & {
        transform: translateX(76px);
      }

      &:before {
        left: 50%;
        bottom: unset;
        top: -8px;
        transform: translateX(-50%);
        margin-left: 0;
        border: 3px solid transparent;
        border-bottom-color: $ken-color-rhubarb;
      }

      &:after {
        display: none;
      }

      .user--notLogged {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $ken-color-grey-m;

        .ken-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 5px;
          padding: 11px 20px;
          font-family: var(--fontNameSemibold);
          font-size: 12px;
          font-weight: 600;
          line-height: 1.92;
          letter-spacing: 0.5px;
          text-align: center;
          text-transform: uppercase;
          color: $ken-color-white;
        }

        .ken-text {
          font-family: var(--fontNameRegular);
          font-size: 12px;
          font-weight: 300;
          line-height: 1.67;
          color: $ken-color-grey-xl;

          a {
            font-family: var(--fontNameSemibold);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.67;
            color: $ken-color-black;
            text-decoration: underline;
          }
        }
      }

      .utilityLinks {
        display: flex;
        flex-direction: column;

        a {
          margin-bottom: 10px;
          font-family: var(--fontNameRegular);
          font-size: 12px;
          letter-spacing: 0.2px;
          color: $ken-color-black;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &--logged {
        .utilityLinks {
          a {
            font-size: 14px;
          }

          hr {
            margin-bottom: 10px;
            border: none;
            border-bottom: 1px solid $ken-color-grey-m;
          }
        }
      }
    }
  }
}
