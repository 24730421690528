.ken-ecommercefocus {

  &--itemswrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include bp('ken-breakpoint-s') {
      flex-direction: row;
    }
  }

  &--item {
    padding: 20px 0;
    width: 100%;

    @include bp('ken-breakpoint-s') {
      display: flex;
      width: 224px;
      padding: 0 20px;
      justify-content: center;
      align-items: center;
    }
  }
}
