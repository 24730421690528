$ken-bppb-body-xspacing: 8.334%;  /* 1200-((1440-1200)/2) */
$ken-bppb-textblock-gap: 4.352%;  /* 47px<==1200-((1440-1200)/2) */
$ken-bppb-bgimg-maxh: 550px;
$ken-bppb-bgimg-maxw: 58.334%;    /* 840px<==>1440 */
$ken-bppb-mainimg-maxh: 460px;
$ken-bppb-mainimg-maxw: 42.5926%; /* 460px<==1200-((1440-1200)/2) */

.ken-bestproductpromobanner {
  overflow: hidden;

  &__bglayer,
  &__mask {
    display: none;
  }

  &__body {
    margin: 40px 20px 50px;

    &__mainimage {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &-img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }


    &__textblock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 20px 0;
      color: $ken-color-black;


      &-kicker,
      &-title,
      &-content {
        text-align: center;
      }

      &-kicker {
        margin: 0 0 10px;
        color: inherit;
        font-family: var(--fontNameRegular);
        font-size: 12px;
        line-height: 1.67;
        text-transform: uppercase;
        letter-spacing: .8px;
      }

      &-title {
        @include ken-text-h2;
        color: inherit;
      }

      &-content {
        margin-top: 10px;
        color: inherit;
        font-family: var(--fontNameRegular);
        font-size: 16px;
        line-height: 1.5;
      }

      &-cta {
        @extend .ken-button--primary;
        margin-top: 30px;
        align-self: center;
        text-transform: uppercase;
      }
    }
  }

  @include bp('ken-breakpoint-s') {
    position: relative;
    max-height: $ken-bppb-bgimg-maxh;
    min-height: $ken-bppb-mainimg-maxh;
    display: flex;
    justify-content: flex-end;

    &__bglayer {
      width: 100%;
      max-width: $ken-bppb-bgimg-maxw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-img {
        height: auto !important;
        width: auto !important;
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__mask {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $ken-color-white-mask-70;
      /*
      background: linear-gradient(to bottom, $ken-color-white-mask-70 100%);
      */
    }

    &__body {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      margin: 0;
      width: auto;
      padding: 0 0 0 $ken-bppb-body-xspacing;
      display: flex;
      justify-content: flex-start;
      background-color: $ken-color-none;
      color: inherit;

      &__mainimage {
        margin: 0;
        max-width: $ken-bppb-mainimg-maxw;
      }

      &__textblock {
        justify-content: center;
        padding: 0 0 0 $ken-bppb-textblock-gap;
        width: 50%;

        @include bp('ken-breakpoint-m') {
          width: 36.667%;
        }

        &-kicker,
        &-title,
        &-content {
          text-align: left;
        }

        &-kicker {
          margin: 0 0 2px;
          font-size: 11px;

          @include bp('ken-breakpoint-m') {
            font-size: 12px;
          }
        }

        &-title {
          font-size: 26px;

          @include bp('ken-breakpoint-m') {
            font-size: 28px;
          }
          @include bp('ken-breakpoint-l') {
            font-size: 32px;
          }
        }

        &-content {
          margin-top: 18px;
          font-size: 13px;

          @include bp('ken-breakpoint-m') {
            font-size: 14px;
          }
          @include bp('ken-breakpoint-l') {
            font-size: 16px;
          }
        }

        &-cta {
          margin-top: 20px;
          align-self: flex-start;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-bestproductpromobanner {
    @include bp('ken-breakpoint-s') {
      &__body {
        &__textblock {
          padding: 0 $ken-bppb-textblock-gap 0 0;

          &-kicker,
          &-title,
          &-content {
            text-align: right;
          }
        }
      }
    }
  }
}
