.ken-compare {
  &__popup__top {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: $ken-color-cheddar;
    padding: 0 20px;
    overflow: hidden;
    max-height: 60px;

    button {
      background: none;
      border: none;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        font-family: var(--fontNameBold);
        font-weight: bold;
        font-size: 13px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        color: $ken-color-black;
        width: calc(100% - 40px);
      }

      i {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 1px solid $ken-color-rhubarb;
        border-radius: 50%;
        margin-left: 10px;

        &::before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          border: 2px solid $ken-color-rhubarb;
          border-top: 0;
          border-right: 0;
          top: calc(50% - 2.5px);
          left: 50%;
          transform: translate(- 50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}