.ken-pdp__faqs {
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
  }

  &__title {
    @include ken-pdp-components-title;
  }

  &__accordion {
    background-color: $ken-color-grey-xs;
    padding: 18px 20px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }

    label {
      cursor: pointer;
    }

    &__title {
      @include ken-pdp-faq-accordion-title;
      width: calc(100% - 14px);
      margin-right: 14px;
    }

    &__content {
      margin-top: 20px;

      p {
        @include ken-pdp-description-content;
      }
    }

    &__content .videoWrapper {
      height: 300px;

      @include bp('ken-breakpoint-s') {
        height: 465px;
      }
    }

    &__content iframe {
      border: none;
    }
  }

  &__cta {
    @include ken-text-link;
  }
}
