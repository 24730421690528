$rwd-d-slidew: 23.75%;

.ken-cookwithus {
  overflow: hidden;
  margin: 35px 20px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a,
  a:link,
  a:visited {
    text-decoration: none;
  }

  &__title {
    @include ken-text-h2;
    margin-bottom: 28px;
  }

  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      padding: 0;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-s') {
      width: $rwd-d-slidew;
      max-width: $rwd-d-slidew;
    }
  }

  .swiper-footer {
    max-width: none;
    margin: 30px 0 0;
    width: 100%;
    padding: 0;

    @include bp('ken-breakpoint-s') {
      display: none
    }

    .swiper-scrollbar {
      width: 100%;
      height: 3px;
      background-color: $ken-color-grey-s;

      margin: 0;
      padding-top: 0;

      &-drag {
        background-color: $ken-color-rhubarb;
      }
    }
  }

  @include bp('ken-breakpoint-s') {
    margin: 35px 20px;

    &__title {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1240px) {
    margin: 35px auto;
  }
}
