$ken-article-margin-right: 47px;

.ken-keyfeatures {
  padding: 30px 0 20px;

  .ken-keyfeatures {
    &-wrapper {
      @include bp('ken-breakpoint-s') {
        display: flex;
        flex-wrap: wrap;
      }

      &--center {
        @include bp('ken-breakpoint-s') {
          justify-content: center;
        }
      }

      &--start {
        justify-content: flex-start;
      }

      &--three {
        margin: 0 auto;
        @include bp('ken-breakpoint-m') {
          max-width: 890px;
        }

        .ken-keyfeatures__article {
          @include bp('ken-breakpoint-s') {
            flex: 0 0 calc(33.3333% - #{($ken-article-margin-right * 2) / 3});
          }

          &:nth-child(3n+3) {  
              margin: 0;
          }
        }
      }

      &--four {
        .ken-keyfeatures__article {
          @include bp('ken-breakpoint-s') {
            flex: 0 0 calc(25% - #{($ken-article-margin-right * 3) / 4});
          }

          &:nth-child(4n+4) {  
              margin: 0;
          }
        }
      }
    }

    &__title {
      @include ken-text-h1;
      text-align: center;
      margin: 0 0 26px 0;
    }

    &__article {
      display: flex;
      flex-direction: column;
      padding: 0;

      @include bp('ken-breakpoint-s') {
        margin: 0 $ken-article-margin-right 0 0;
      }
      
      &__img {
        width: 100%;
        height: auto;
      }

      &__title {
        @include  ken-text-h4;
        margin-bottom: 15px;
        @include bp('ken-breakpoint-s') {
          margin-bottom: 7px;
        }
      }

      &__desc {
        @include ken-text-body-mont;
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      min-height: 180px;
      padding: 20px 0;

      @include bp('ken-breakpoint-s') {
        min-height: 200px;
      }
    }

    &__swiper-scrollbar-wrapper {
      @include bp('ken-breakpoint-s') {
        display: none;
      }

      .swiper-scrollbar {
        position: initial;
        width: 80%;
        margin-left: 12%;
        height: 3px;
        border-radius: 0;
        background-color: $ken-color-grey-s;
    
        &-drag {
          border-radius: 0;
          background-color: $ken-color-rhubarb;
        }
      }
    }
  }
}
