.ken-pdp__sticky {
  position: fixed;
  top: 0;
  transform: translateY(-140px);
  transition: top 0.3s ease-out, transform 0.3s ease-in-out;

  &.--active {
    transform: translateY(0); /* TODO: Change when header will be sticky */

    @include bp('ken-breakpoint-m') {
      transform: translateY(0); /* TODO: Change when header will be sticky */
    }
  }

  &.--plus-header {
    top: 69px;

    @include bp('ken-breakpoint-m') {
      top: 99px;
    }

    @include bp('ken-breakpoint-l') {
      top: 83px;
    }
  }

  width: 100%;
  background-color: $ken-color-white;
  z-index: 3;

  border-bottom: 1px solid $ken-color-grey-m;

  @include bp('ken-breakpoint-m') {
    box-shadow: 0 0 10px 0 $ken-color-grey-m;
    border-bottom: 0;
  }

  &__wrapper {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include bp('ken-breakpoint-m') {
      padding: 10px 10px 0;
    }

    &__left {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      padding: 16px 20px;

      @include bp('ken-breakpoint-m') {
        flex-basis: 50%;
        padding: 0;
      }
    }

    &__right {
      justify-content: flex-end;
      flex-basis: 50%;
      display: none;
      align-items: center;

      @include bp('ken-breakpoint-m') {
        display: flex;
      }
    }
  }

  &__image {
    display: none;
    @include bp('ken-breakpoint-m') {
      display: block;
      width: 78px;
    }
  }

  &__title {
    @include ken-pdp-sticky-menu-product-title;
  }

  &__code {
    @include ken-pdp-sticky-menu-product-code;
    display: none;
    @include bp('ken-breakpoint-m') {
      display: block;
    }
  }

  &__toggle {
    position: relative;
    padding-right: 30px;
    width: 100%;
    @include bp('ken-breakpoint-m') {
      pointer-events: none;
    }

    &::after {
      @extend .ken-icon;
      @extend .ken-icon--arrow-down-black;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 17px;
      width: 17px;
      @include bp('ken-breakpoint-m') {
        content: unset;
      }
    }

    &.--open {
      &::after {
        @extend .ken-icon--arrow-up-black;
      }
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    height: 0;
    width: 100%;
    overflow: hidden;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      height: unset;
      flex-basis: 100%;
      padding: 0 5px;
      justify-content: center;
      align-items: center;
      overflow: unset;
    }

    &.--open {
      padding: 0 20px;
      height: unset;
      overflow: unset;
      align-items: flex-start;
      border-top: 1px solid $ken-color-grey-m;
    }
  }

  &__link {
    @include ken-pdp-sticky-menu-sections;
    text-decoration: none;
    border-bottom: 1px solid $ken-color-grey-m;
    width: 100%;
    text-align: left;
    margin: 10px 0 0;
    padding: 0 0 10px;

    &:last-child {
      border-bottom: 0;
    }

    &.--active {
      @include bp('ken-breakpoint-m') {
        border-bottom: 3px solid $ken-color-rhubarb;
      }
    }

    @include bp('ken-breakpoint-m') {
      margin: 0 20px;
      padding: 0;
      border-bottom: 1px solid transparent;
      text-align: center;
      width: auto;
    }
  }

  &__price {
    @include ken-pdp-price-current;
    margin-right: 20px;
    display: none;

    &--strike {
      @include ken-pdp-price-strike;
    }

    @include bp('ken-breakpoint-m') {
      display: block;
    }
  }

  &__cta {
    width: 286px;
    text-transform: uppercase;
    display: none;
    @include bp('ken-breakpoint-m') {
      display: block;
    }
  }
}
