.ken-footer {

  .ken-benefit {
    width: 100%;

    &__container {
      width: 100%;

      @include bp('ken-breakpoint-s') {
        border-top: 1px solid $ken-color-grey-m;
      }
    }

    &__wrapper {
      padding-bottom: 40px;

      @include bp('ken-breakpoint-s') {
        padding-bottom: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding: 30px;
      text-align: center;

      @include bp('ken-breakpoint-s') {
        width: 33%;
        max-width: 33%;
      }
    }

    &__img {
      $size: 40px;
      height: $size;
      width: $size;
    }

    &__text {
      font-family: var(--fontNameRegular);
      font-size: 14px;
      line-height: 1.57;
    }

    &__desc {
      font-family: var(--fontNameRegular);
      font-size: 13px;
      font-weight: 300;
      line-height: 1.69;
    }

    .swiper-scrollbar {
      width: calc(100% - 40px);
      left: 20px;
      bottom: 43px;
      height: 3px;
      border-radius: 0;
      background-color: $ken-color-grey-s;

      &-drag {
        border-radius: 0;
        background-color: $ken-color-rhubarb;
      }
    }
  }
}
