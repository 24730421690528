.ken-plp__filters {
  &__desktop-bar__wrapper {
    display: none;

    @include bp('ken-breakpoint-s') {
      min-height: 50px;
      display: block;
    }

    &.--sticky {
      .ken-plp__filters__desktop-bar {
        position: fixed;
        z-index: 3;
        top: 0;
        transition: top 0.3s ease-out;
        animation: showStickyBar .5s normal forwards ease-in-out;
      }

      &.--plus-header {
        .ken-plp__filters__desktop-bar {
          top: 69px;

          @include bp('ken-breakpoint-m') {
            top: 99px;
          }

          @include bp('ken-breakpoint-l') {
            top: 83px;
          }
        }
      }
    }
  }

  &__desktop-bar {
    display: flex;
    padding: 15px 20px 10px;
    width: 100%;
    background-color: $ken-color-white;
    border-bottom: 1px solid $ken-color-grey-m;

    @include bp('ken-breakpoint-l') {
      padding: 15px 0 10px;
    }

    &__content {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    &__filter-label {
      padding-top: 1px;
      min-width: 80px;
    }

    &__filter-content {
      flex-grow: 2;
    }

    &__filter-label,
    &__sort button,
    &__sort button span {
      font-family: var(--fontNameLight);
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $ken-color-black;
    }

    &__sort {
      position: relative;
      min-width: 280px;
      text-align: right;
      button {
        border: none;
        background: none;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        span {
          font-family: var(--fontNameSemibold);
          margin: 0 5px;
        }
        i {
          width: 13px;
          height: 13px;
          transition: all .3s ease-in-out;
        }
      }

      &.--open {
        button i {
          transform: rotate(180deg);
        }
      }

      &__overlay {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .3s .2s ease-in-out;
        z-index: 5;
        width: 280px;
        max-height: 300px;
        overflow-y: auto;
        right: 0;
        transform: translateY(-5px);

        &.--open {
          visibility: visible;
          opacity: 1;
        }

        &__content {

          background-color: $ken-color-white;
          border: 1px solid $ken-color-black;
          padding: 15px;
          margin-top: 15px;

          &:hover {
            button {
              opacity: .5;
            }
          }

          button {
            border: none;
            background: none;
            width: 100%;
            transition: opacity .2s ease-in-out;

            span {
              font-family: var(--fontNameLight);
              font-size: 13px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.15;
              letter-spacing: normal;
              color: $ken-color-black;
              padding: 10px 0 5px;
              border-bottom: 2px solid transparent;
            }

            &.--selected {
              span {
                font-family: var(--fontNameSemibold);
                border-bottom-color: $ken-color-black;
              }
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
