.ken-plp__promo-banner {
  display: none;
  .ken-promobanner {
    padding: 0!important;
  }
}

.--has-promo-banner .ken-plp__promo-banner,
.is-smartedit .ken-plp__promo-banner {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  margin: 0 10px 20px;

  @include bp('ken-breakpoint-m') {
    width: calc(66.6% - 20px);
  }

  @include bp('ken-breakpoint-l') {
    width: calc(50% - 20px);
  }
}