.ken-product-tile {
  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    min-height: 250px;

    img {
      display: block;
      max-width: 100%;
    }

    a,
    span {
      display: none;
    }

    .--out-of-stock & {
      img {
        opacity: .5;
      }
      span {
        display: block;
        position: absolute;
        padding: 8px 14px;
        background-color: $ken-color-purple;
        font-family: var(--fontNameSemibold);
        font-size: 13px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $ken-color-white;
        max-width: calc(100% - 20px);
      }
    }

    .--lite & {
      a:not(:first-child) {
        display: none;
      }
    }
  }
}