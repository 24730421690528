.save-for-later-popup-wrapper {
  .ken-configurator__popup-content {
    height: 90%;

    @include bp('ken-breakpoint-m') {
      height: unset;
    }
  }

  &--confirm {
    .ken-configurator__popup-content {
      height: 60%;
      @include bp('ken-breakpoint-m') {
        height: unset;
      }
    }
  }

  .save-for-later-popup {
    display: flex;
    height: 100%;

    @include bp('ken-breakpoint-m') {
      max-width: 768px;
      max-height: 560px;
      height: unset;
    }

    .ken-configurator {
      &__generic-popup-close {
        display: none;
        @include bp('ken-breakpoint-m') {
          display: inline-block;
        }
      }
      &__generic-popup-content {
        padding: 32px 22px 22px 22px;

        @include bp('ken-breakpoint-m') {
          padding: 52px 52px 32px 52px;
        }
        .ken-configurator__generic-popup-title {
          font-size: 16px;
          line-height: unset;

          @include bp('ken-breakpoint-m') {
            font-size: 24px;
            line-height: 31.2px
          }
        }
        .ken-configurator__generic-popup-desc {
          font-family: var(--fontNameRoman);
          font-size: 10px;

          @include bp('ken-breakpoint-m') {
            font-size: 12px;
          }
        }
        .ken-configurator__generic-popup-form {
          padding-bottom: 10px;
          @include bp('ken-breakpoint-m') {
            padding-bottom: 50px;
          }
        }
      }
      &__generic-popup-ctas {
        justify-content: center;
        flex-direction: row;

        @include bp('ken-breakpoint-m') {
          position: absolute;
          bottom: 28px;
          right: 28px;
          justify-content: flex-end;
        }

        &__cta {
          &.ken-button--primary {
            flex-grow: 1;
            text-transform: uppercase;

            @include bp('ken-breakpoint-m') {
              flex-grow: unset;
            }
          }
          &.ken-button--secondary {
            border: 0;
            text-transform: uppercase;
          }
        }
      }
      &__generic-popup-picture {
        overflow: hidden;
        display: none;
        justify-content: center;
        align-items: center;
        height: inherit;
        max-width: 25%;

        img {
          height: 100%;
        }

        @include bp('ken-breakpoint-m') {
          display: flex;
        }
      }
      &__generic-popup-form {
        &.align-bottom {
          justify-content: flex-end;
        }
        input[type=email] {
          max-width: 100%;

          @include bp('ken-breakpoint-m') {
            max-width: 60%;
          }
        }
        .checkbox-input-wrapper {
          display: flex;
          column-gap: 10px;

          input[type="checkbox"] {
            appearance: none;
            cursor: pointer;
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 2px solid black;
            border-radius: 3px;
            display: flex;
            justify-content: center;
          }
          input[type="checkbox"]:after {
            content: "\E0";
            color: var(--brand-01);
            font-family: var(--fontNameIcons);
            display: none;
          }
          input[type="checkbox"]:checked {
            border-color: var(--brand-01);
          }
          input[type="checkbox"]:checked:after {
            display: block;
          }
          label {
            cursor: pointer;
          }
        }
        .required-tag {
          color: var(--brand-01);
        }
        &__email-input-wrapper {
          label {
            color: var(--brand-04);
            font-size: 10px;

            @include bp('ken-breakpoint-m') {
              font-size: 12px;
            }
          }
        }
      }
    }
    &--confirm {
      flex-direction: column;
      @include bp('ken-breakpoint-m') {
        max-height: 400px;
        flex-direction: row;
      }
      .ken-configurator {
        &__generic-popup-content {
          padding: 32px 32px 22px 32px;
          flex-grow: 1;

          @include bp('ken-breakpoint-m') {
            padding: 52px 82px 32px 82px;
            justify-content: center;
            flex-grow: unset;
          }
          .ken-configurator__generic-popup-title {
            font-size: 18px;

            @include bp('ken-breakpoint-m') {
              font-size: 24px;
            }
          }
          .ken-configurator__generic-popup-desc {
            font-size: 12px;
          }
        }
        &__generic-popup-ctas {
          position: absolute;
          bottom: 20px;
          left: 20px;
          right: 20px;

          @include bp('ken-breakpoint-m') {
            left: unset;
          }

          &__cta {
            &.ken-button--primary {
              @include bp('ken-breakpoint-m') {
                flex-grow: unset;
              }
            }
          }
        }
        &__generic-popup-picture {
          display: flex;
          max-width: unset;
          max-height: 40%;

          img {
            width: 100%;
            height: unset;

            @include bp('ken-breakpoint-m') {
              height: 100%;
              width: unset;
            }
          }
          @include bp('ken-breakpoint-m') {
            display: flex;
            max-width: 25%;
          }
        }
      }
    }
  }
}