.ken-configurator__carousel {
  width: 100%;
  background-color: $ken-color-white;
  position: relative;

  @include bp('ken-breakpoint-m') {
    min-height: 430px;
    width: 100%;
    max-width: 900px;
    margin-right: 10px;
  }

  &--wrapper {
    display: flex;
    padding: 40px 0 20px;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;

    @include bp('ken-breakpoint-m') {
      width: 50%;
      height: 100%;
      padding: 40px 40px 20px;
    }
  }

  &--swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 300px;
    width: 100%;
    padding-bottom: 20px;

    @include bp('ken-breakpoint-m') {
      width: 50%;
      min-height: 0;
      padding-bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .swiper-container {
    height: calc(100% - 98px);
    width: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 0;
    border: 0;
    background: none;

    &::after {
      content: ''
    }

    @include bp('ken-breakpoint-m') {
      top: 50%;
    }
  }

  .swiper-button-next {
    @include bp('ken-breakpoint-m') {
      top: 0;
      right: 43px;
    }
  }

  .swiper-button-prev {
    transform: scaleX(-1);

    @include bp('ken-breakpoint-m') {
      top: 0;
      left: auto;
      right: 82px;
    }
  }

  .swiper-container-3d {

    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      background-image: none;
    }
  }

  &--slide-title,
  &--slide-indicator {
    margin-bottom: 0;
    width: 100%;
    padding: 0 40px;
    text-align: center;

    @include bp('ken-breakpoint-m') {
      text-align: left;
      padding: 0;
    }
  }

  &--slide-title {
    text-transform: uppercase;
    height: 48px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    justify-content: center;
    font-size: 16px;
    font-family: var(--fontNameBold);
    line-height: 24px;
    color: $ken-color-black;

    @include bp('ken-breakpoint-m') {
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 20px;
    }
  }

  &--slide-indicator {
    font-family: var(--fontNameLight);
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;

    @include bp('ken-breakpoint-m') {
      color: $ken-color-rhubarb;
      font-family: var(--fontNameBold);
      text-align: left;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &--link-to-details {
    border: 0;
    background: none;
    display: flex;
    text-align: center;
    margin: 0 auto;
    text-decoration: underline;
    font-weight: bold;
    color: $ken-color-black;
    cursor: pointer;
    margin-top: 10px;

    @include bp('ken-breakpoint-m') {
      display: none;
    }
  }

  &--slide-description {
    font-family: var(--fontNameLight);
    font-size: 12px;
    line-height: 18px;
    color: $ken-color-black;
    flex-grow: 3;
    max-height: 180px;
    margin: auto 40px 20px;
    text-align: justify;

    @include bp('ken-breakpoint-m') {
      margin: auto 0 20px;
      text-align: left;
    }
  }

  &--color-swatch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;

    @include bp('ken-breakpoint-m') {
      flex-direction: column;
      align-items: flex-start;
    }

    &-label {
      font-size: 14px;
      font-family: var(--fontNameBold);
      margin-right: 16px;
      margin-left: 40px;
      width: 135px;

      @include bp('ken-breakpoint-m') {
        margin: 0;
        width: auto;
        font-size: 12px;
      }
    }

    &-wrapper {
      display: flex;
      overflow-x: auto;
      width: calc(100% - 191px); // 135 + 40 + 16 from label
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include bp('ken-breakpoint-m') {
        width: 100%;
        justify-content: flex-start;
        margin-top: 16px;
        overflow-x: initial;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-item {
      padding: 6px 14px 6px 7px;
      margin-right: 8px;
      border: 1px solid $ken-color-grey-m;
      border-radius: 25px;
      display: flex;
      justify-items: center;
      align-items: center;
      cursor: pointer;
      background-color: $ken-color-white;

      @include bp('ken-breakpoint-m') {
        margin-bottom: 8px;
        padding: 6px 8px 6px 7px;
      }

      &:last-of-type {
        margin-right: 40px;

        @include bp('ken-breakpoint-m') {
          margin-right: 8px;
        }
      }

      &.--active {
        border-color: $ken-color-rhubarb;
        background-color: $ken-color-rhubarb-08;
      }
    }

    &-item-color {
      height: 20px;
      width: 20px;
      background-color: $ken-color-black;
      margin-right: 8px;
      border-radius: 50%;
      flex-shrink: 0;

      @include bp('ken-breakpoint-m') {
        height: 12px;
        width: 12px;
      }

      &.--active {
        position: relative;

        // Checkmark
        &::after {
          content: '';
          position: absolute;
          top: 40%;
          left: 55%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 6px;
          height: 12px;
          border: solid $ken-color-white;
          border-width: 0 1px 1px 0;

          @include bp('ken-breakpoint-m') {
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 4px;
            height: 8px;
          }
        }
      }
    }

    &-item-label {
      font-size: 12px;
      font-family: var(--fontNameBold);
      text-transform: uppercase;

      @include bp('ken-breakpoint-m') {
        font-size: 8px;
      }
    }
  }

  &--slide-price {
    font-family: var(--fontNameBold);
    font-size: 14px;
    line-height: 140%;
    color: $ken-color-black;
    margin: auto 40px 16px;

    @include bp('ken-breakpoint-m') {
      margin: auto 0 16px;
    }
  }

  &--slide-image {
    &.swiper-slide {
      opacity: 0.7;

      &.swiper-slide-active {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--pagination {
    display: none;

    @include bp('ken-breakpoint-m') {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .swiper-pagination-bullet {
        margin: 0 6px;
      }

      .swiper-pagination-bullet-active {
        background-color: $ken-color-rhubarb;
      }
    }
  }
}