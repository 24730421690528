.ken-configurator__accessories-menu {
  display: none;
  padding-bottom: 30px;
  min-height: calc(100vh - 194px);
  position: relative;

  @include bp('ken-breakpoint-m') {
    filter: drop-shadow(0px 0px 16px rgba(51, 51, 51, 0.1));
    background-color: $ken-color-white;
    padding-bottom: 0px;
    height: 430px;
    display: block;
    min-height: auto;
  }

  &.-mobile-open {
    display: block;
  }

  &-lvl1,
  &-lvl2,
  &-lvl3 {
    background-color: $ken-color-white;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    @include bp('ken-breakpoint-m') {
      height: 430px;
    }
  }

  &-lvl1:not(.-active),
  &-lvl2:not(.-active),
  &-lvl3:not(.-active) {
    display: none;

    @include bp('ken-breakpoint-m') {
      display: block;
    }
  }

  &-lvl2 {
    @include bp('ken-breakpoint-m') {
      left: 285px;
      overflow-y: auto;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.0555) 0%, rgba(0, 0, 0, 0) 9.17%), #FFFFFF;
      box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);
      width: 400px;
    }

    @include bp('ken-breakpoint-l') {
      width: 450px;
    }

    .ken-configurator__accessories-menu-wrapper {
      height: calc(100% - 175px);

      @include bp('ken-breakpoint-m') {
        height: 100%;
      }
    }
  }

  &-lvl3 {
    @include bp('ken-breakpoint-m') {
      left: 685px;
      overflow-y: auto;
      width: 300px;
      box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.0555) 0%, rgba(0, 0, 0, 0) 3.1%), #FFFFFF;
    }

    @include bp('ken-breakpoint-l') {
      left: 735px;
      width: 560px;
    }

    .ken-configurator__accessories-menu-wrapper {
      height: calc(100% - 175px);

      @include bp('ken-breakpoint-m') {
        height: 100%;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    width: 100%;
    border: none;
    align-items: center;
    background-color: transparent;
    padding: 16px 20px;
    border-bottom: 1px solid $ken-color-grey-xs;
    cursor: pointer;

    &.-no-pointer {
      cursor: default;
    }

    &-img-wrapper {
      height: 40px;
      width: 40px;
      margin-right: 16px;
      position: relative;
    }

    &-img {
      height: 40px;
      width: 40px;
    }

    &-micro-quantity {
      position: absolute;
      top: 0;
      left: 0;
      height: 17px;
      width: 17px;
      background-color: $ken-color-rhubarb;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $ken-color-white;
      font-size: 12px;
    }

    &-label {
      font-size: 14px;
      font-family: var(--fontNameMedium);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-macro-quantity {
      font-size: 14px;
      font-family: var(--fontNameSemibold);
      color: $ken-color-rhubarb;
      margin-left: 10px;

      @include bp('ken-breakpoint-m') {
        margin-left: 10px;
      }
    }

    &-chevron {
      margin-left: auto;

      &.-second-level {
        @include bp('ken-breakpoint-m') {
          display: none;
        }

        &.-has-quantity {
          margin-left: 10px;
        }
      }
    }

    &-active-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: $ken-color-rhubarb;
      display: none;
    }

    &.-active {
      .ken-configurator__accessories-menu-item-active-bar {
        display: block;
      }

      .ken-configurator__accessories-menu-item-chevron {
        transform: rotate(180deg);
      }
    }

    &.-disabled {
      display: none;
    }
  }

  &-link-btn {
    background-color: transparent;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid;
    padding: 0;
    cursor: pointer;
  }

  &-back {
    background-color: $ken-color-grey-xs;

    @include bp('ken-breakpoint-m') {
      display: none;
    }

    &-img {
      padding: 0 15px;
    }

    &-category-img {
      &-wrapper {
        width: 25px;
        height: 25px;
        margin-right: 15px;
        position: relative;
      }

      &-img {
        width: 100%;
        height: 100%;
        filter: brightness(1.05);
      }

      &-filter {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, .03);
      }
    }

    &-label {
      font-size: 14px;
      font-family: var(--fontNameSemibold);
    }
  }

  &-product {
    background-color: $ken-color-white;
    height: 72px;
    padding: 0;
    display: flex;

    &.-hidden {
      display: none;
    }

    &-img-wrapper {
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $ken-color-white;
      flex-shrink: 0;
      border-bottom: 1px solid $ken-color-grey-xs;
    }

    &-img {
      width: 58px;
    }

    &-content-wrapper {
      background-color: $ken-color-grey-xs;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
    }

    &-label {
      font-size: 12px;
      padding-right: 5px;

      strong {
        font-family: var(--fontNameSemibold);
        @include lineClamp(2);
      }
    }

    &-price {
      padding-top: 7px;
      font-size: 12px;
      color: $ken-color-grey-xl;
      font-family: var(--fontNameRegular);
    }

    &-remove {
      margin-left: auto;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.65;
      }
    }
  }

  &-wrapper {
    height: calc(100% - 175px);
    overflow-y: auto;

    &.-no-product {
      height: 100%;
    }

    @include bp('ken-breakpoint-m') {
      height: calc(100% - 72px);

      &.-no-product {
        height: 100%;
      }
    }
  }

  &-count {
    padding: 30px 20px;
    font-size: 14px;
    font-family: var(--fontNameSemibold);
  }

  &-accessory {
    padding: 32px 20px;
    height: calc(100vh - 260px);
    overflow-y: auto;

    @include bp('ken-breakpoint-m') {
      height: 100%;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        font-size: 16px;
        font-family: var(--fontNameSemibold);
      }

      &-price {
        padding-top: 7px;
        font-size: 14px;
        font-family: var(--fontNameRegular);
      }

      &-add {
        display: flex;
        align-items: center;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        span {
          display: none;

          @include bp('ken-breakpoint-s') {
            display: inline-block;
          }
        }

        img {
          @include bp('ken-breakpoint-s') {
            margin-left: 10px;
          }
        }
      }

      &-quantity {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 34px;
        background-color: rgba($ken-color-rhubarb, 0.1);
        margin: 24px 0;

        @include bp('ken-breakpoint-m') {
          display: none;
        }

        &-number {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $ken-color-rhubarb;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $ken-color-white;
          font-size: 12px;
          font-family: var(--fontNameSemibold);
        }

        &-label {
          margin-left: 10px;
          font-family: var(--fontNameSemibold);
        }

        &-remove {
          margin-left: auto;
          margin-right: 20px;
        }
      }

      &-description {
        padding-top: 24px;
        padding-bottom: 34px;
        font-size: 12px;
        line-height: 18px;
        font-family: var(--fontNameRegular);
      }

      &-img {
        max-width: 100%;
      }
    }
  }
}