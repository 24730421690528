.ken-editorialarticlelist {
  padding: 100px 0 80px;

  @include bp('ken-breakpoint-m') {
    padding: 60px 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__main-title {
    margin-bottom: 8px;
    font-family: var(--fontNameExtra);
    font-size: 28px;
    line-height: 1.21;
    text-align: center;
    color: $ken-color-black;

    @include bp('ken-breakpoint-m') {
      margin-bottom: 30px;
      font-size: 32px;
      line-height: 1.25;
    }
  }

  &__articles-container {
    display: flex;
    flex-direction: column;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
    }
  }
}
