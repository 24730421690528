.ken-reccomandations-carousel {
  background-color: $ken-color-grey-xs;
  padding: 60px 20px;

  @include bp('ken-breakpoint-s') {
    padding: 100px 0;
  }

  &__title {
    @include ken-pdp-components-title;
    text-align: center;
    margin-bottom: 30px;
  }

  &__date {
    @include ken-pdp-text-small;
    color: $ken-color-grey-xxl;
    margin-bottom: 8px;
  }

  &__description {
    @include ken-pdp-description-content;
    margin-bottom: 30px;
  }

  &__swiper-scrollbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;

    .swiper-scrollbar {
      position: initial;
      width: 100%;
      height: 3px;
      border-radius: 0;
      background-color: $ken-color-grey-s;

      @include bp('ken-breakpoint-s') {
        width: 85%;
      }

      &-drag {
        border-radius: 0;
        background-color: $ken-color-rhubarb;
      }
    }
  }

  &__swiper-arrows-wrapper {
    width: 12%;
    justify-content: space-around;
    display: none;

    @include bp('ken-breakpoint-s') {
      display: flex;
    }

    .swiper {
      &-prev {
        @extend .ken-icon;
        @extend .ken-icon--arrow-left-rhubarb;
        height: 17px;
        width: 17px;
        display: flex;

        &.swiper-button-disabled {
          @extend .ken-icon--arrow-left-grey-l;
          cursor: initial;
        }
      }
  
      &-next {
        @extend .ken-icon;
        @extend .ken-icon--arrow-right-rhubarb;
        height: 17px;
        width: 17px;
        display: flex;

        &.swiper-button-disabled {
          @extend .ken-icon--arrow-right-grey-l;
          cursor: initial;
        }
      }
    }
  }
}
