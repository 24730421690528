.ken-plp__filters {
  &__title {
    font-family: var(--fontNameBold);
    font-size: 24px;
    color: $ken-color-black;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;

    background: $ken-color-grey-xs;
    text-align: center;
    padding: 20px;

    @include bp('ken-breakpoint-s') {
      background: $ken-color-white;
      text-align: left;
    }

    @include bp('ken-breakpoint-l') {
      padding: 15px 0;
    }

    .--count {
      font-family: var(--fontNameRegular);
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
}