[class^='ken-'] {
  box-sizing: border-box;
  font-family: var(--fontNameRegular);

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
}

$full-grid: 'full-grid';
$big-grid: 'big-grid';
$mid-grid: 'mid-grid';
$small-grid: 'small-grid';

$grid-external-gap: 0;
$wrapperWidth: (
  $full-grid: 1440,
  $big-grid: 1200,
  $mid-grid: 996,
  $small-grid: 790
) !default;

@each $key, $val in $wrapperWidth {
  .ken-wrapper--#{$key} {
    max-width: #{$val}px !important;
    margin: 0 auto;

    @media (max-width: #{$val + $grid-external-gap}px) {
      padding: 0 #{$grid-external-gap}px;
    }
  }
}

body.page-kenComponentTestingPage {
  .ken-layout-pagewrapper:empty {
    width: 100%;
    @extend .ken-wrapper--full-grid;
    align-items: center;

    &:after {
      content: "This content section is empty";
      display: flex;
      justify-content: center;
      background-color: inherit;
      color: $ken-color-rhubarb;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
