.ken-pdp__keyfeatures {
  &.ken-keyfeatures {
    padding: 100px 0;

    .ken-keyfeatures {
      &__title {
        @include ken-pdp-components-title;
      }

      &__swiper-scrollbar-wrapper {
        .swiper-scrollbar {
          margin: 0 20px;
          width: calc(100% - 40px);
        }
      }
    }
  }
}
