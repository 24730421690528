.ken-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  background-color: $ken-color-white;

  &--light {
    width: 100%;
    background-color: $ken-color-grey-xs;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 19px 0;

    @include bp('ken-breakpoint-m') {
      justify-content: flex-start;
      padding: 30px 0;
    }

    .ken-copyright {
      margin: 0;
    }
  }

  a:link,
  a:visited {
    text-decoration: inherit;
    color: $ken-color-black;
  }

  .ken-usefullinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $ken-color-grey-xs;
  }

  .ken-legal {
    width: 100%;

    &__top-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 10px 20px;

      @include bp('ken-breakpoint-s') {
        flex-direction: row;
        justify-content: space-between;
      }

      @include bp('ken-breakpoint-m') {
        border-bottom: 1px solid $ken-color-grey-m;
      }

      @include bp('ken-breakpoint-l') {
        padding: 10px 0;
      }
    }

    &__bottom-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 10px 20px;

      @include bp('ken-breakpoint-m') {
        flex-direction: row;
        justify-content: space-between;
      }

      @include bp('ken-breakpoint-l') {
        padding: 10px 0;
      }
    }
  }

  .ken-business {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include bp('ken-breakpoint-s') {
      flex-direction: row;
      flex-grow: 1;
    }

    &--mobile {
      margin: 40px 0;

      @include bp('ken-breakpoint-m') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp('ken-breakpoint-m') {
        display: flex;
      }
    }
  }
}
