.ken-pdp__specifications {
  padding: 65px 0;
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    @include ken-pdp-components-title;
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 40px;
  }

  &__specification {
    flex-basis: 100%;
    margin-bottom: 30px;

    @include bp('ken-breakpoint-s') {
      flex-basis: 48%;
      margin-bottom: 40px;
    }

    &__title {
      @include ken-text-h6;
      text-transform: uppercase;
      margin-bottom: 17px;
    }
  }

  &__single {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $ken-color-grey-m;
    padding: 4px 0;

    &__label {
      @include ken-text-body-mont;
      flex-basis: 65%;
    }
  
    &__value {
      @include ken-text-body-mont;
      flex-basis: 50%;
    }
  }

  &__show-more {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &__button {
      @extend .ken-button--link;
      text-decoration: none;
      margin: 10px auto 0;
      display: flex;
      align-items: center;
    }

    &__text {
      text-decoration: underline;
      &.--hidden {
        display: none;
      }
    }

    &__icon {
      width: 13px;
      height: 13px;
      margin-left: 4px;
      transition: transform 0.3s ease-out;
      &.--rotated {
        transform: rotate(-180deg);
      }
    }
  }

  &__manual {
    padding: 25px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    border-top: 1px solid $ken-color-grey-l;
    border-bottom: 1px solid $ken-color-grey-l;
    margin: 60px 0;

    @include bp('ken-breakpoint-s') {
      margin: 70px 0;
    }

    .ken-icon--manual-pdf {
      height: 50px;
      width: 50px;
      margin-right: 10px;
    }

    a {
      @extend .ken-button--link;
    }
  }
}
