.ken-footer {

  .ken-menues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding: 25px 0;

    @include bp('ken-breakpoint-s') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      border: 1px solid $ken-color-grey-m;
      border-left: none;
      border-right: none;
    }
  }

  .ken-menu {
    position: relative;
    border-top: 1px solid $ken-color-grey-m;

    &:last-child {
      border-bottom: 1px solid $ken-color-grey-m;
    }

    @include bp('ken-breakpoint-s') {
      width: 30%;
      border: none;
      padding: 0 20px 0 0;

      &:last-child {
        border: none;
      }
    }

    @include bp('ken-breakpoint-m') {
      width: 200px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
    }

    &__title {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: $ken-color-grey-xs;
      padding: 15px 20px;
      text-transform: uppercase;

      @include bp('ken-breakpoint-s') {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.17px;
      }

      @include bp('ken-breakpoint-l') {
        padding: 15px 0;
      }

      &text {
        position: relative;
        z-index: 2;
        font-family: var(--fontNameBold);
        font-weight: bold;
        font-size: 14px;
        line-height: 2.14;
        letter-spacing: 0.2px;
        color: $ken-color-black;

        @include bp('ken-breakpoint-s') {
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: 0.17px;
        }
      }
    }

    &__plus {
      position: relative;
      z-index: 2;
      $size: 13px;
      width: $size;
      height: $size;

      @include bp('ken-breakpoint-s') {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        width: $size;
        height: 3px;
        background: $ken-color-black;
        top: ($size / 2) - 2;
        transition: all 300ms ease;
      }

      &:after {
        position: absolute;
        content: '';
        width: 3px;
        height: $size;
        background: $ken-color-black;
        left: ($size / 2) - 2;
        transition: all 300ms ease;
      }
    }

    &__list {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      padding: 0 20px 15px;
      list-style-type: none;
      transform: translateY(-100px);
      opacity: 0.8;

      @include bp('ken-breakpoint-s') {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
      }

      @include bp('ken-breakpoint-l') {
        padding: 0 0 15px;
      }

      & li {
        margin-bottom: 25px;

        @include bp('ken-breakpoint-m') {
          margin-bottom: 5px;
        }

        a {
          font-family: var(--fontNameRegular);
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: 0.2px;
          color: $ken-color-black;

          @include bp('ken-breakpoint-m') {
            font-size: 12px;
            line-height: 1.25;
            letter-spacing: normal;
          }
        }
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked + .ken-menu__wrapper .ken-menu__list {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
      }

      &:checked + .ken-menu__wrapper .ken-menu__plus:after {
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Safari prior 9.0 */
        transform: rotate(90deg); /* Standard syntax */
      }
    }
  }
}

[dir="rtl"] {
  .ken-footer {
    .ken-menu {
      @include bp('ken-breakpoint-s') {
        padding: 0 0 0 20px;
      }
    }
  }
}
