// ###
// LAYOUT
// ###
.ken-plp-plus__layout {
  display: flex;
  flex-direction: column;
  padding-block: 40px;
  background-color: var(--brand-03);

  @include bp('ken-breakpoint-m') {
    flex-direction: row;
  }
}

// ###
// FILTERS
// ###
.ken-plp-plus__filters {
  display: flex;
  flex-direction: column;
  @include bp('ken-breakpoint-m') {
    width: 475px;
  }

  .--filters-box {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 115px;
  }

  .--filter-title {
    font-family: var(--fontNameSemibold);
    font-size: 24px;
    line-height: 34px;
    padding-inline: 20px;
    margin-bottom: 15px;

    @include bp('ken-breakpoint-m') {
      font-size: 32px;
      line-height: 40px;
      padding-inline: 30px;
      margin-bottom: 35px;
    }
  }

  .--facets {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    padding-inline: 20px;
    margin-bottom: 15px;

    @include bp('ken-breakpoint-m') {
      padding-inline: 30px;
    }

    .--price-label {
      font-family: var(--fontNameSemibold);
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 5px;
    }

    .--facet-value {
      display: flex;
      align-items: center;
      font-family: var(--fontNameRegular);
      font-size: 12px;
      line-height: 14px;
      background: none;
      border: 1px solid var(--ui-12);
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 20px;
      color: var(--brand-04);

      &.--selected {
        border-color: var(--brand-01);
      }

      .--color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .--totals-and-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 20px;
    margin-block: 10px;

    @include bp('ken-breakpoint-m') {
      padding-inline: 30px;
    }

    .--total,
    .--reset {
      font-family: var(--fontNameRegular);
      font-size: 14px;
      line-height: 16px;
      @include bp('ken-breakpoint-m') {
        line-height: 20px;
      }
    }

    .--reset {
      font-family: var(--fontNameMedium);
      color: var(--brand-01);
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// ###
// RESULTS
// ###
.ken-plp-plus__results {
  &-wrapper {
    width: 100%;
    padding-inline: 20px;
    margin-top: 20px;
    @include bp('ken-breakpoint-m') {
      width: calc(100% - 475px);
    }
    @include bp('ken-breakpoint-xl') {
      padding-inline: 0;
    }
  }

  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @include bp('ken-breakpoint-l') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ken-product-tile--plus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--ui-12);
    padding: 10px;

    a {
      text-decoration: none;
    }

    .ken-image__image {
      display: block;
      margin-inline: auto;
      max-width: 100%;
      height: auto;
    }

    .--promos {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .--promo-label,
      .--discount-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--fontNameSemibold);
        font-size: 10px;
        line-height: 10px;
        color: var(--brand-01);
        background-color: var(--support-01--light);
        padding: 4px 8px;
      }

      .--discount-label {
        color: var(--brand-03);
        background-color: var(--brand-01);
      }
    }

    .--name {
      font-family: var(--fontNameMedium);
      font-size: 12px;
      line-height: 15px;
      color: var(--brand-04);
      margin-block: 5px;
      margin-top: 10px;
      @include bp('ken-breakpoint-m') {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .--code {
      font-family: var(--fontNameRegular);
      font-size: 8px;
      line-height: 12px;
      color: var(--brand-02);
      margin-block: 5px;
      @include bp('ken-breakpoint-m') {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .ken-product-tile__price {
      margin-top: 10px;

      .--mainPrice {
        font-family: var(--fontNameMedium);
        font-size: 12px;
        line-height: 14px;
        @include bp('ken-breakpoint-m') {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .--strikePrice {
        font-size: 8px;
        line-height: 16px;
        @include bp('ken-breakpoint-m') {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .--attachments {
      font-family: var(--fontNameRegular);
      font-size: 10px;
      line-height: 12px;
      @include bp('ken-breakpoint-m') {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .ken-button {
      width: 100%;
      margin-top: 30px;
    }

    .ken-product-tile__reviews:not(.--no-reviews) {
      margin-top: 10px;
    }
  }

  .ken-plp__load-more {
    grid-column: 1 / 3;
    margin-top: 30px;
    @include bp('ken-breakpoint-l') {
      grid-column: 1 / 4;
    }

    .ken-plp__load-more__text {
      display: none;
    }

    button {
      width: 100%;
      @include bp('ken-breakpoint-l') {
        max-width: 292px;
      }
    }
  }
}

.ken-plp-plus__no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  border: 1px solid var(--ui-12);
  padding: 46px 30px;
  margin-top: 20px;

  @include bp('ken-breakpoint-l') {
    margin-top: 0;
    padding-block: 76px;
  }

  .--title,
  .--text {
    text-align: center;
    @include bp('ken-breakpoint-l') {
      max-width: 540px;
    }
  }

  .--title {
    font-family: var(--fontNameSemibold);
    font-size: 24px;
    line-height: 32px;
  }

  .--text {
    font-family: var(--fontNameLight);
    font-size: 14px;
    line-height: 20px;
  }
}
