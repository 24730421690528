.ken-header-v2__menu-item {
  &__bottom-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include bp('ken-breakpoint-m') {
      gap: 40px;
      padding: 40px;
      flex-wrap: unset;
    }
  }

  &__bottom-title {
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    line-height: 20px;
    color: $ken-color-grey-l;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    margin-block: 30px;

    @include bp('ken-breakpoint-m') {
      font-size: 12px;
      margin-block: 0;
      width: auto;
    }
  }

  &__bottom-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;

    @include bp('ken-breakpoint-m') {
      display: flex;
      gap: 40px;
      width: auto;
    }
  }

  &__bottom-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    min-height: 62px;
    padding-inline: 2px;
    border-radius: 4px;
    color: $ken-color-black;
    background-color: $ken-color-grey-xs;

    @include bp('ken-breakpoint-m') {
      display: inline-block;
      min-height: unset;
      padding-inline: 0;
      background-color: transparent;
    }

    &.--view-all {
      @include bp('ken-breakpoint-m') {
        color: $ken-color-grey-l;
      }
    }
  }
}
