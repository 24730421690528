.ken-miniCart.dln-miniCart {
  left: auto;
  right: 300px;
  top: 5px;
  position: relative;
  min-width: 400px;
  box-shadow: 0 4px 5px 0 rgba($ken-color-black, 0.1);

  .dln-miniCart__content {
    min-height: 200px;
    max-height: 289px;
    overflow-y: auto;

    .dln-icn:before,
    [class^='dln-icn']:before {
      font-family: var(--fontNameIcons);
    }
  }

  .dln-orderSumm__contentRow--total span {
    .dln-icn {
      width: auto;
      height: 16px;
      font-size: 16px;

      & + a {
        text-decoration: underline;
      }
    }
  }

  .dln-miniCart__cta {
    button[title='Checkout'] {
      background-color: $ken-color-rhubarb;
    }
  }

  [class^='dln-icn']:before {
    font-family: var(--fontNameIcons);
  }
}
