.ken-configurator__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $ken-color-grey-xxl-alpha;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.ken-configurator__generic-popup {
  max-width: 560px;
  position: relative;
  background: $ken-color-white;
  margin: auto 20px;

  &-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-close {
    position: absolute;
    top: 28px;
    right: 28px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &-img {
      filter: invert(100%);
    }
  }

  &-content {
    padding: 32px 42px 32px 32px;

    &-center {
      text-align: center;
    }

    &.save-for-later-popup-content {
      display: flex;
      flex-direction: column;
    }
  }

  &-alert-img {
    padding-bottom: 12px;
  }

  &-title {
    font-size: 24px;
    font-family: var(--fontNameBold);
    color: var(--brand-01);
    padding-bottom: 16px;
    line-height: 31.2px;

    &-dark {
      color: $ken-color-black;
    }

    span {
      color: $ken-color-black;
      font-family: var(--fontNameBold);
    }
  }

  &-subtitle {
    font-size: 16px;
    font-family: var(--fontNameBold);
    color: var(--brand-01);
    padding-bottom: 16px;
    line-height: 31.2px;

    span {
      color: $ken-color-black;
      font-family: var(--fontNameBold);
    }
  }

  &-desc {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 12px;

    ul {
      margin-left: 1rem;
    }

    &-center {
      text-align: center;
    }

    &-flexboxes,
    &-attachments {
      display: flex;
      justify-content: space-evenly;
    }

    &-flexboxes {
      flex-wrap: wrap;
      margin-bottom: 24px;
    }

    &-attachments {
      flex-direction: column;
      justify-content: space-between;

      & > div {
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        img {
          max-width: 100%;
        }
      }

      @include bp('ken-breakpoint-m') {
        flex-direction: row;

        & > div {
          margin-bottom: 0;
          margin-right: 20px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    h3 {
      font-family: var(--fontNameBold);
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 3px;
    }

    .ken-button--link {
      font-family: var(--fontNameSemibold);
    }
  }

  &-form {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 20px;

    input[type=email] {
      max-width: 50%;
    }
  }

  &-ctas {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
    }
  }

  .ken-hero__textblock-cta {
    margin-top: 10px;
    width: 100%;

    &[disabled=true]{
      opacity: 0.5;
    }

    @include bp('ken-breakpoint-m') {
      margin-right: 20px;
      margin-top: 30px;
      width: auto;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.ken-configurator__change-config-cta {
  border: none;
  background: none;
  color: $ken-color-black;
  font-family: var(--fontNameMedium);
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  margin-right: auto;
  margin-bottom: 15px;

  &::before {
    content: "❮";
    margin-right: 5px;
    display: inline-block;
  }
}

.out-of-stock-tag {
  border-radius: 30px;
  background-color: $ken-color-pumpkin;
  color: $ken-color-white;
  font-family: var(--fontNameMedium);
  text-transform: uppercase;
  padding: 10px 15px;
}