.ken-productmoodboard {
  &__tab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
    }

    &__main-product {
      order: 1;

      @include bp('ken-breakpoint-m') {
        order: 2;
        width: calc(50% - 10px);
      }
    }

    &__side-column {
      display: flex;
      justify-content: space-between;

      @include bp('ken-breakpoint-m') {
        flex-direction: column;
        width: calc(25% - 15px);
      }

      &--left {
        order: 2;
        margin: 10px 0;

        @include bp('ken-breakpoint-m') {
          order: 1;
          margin: 0;
        }
      }

      &--right {
        order: 3;
      }
    }
    &__product {
      @include bp('ken-breakpoint-m') {
        height: 100%;
      }

      &__textgroup {
        flex: auto;
      }
    }
  }
}
