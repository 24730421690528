.ken-header-v2 {
  &__promo-card {
    display: flex;
    width: 100%;
    height: 164px;
    padding: 20px;
    border-radius: 4px;
    background-size: cover;
    background-position: bottom right;
    text-decoration: none;

    @include bp('ken-breakpoint-m') {
      width: 302px;
      height: 230px;
    }

    @include bp('ken-breakpoint-l') {
      width: 361px;
    }

    @include bp('ken-breakpoint-xl') {
      width: 443px;
      height: 233px;
    }

    &.--with-tiles {
      width: 334px;

      @include bp('ken-breakpoint-xl') {
        width: 377px;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 200px;
    }

    &__text-box {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__kicker {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $ken-color-grey-xxl;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      .--white-text & {
        color: $ken-color-white;
      }
    }

    &__title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $ken-color-black;

      .--white-text & {
        color: $ken-color-white;
      }
    }

    &__link {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: $ken-color-black;
      text-decoration: underline;

      .--white-text & {
        color: $ken-color-white;
      }
    }
  }
}