$ken-producttiles-xgap: 1.6667%;

.ken-producttiles {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  color: $ken-color-white;
  overflow: hidden;

  @include bp('ken-breakpoint-max-s', 'max') {
    gap: 10px;
  }

  &-tile-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__picture {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &-image {
      width: 100%;
      max-width: 100%;
    }
  }

  &-layout--three-tiles {
    .ken-producttiles-tile-wrapper:nth-child(2),
    .ken-producttiles-tile-wrapper:nth-child(3) {
      max-width: calc(50% - 5px);
      margin-top: 10px;
    }
  }

  &__textblock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 15px 30px;
    background-color: $ken-color-none;
    color: $ken-color-white;

    &-titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-subtitle,
    &-title {
      text-align: center;
    }

    &-title {
      @include ken-text-h5;
      color: inherit;
    }

    &-subtitle {
      margin: 8px 0 0;
      @include ken-text-body-light;
      color: inherit;
      font-family: var(--fontNameLight);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      overflow: hidden;
    }
  }

  &-layout--three-tiles {
    .ken-producttiles-tile-wrapper:nth-child(2),
    .ken-producttiles-tile-wrapper:nth-child(3) {
      @include bp('ken-breakpoint-max-s', 'max') {
        text-decoration: none;
      }

      .ken-producttiles__textblock {
        @include bp('ken-breakpoint-max-s', 'max') {
          position: static;
          display: block;
          height: auto;

          &-title {
            padding-top: 12px;
            color: $ken-color-black;
            font-family: var(--fontNameBold);
            font-weight: bold;
          }

          &-subtitle {
            color: $ken-color-black;
          }
        }
      }
    }
  }

  &-cta {
    display: none;
    @extend .ken-button--primary--small;
    margin-top: 10px;
    font-size: 11px;
    align-self: center;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity .5s ease-in;
  }

  @include bp('ken-breakpoint-s') {
    flex-wrap: nowrap;
    padding: 0;

    &-tile-wrapper {
      width: auto;
      max-height: 543px;
      max-width: 387px;
      margin: 10px 0;

      &.tile--big {
        max-width: 794px;
      }

      &:not(:first-child) {
        margin-left: $ken-producttiles-xgap;
      }
    }

    &__picture {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;

      &-image {
        width: auto;
        max-width: 100%;
      }
    }

    &__textblock {
      margin: 0;
      padding-bottom: 20px;
      width: auto;
      align-items: center;
      background-color: $ken-color-none;
      color: $ken-color-white;
      overflow: hidden;
      transition: all .3s ease-in;

      &-titles {
        max-width: calc(100% - 100px);
        margin-bottom: -50px;
        padding: 0;
        align-items: center;
        transition: margin .3s ease-in;
      }

      &-subtitle,
      &-title {
        text-align: center;
      }

      &-title {
        line-height: 1.2;
        color: inherit;
      }

      &-subtitle {
        margin: 8px 0 10px;
      }

      &:hover {
        .ken-producttiles__textblock-titles {
          margin-bottom: 30px;
          transition: margin .3s ease-in;
        }

        .ken-producttiles__textblock-subtitle {
          -webkit-line-clamp: unset;
          /* autoprefixer: ignore next */
          -webkit-box-orient: unset;
          overflow: unset;
        }

        .ken-producttiles-cta {
          opacity: 1;
          transition: all .3s ease-in;
        }
      }
    }

    &-cta {
      display: block;
    }

    &-layout--two-tiles {
      &-big-right {
        .ken-producttiles-tile-wrapper {
          max-width: 794px;
        }
      }

      &-big-left {
        .ken-producttiles-tile-wrapper:first-child {
          max-width: 794px;
        }
      }
    }
  }

  &__mask {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(to top, $ken-color-black-mask-50 50%, $ken-color-none 100%);
  }

  &-tile-wrapper {
    &:hover {
      .ken-producttiles__mask {
        height: 100%;
        transition: all .3s ease-in;
      }
    }
  }

  &-layout--three-tiles {
    .ken-producttiles-tile-wrapper:nth-child(2),
    .ken-producttiles-tile-wrapper:nth-child(3) {
      .ken-producttiles__mask {
        display: none;

        @include bp('ken-breakpoint-s') {
          display: block;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-producttiles {

    &-layout--three-tiles {
      .ken-producttiles-tile-wrapper:nth-child(3) {
        order: 1;
      }
    }

    @include bp('ken-breakpoint-s') {
      &-layout--two-tiles {
        .ken-producttiles-tile-wrapper:first-child {
          order: 2;
        }

        .ken-producttiles-tile-wrapper:last-child {
          order: 1;
        }
      }

      &-tile-wrapper {
        &:not(:first-child) {
          margin-left: 0;
          margin-right: $ken-producttiles-xgap;
        }
      }
    }
  }
}
