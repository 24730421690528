.ken-footer {

  .ken-logo {
    display: flex;
    align-items: center;

    img {
      height: 20px;
      width: auto;
    }
  }
}
