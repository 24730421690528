.ken-breadcrumb {
  display: flex;
  width: 100%;
  margin: 10px auto;
  padding: 0 20px;
  align-items: center;
  background-color: transparent;

  @include bp('ken-breakpoint-s') {
    margin: 15px auto 39px;
  }

  &__crumblist {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    list-style-type: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__crumb {
    display: inline;
    height: 15px;

    &:not(:first-child):before {
      display: inline-block;
      content: "/";
      line-height: 15px;
    }

    a.ken-breadcrumb_link,
    .ken-breadcrumb_label {
      display: inline;
      color: $ken-color-black;
      font-family: var(--fontNameRegular);
      font-size: 12px;
      font-weight: bolder;
      line-height: 15px;
      letter-spacing: normal;
      text-decoration: none;
    }

    &.active {
      a.ken-breadcrumb_link,
      .ken-breadcrumb_label {
        color: $ken-color-grey-l;
        }
      }

    a:hover {
      text-decoration-line: underline;
    }
  }
}

.ken-modifier-wrapper {
  &.modifier-desktop-light {
    .ken-breadcrumb {
      @include bp('ken-breakpoint-s') {
        &__crumb {
          color: $ken-color-grey-xs;

          &:not(:first-child):before {
            color: inherit;
          }

          a.ken-breadcrumb_link,
          .ken-breadcrumb_label {
            color: inherit;
            font-weight: normal;
          }

          &.active {
            a.ken-breadcrumb_link,
            .ken-breadcrumb_label {
              color: $ken-color-grey-l;
            }
          }
        }
      }
    }
  }
}
