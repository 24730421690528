.ken-promobanner__mask {
  display: none;

  @include bp('ken-breakpoint-s') {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;

    &--left--white {
      width: 50% !important;
      background: linear-gradient(to right, $ken-color-white-mask-50, transparent);
    }

    &--left--black {
      width: 50% !important;
      background: linear-gradient(to right, $ken-color-black-mask-50, transparent);
    }

    &--cover--white {
      width: 100%;
      background-color: $ken-color-white-mask-50;
    }

    &--cover--black {
      width: 100%;
      background-color: $ken-color-black-mask-50;
    }
  }

  html[dir='rtl'] & {
    @include bp('ken-breakpoint-s') {
      &--left--white {
        width: 50% !important;
        background: linear-gradient(to left, $ken-color-white-mask-50, transparent);
      }
      &--left--black {
        width: 50% !important;
        background: linear-gradient(to left, $ken-color-black-mask-50, transparent);
      }
    }
  }
}
