.ken-product-tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $ken-color-white;
  position: relative;
  transition: all 0.3s ease 0s;

  .js-prod-anchor {
    visibility: hidden;
    position: absolute;
    top: -120px;
  }

  &__top {
    position: relative;
  }
}
