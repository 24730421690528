.ken-pdp__main-info__colors {
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
  }

  &__color {
    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      border-bottom: 2px solid $ken-color-black;
    }

    &--current {
      border-bottom: 2px solid $ken-color-black;
    }
  }
}
