.ken-product-tile {
  &__wishlist {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
}