.ken-plp__filters {
  &__mobile-bar__wrapper {
    min-height: 50px;

    @include bp('ken-breakpoint-s') {
      display: none;
    }

    &.--sticky {
      .ken-plp__filters__mobile-bar {
        position: fixed;
        width: 100%;
        z-index: 2;
        top: 0;
        transition: top 0.3s ease-out;
        animation: showStickyBar .5s normal forwards ease-in-out;
      }

      &.--plus-header {
        .ken-plp__filters__mobile-bar {
          top: 69px;
        }
      }
    }
  }

  &__mobile-bar {
    display: flex;
    padding: 14px 20px;
    background-color: $ken-color-white;
    border-top: 1px solid $ken-color-grey-m;
    border-bottom: 1px solid $ken-color-grey-m;

    &__filter {
      width: 50%;

      button {
        display: flex;
        align-items: center;
        font-family: var(--fontNameBold);
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $ken-color-black;
        background: none;
        border: none;
        cursor: pointer;

        i {
          width: 25px;
          height: 20px;
        }
      }
    }

    &__view {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      font-family: var(--fontNameLight);
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: $ken-color-black;

      .--single-card,
      .--double-card{
        background: none;
        border: none;
        cursor: pointer;
      }

      .--single-card {
        width: 16px;
        height: 25px;
        background-color: $ken-color-grey-l;
        margin-left: 13px;
        margin-right: 15px;
        &.--active {
          background-color: $ken-color-black;
        }
      }

      .--double-card {
        position: relative;
        width: 16px;
        height: 13px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 7px;
          height: 100%;
          top: 0;
          background-color: $ken-color-grey-l;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }

        &.--active {
          &::before,
          &::after {
            background-color: $ken-color-black;
          }
        }

      }
    }
  }
}

@keyframes showStickyBar {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}