.ken-plp__filters {
  &__mobile-popup__bottom {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    height: 80px;
    box-shadow: 0 0 6px 0 rgba($ken-color-black, .15);
    background-color: $ken-color-white;
    width: 100%;

    button {
      max-width: 334px;
      width: 100%;
    }
  }
}
