.ken-footer {
  .ken-quicklinks {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;

    @include bp('ken-breakpoint-m') {
      padding: 0;
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px 0;

      @include bp('ken-breakpoint-s') {
        justify-content: center;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      padding: 10px 20px;

      @include bp('ken-breakpoint-s') {
        width: 25%;
      }
    }

    &__text {
      margin-top: 10px;
      font-family: var(--fontNameSemibold);
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
      text-align: center;
    }
  }
}
