.ken-contact-us {
  padding-bottom: 23px;

  &__title {
    text-align: center;
    font-size: 24px;
    font-family: var(--fontNameSemibold);
    text-transform: uppercase;
    padding-bottom: 50px;

    @include bp('ken-breakpoint-m') {
      font-size: 30px;
      padding-bottom: 45px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    max-width: 375px;
    width: 100%;
    margin: 0 auto;

    @include bp('ken-breakpoint-s') {
      max-width: 565px;
    }

    @include bp('ken-breakpoint-l') {
      max-width: 1200px;
      justify-content: space-between;
    }
  }

  &__item {
    position: relative;
    padding: 0 30px;
    margin: 30px 0;
    width: 187px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @include bp('ken-breakpoint-m') {
      width: 235px;
    }

    &:nth-child(odd) {
      border-right: 2px solid $ken-color-grey-s;

      @include bp('ken-breakpoint-s') {
        border-right: none;
      }
    }

    &-image {
      width: 50px;

      img {
        width: 100%;
      }
    }

    &-title {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      font-family: var(--fontNameSemibold);
    }

    &-text {
      padding-top: 5px;
      font-size: 13px;
      text-align: center;
      color: $ken-color-black;
    }

    &-cta-wrapper {
      padding-top: 10px;
    }

    &-cta {
      font-size: 14px;
      text-align: center;
      color: $ken-color-black;
      text-decoration: underline;
      font-family: var(--fontNameSemibold);
      cursor: pointer;
    }

    &-chat {
      &-tooltip {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 13px 8px;
        width: 155px;
        background-color: $ken-color-white;
        filter: drop-shadow(0px 2px 10px rgba(19, 21, 24, 0.20));

        @include bp('ken-breakpoint-s') {
          top: calc(100% + 15px);
          padding: 25px 15px 15px 15px;
          width: 240px;

          &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $ken-color-white transparent;
          }
        }

        &.--hidden {
          display: none;
        }
      }

      &-title {
        font-size: 16px;
        color: $ken-color-black;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 10px;

        @include bp('ken-breakpoint-s') {
          display: none;
        }
      }

      &-message {
        font-size: 14px;
        color: $ken-color-black;
        text-align: center;

        @include bp('ken-breakpoint-s') {
          font-size: 16px;
        }
      }
    }
  }
}