.ken-titleimagelink {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(50% + 6.6667%) !important;
    margin: 15px auto 0;
    max-height: 50%;
    min-height: 50%;
    padding: 0;
    text-decoration: none;

    &:visited,
    &:focus {
      color: $ken-color-black;
    }
  }

  &__imgwrap {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto 0 20px 0;
    padding: 0;
    line-height: 1.3;
    font-family: var(--fontNameBold);
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  &__cta {
    display: none;
    font-family: var(--fontNameRegular);
    font-weight: normal;
    font-size: 11px;
    text-transform: uppercase;
  }

  @include bp('ken-breakpoint-s') {
    display: flex;
    width: 100%;

    &__link {
      align-items: initial;
      height: auto;
      width: 100%;
    }

    &__cta {
      display: block;
      font-size: 10px;
    }
  }
}
