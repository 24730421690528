.ken-footer {

  .ken-copyright {
    margin-top: 12px;
    font-family: var(--fontNameRegular);
    font-size: 12px;
    line-height: 1.5;
    color: $ken-color-grey-xxl;

    @include bp('ken-breakpoint-s') {
      margin: 0;
      padding-left: 20px;
    }
  }
}

[dir="rtl"] {
  .ken-footer {

    .ken-copyright {
      @include bp('ken-breakpoint-s') {
        padding: 0 20px 0 0;
      }
    }
  }
}
