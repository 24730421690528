.ken-campaign-steps {
  padding: 0 20px;

  @include bp('ken-breakpoint-s') {
    padding: 0;
  }

  &__title {
    @include ken-text-h2;
    text-align: center;
    margin-bottom: 30px;
  }


  &__item {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;

    &-number {
      @include ken-text-h6($boldMobile: false);
      color: $ken-color-white;
      background-color: $ken-color-rhubarb;
      display: flex;
      flex: 0 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      border: 8px solid $ken-color-white-mask-70;
      margin-right: 17px;
    }

    &-title {
      @include ken-text-h6($boldMobile: false);
      margin-top: 8px;
    }

    &-description {
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &__button-wrapper {
    text-align: center;
    a {
      text-align: center;
      @include ken-button;
      @include ken-button--primary;
  
      @include bp('ken-breakpoint-s') {
        @include ken-button--secondary;
        border: 1px solid $ken-color-rhubarb;
      }
    }
  }
}
