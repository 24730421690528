.ken-configurator__step-three-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp('ken-breakpoint-m') {
    flex-grow: 1;
    max-width: 590px;
    margin: 0 20px 20px 20px;
  }

  &-title {
    font-family: var(--fontNameSemibold);
    font-size: 16px;
    margin-top: 40px;

    @include bp('ken-breakpoint-m') {
      display: none;
    }
  }

  &-img-wrapper {
    margin-top: 30px;

    @include bp('ken-breakpoint-m') {
      margin: 45px 0;
    }

    img {
      width: 220px;
      height: 185px;

      @include bp('ken-breakpoint-m') {
        width: 386px;
        height: 322px;
      }
    }
  }

  &-scroll {
    margin-top: 30px;
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    text-decoration: underline;

    @include bp('ken-breakpoint-m') {
      display: none;
    }
  }

  &-overview {
    padding: 30px 20px 0 20px;
    width: 100%;

    @include bp('ken-breakpoint-m') {
      padding: 0;
    }
  }

  &-promo {
    background-color: $ken-color-white;
    padding: 12px 16px;
    width: 100%;
    filter: drop-shadow(0px 0px 16px rgba(51, 51, 51, 0.1));

    @include bp('ken-breakpoint-m') {
      background-color: $ken-color-grey-xs;
      width: 56%;
      display: flex;
      align-items: center;
    }

    &-title {
      font-family: var(--fontNameMedium);
      font-size: 10px;
    }

    &-details {
      display: flex;
      align-items: center;

      @include bp('ken-breakpoint-m') {
        margin-left: 10px;
        flex-grow: 1;
      }

      &-title {
        font-family: var(--fontNameSemibold);
        font-size: 14px;
        margin-right: 8px;
      }

      &-info {
        margin-left: 0;
        margin-right: 8px;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }

        @include bp('ken-breakpoint-m') {
          margin-left: 0;
          margin-right: 10px;
        }
      }

      &-percentage {
        font-family: var(--fontNameSemibold);
        font-size: 16px;
        color: $ken-color-orangestone;
        margin-left: auto;
      }
    }
  }

  &-wrapper {
    @include bp('ken-breakpoint-m') {
      display: flex;
      height: 56px;
    }
  }

  &-totals {
    background-color: $ken-color-grey-xxxl;
    color: $ken-color-white;
    padding: 12px 16px;
    width: 100%;
    filter: drop-shadow(0px 0px 16px rgba(51, 51, 51, 0.1));
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include bp('ken-breakpoint-m') {
      width: 56%;
      align-items: center;
    }

    &-label {
      font-family: var(--fontNameSemibold);
      font-size: 14px;
    }

    &-values {
      display: flex;
      flex-direction: column;

      @include bp('ken-breakpoint-m') {
        flex-direction: row;
        align-items: center;
      }

      &-discounted {
        font-family: var(--fontNameMedium);
        font-size: 10px;
        text-decoration: line-through;
        text-align: right;

        @include bp('ken-breakpoint-m') {
          margin-right: 10px;
          font-size: 12px;
        }
      }

      &-final {
        font-family: var(--fontNameSemibold);
        font-size: 16px;
      }
    }
  }

  &-ctas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $ken-color-grey-xxs;
    flex-wrap: wrap;

    @include bp('ken-breakpoint-m') {
      position: relative;
      padding: 0;
      width: 44%;
      flex-wrap: nowrap;
    }

    &-back {
      width: 45px;
      padding: 10px;

      @include bp('ken-breakpoint-m') {
        display: none;
      }
    }

    &-link {
      flex-grow: 1;
      background-color: $ken-color-rhubarb;
      color: $ken-color-white;
      font-size: 12px;
      width: 135px;
      height: 45px;
      border: none;
      margin-left: 16px;
      font-family: var(--fontNameSemibold);
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      @include bp('ken-breakpoint-m') {
        width: 100%;
        height: 56px;
        font-size: 14px;
        margin-left: 0;

        span {
          display: inline;
        }
      }
      &__save-for-later {
        color: var(--brand-01);
        background-color: #F2F2F2;
        margin: 0;
        width: 100%;
        text-transform: uppercase;

        @include bp('ken-breakpoint-m') {
          background-color: #f9f9f9;
          text-transform: none;
        }
      }
    }
  }
}
