.ken-hero--counter {
  .ken-hero {
    &__counter {
      display: flex;
      justify-content: space-between;
      margin: 30px auto 3px;

      &__box {
        width: 84px;

        &:not(:last-child) {
          border-right: 1px solid $ken-color-grey-m;
        }
      }

      &__number {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 50px;
        font-weight: bold;
        line-height: 40px;
        color: inherit;

        &__label {
          color: inherit;
          font-family: var(--fontNameBold) !important;
          font-weight: bold;
        }
      }

      &__label {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        font-size: 12px;
        text-align: center;
        text-transform: lowercase;
      }

      @include bp('ken-breakpoint-s') {
        margin: 10px -8px 0;

        &__box {
          width: 54px;
        }

        &__number {
          font-size: 32px;
          line-height: 32px;
        }

        &__label {
          font-size: 10px;
        }
      }

      @include bp('ken-breakpoint-m') {
        margin: 30px -15px 3px;

        &__box {
          width: 94px;
        }

        &__number {
          font-size: 50px;
          line-height: 40px;
        }

        &__label {
          font-size: 12px;
        }
      }
    }

    &__textblock {
      padding: 28px 20px 40px;

      &-kicker {
        display: block;
        color: inherit;
        font-size: 13px;
        font-weight: normal;
      }

      &-title {
        @include ken-text-h2;
        color: inherit;

        @include bp('ken-breakpoint-s') {
          font-size: 24px;
          line-height: 30px;
        }

        @include bp('ken-breakpoint-m') {
          @include ken-text-h2;
          color: inherit;
        }

        &.--montserrat-bold {
          font-family: var(--fontNameBold);
          font-weight: bold;
        }
      }

      &--mobile-light {
        @include bp('ken-breakpoint-max-s', 'max') {
          color: $ken-color-white;
        }
      }

      @include bp('ken-breakpoint-s') {
        width: calc(26.875% + 8.334%) !important;
        max-width: 35.1389%;
        padding: 0 0 0 8.334%;
        color: inherit;

        &--desktop-light {
          color: $ken-color-white;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-hero--counter {
    .ken-hero {
      &__counter {
        &__box {
          border-right: 0 none;

          &:not(:first-child) {
            border-right: 1px solid $ken-color-grey-m;
          }
        }
      }

      &__textblock {
        @include bp('ken-breakpoint-s') {
          padding: 0 8.334% 0 0;
        }
      }
    }
  }
}
