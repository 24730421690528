.ken-header-v2 {
  &__menu-mobile {
    position: absolute;
    top: 60px;
    left: -100vw;
    display: block;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: $ken-color-white;
    transition: all 0.3s ease;
    visibility: hidden;

    .--with-stripe-links & {
      height: calc(100vh - 100px);
    }

    .--show-menu-mobile + & {
      left: 0;
      visibility: visible;
    }

    &__main {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &__nav-list,
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      padding: 20px;
      list-style: none;
    }

    &__separator {
      margin: 0 20px;
      border: 1px solid $ken-color-grey-xxs;
    }

    &__buttons {
      border-top: 1px $ken-color-grey-m;
    }

    &__nav-item {
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      background-color: $ken-color-grey-xxs;
    }

    &__label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span,
      a {
        color: $ken-color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-decoration: none;
      }

      a {
        color: $ken-color-rhubarb;
      }

      .ken-icon {
        width: 24px;
        height: 24px;
      }
    }

    &__button {
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      background-color: $ken-color-grey-xxs;

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        color: $ken-color-black;
        text-decoration: none;

        .ken-icon {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.186px;
        }
      }
    }
  }

  &__menu-screen {
    position: absolute;
    top: -60px;
    left: 100vw;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: $ken-color-white;
    overflow: auto;
    transition: all 0.3s ease;
    visibility: hidden;

    .--with-stripe-links & {
      height: calc(100vh - 40px);
    }

    .ken-header-v2__menu-mobile__nav-item.--show-screen &,
    .ken-header-v2__menu-mobile__button.--show-screen & {
      left: 0;
      visibility: visible;
    }

    &__title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 16px;

      .ken-icon {
        width: 28px;
        height: 28px;
      }

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-transform: capitalize;
      }
    }

    &__close-button {
      width: 20px;
      height: 20px;

      .ken-icon {
        width: 20px;
        height: 20px;
      }
    }

    &__content-row {
      display: none;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding: 8px 20px 20px;

      .ken-header-v2__menu-mobile__nav-item.--show-screen &,
      .ken-header-v2__menu-mobile__button.--show-screen & {
        display: flex;
      }
    }

    &__view-all-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &__view-all-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 36px;
      border: 1px solid $ken-color-rhubarb;
      text-decoration: none;

      span {
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $ken-color-rhubarb;
        text-transform: uppercase;
      }
    }

    &__category-tiles {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px;

      .ken-header-v2__category-tile {
        &:last-child:nth-child(odd) {
          width: 100%;
        }
      }
    }

    &__promo-row {
      display: none;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 20px 0;
      width: 100%;

      .ken-header-v2__menu-mobile__nav-item.--show-screen &,
      .ken-header-v2__menu-mobile__button.--show-screen & {
        display: flex;
      }
    }
  }

  &__support-screen {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;
    display: flex;
    border-radius: 4px;
    padding: 32px 20px;
    background: $ken-color-grey-xxs;

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $ken-color-rhubarb;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      list-style: none;
    }

    &__item {
      width: calc(50% - 5px);
    }

    &__link {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $ken-color-black;
      text-decoration: none;
    }
  }
}
