.ken-product-tile {
  &__color-variants-label {
    display: inline-block;
    opacity: 0;
    font-family: var(--fontNameLight);
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: $ken-color-grey-xxl;
    padding: 10px 0;

    @include bp('ken-breakpoint-s') {
      opacity: 1;
    }

    .--lite & {
      opacity: 1;
      @include bp('ken-breakpoint-max-s', 'max') {
        display: none;
      }
    }

    &.--lite {
      display: none;
      .--lite & {
        @include bp('ken-breakpoint-max-s', 'max') {
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
          padding: 0;
        }
      }
    }
  }

  &:hover {
    .ken-product-tile__color-variants-label {
      opacity: 0;
    }
  }

  &__colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include bp('ken-breakpoint-s') {
      padding: 0;
    }
  }

  &__color-link {
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
    margin-right: 7px;
    margin-bottom: 3px;

    &:hover {
      border-bottom-color: $ken-color-black;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__color-image {
    display: block;
  }
}