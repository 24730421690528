.ken-header-v2 {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  z-index: $ken-header-z-index;
  transition: top 0.3s ease-out;
  background-color: $ken-color-white;

  &__gap {
    width: 100%;
    height: 60px;

    @include bp('ken-breakpoint-m') {
      height: 66px;
    }

    @include bp('ken-breakpoint-l') {
      height: 68px;
    }

    &.--stripe-links {
      height: 40px;
    }
  }
}