.ken-faqscomponent {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 100%;

        @include bp('ken-breakpoint-s') {
            max-width: 55%;
            margin: auto;
            gap: 29px;
        }
    }

    &__heading {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;

        h2 {
            @include ken-text-mont-h3;
            font-size: 24px;

            @include bp('ken-breakpoint-s') {
                font-size: 32px;
            }
        }

        a {
            @include ken-pdp-promo-link;
            font-size: 14px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto 0;
            height: fit-content;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__faq {
            display: grid;

            section {
                justify-self: center;
            }
        }
    }
}