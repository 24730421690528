.ken-homepagerecipes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  a,
  a:link,
  a:visited {
    text-decoration: none;
  }

  &__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;

    @include bp('ken-breakpoint-m') {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
}
