.ken-product-tile {
  &__lite-info {
    display: none;
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        display: block;
        width: 100%;
      }
    }
  }
}
