.dln-checkout__payment-methods-wrapper {
  .adyen-checkout__payment-method__radio {
    border: 1px solid $ken-color-rhubarb !important;
    box-shadow: none !important;
    width: 20px;
    height: 20px;

    &--selected {
      background-color: transparent;

      &::after {
        background-color: $ken-color-rhubarb;
        width: 70%;
        height: 70%;
        left: 1px !important;
      }
    }
  }

  .adyen-checkout__input {
    border-radius: 0;
  }

  .adyen-checkout__checkbox {
    .adyen-checkout__checkbox__label {
      &:after {
        background-color: $ken-color-rhubarb !important;
      }
    }
  }

  .adyen-checkout__button {
    border-radius: 0;
    background: $ken-color-rhubarb;
    transition: all 0.3s ease-out;
    box-shadow: none;

    &:hover {
      background: $ken-color-black;
      opacity: 0.8;
      box-shadow: none;
    }
  }
}
