.ken-footer {

  .ken-social {
    &-links {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;

      @include bp('ken-breakpoint-s') {
        justify-content: flex-start;
        flex-grow: 1;
        padding: 0;
      }

      @include bp('ken-breakpoint-m') {
        justify-content: center;
      }
    }

    &-entry {
      display: flex;
      align-items: center;
      margin: 0 25px;

      @include bp('ken-breakpoint-s') {
        margin: 0 30px 0 0;
      }

      @include bp('ken-breakpoint-m') {
        margin: 0 15px;
      }
    }
  }
}

[dir="rtl"] {
  .ken-footer {

    .ken-social {
      &-entry {
        @include bp('ken-breakpoint-s') {
          margin: 0 0 0 30px;
        }

        @include bp('ken-breakpoint-m') {
          margin: 0 15px;
        }
      }
    }
  }
}
