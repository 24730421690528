.ken-product-tile {
  &__ctas {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .ken-button {
      width: 100%;
    }

    &__learn-more {
      &.--not-alone {
        margin-top: 15px;
      }
    }

  }
}