.ken-configurator__step-two-overview {
  height: 100%;

  &-mobile {
    min-height: calc(100vh - 185px);

    @include bp('ken-breakpoint-m') {
      display: none;
    }

    &.-mobile-closed {
      display: none;
    }

    &-title {
      font-size: 16px;
      font-family: var(--fontNameMedium);
      color: $ken-color-black;
      padding: 40px 20px;
      max-width: 335px;
      margin: 0 auto;
      text-align: center;
    }

    &-img-wrapper {
      width: 245px;
      margin: 0 auto 40px auto;
    }

    &-img {
      width: 100%;
    }

    &-change-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 75px;
    }

    &-change {
      background-color: transparent;
      font-size: 12px;
      border: none;
      border-bottom: 1px solid;
      padding: 0;
      cursor: pointer;
    }

    &-cta-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }

    &-cta-menu {
      margin-left: 10px;
      text-transform: uppercase;
    }

    &-cta-back {
      height: 45px;
      width: 45px;
      padding: 10px;
    }
  }

  &-desktop {
    display: none;
    width: 100%;
    height: 100%;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-m') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-img {
      width: 100%;
      max-width: 388px;
    }

    &-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-family: var(--fontNameSemibold);
      font-size: 24px;
      width: 387px;
      margin: 20px 0;

      span {
        font-family: var(--fontNameSemibold);
        color: $ken-color-rhubarb;
      }
    }
  }
}