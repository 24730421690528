.ken-configurator__header {
  background: $ken-color-white;
  border-top: 1px solid $ken-color-grey-xs;
  box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);
  position: relative;
  display: flex;
  justify-content: space-between;

  &-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    @include bp('ken-breakpoint-m') {
      justify-content: space-between;
    }

    &-inner {
      display: flex;
      align-items: center;
    }
  }

  &-exit {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-left: 15px;

    @include bp('ken-breakpoint-m') {
      margin-left: 40px;
    }
  }


  &-steps {
    display: flex;
    padding: 22px 0;

    @include bp('ken-breakpoint-m') {
      padding: 25px 0;
    }
  }

  &-step-link {
    text-decoration: none;
    color: $ken-color-grey-l;
    font-size: 12px;
    border: none;
    background: none;

    @include bp('ken-breakpoint-m') {
      font-size: 14px;
    }

    &.-active {
      color: $ken-color-rhubarb;
      font-family: var(--fontNameBold);
    }

    &.-previous {
      color: $ken-color-black;
      cursor: pointer;
    }
  }

  &-step-divisor {
    margin: 0 8px;
    border-right: 1px solid $ken-color-grey-l;

    @include bp('ken-breakpoint-m') {
      margin: 0 16px;
    }
  }

  &-cockpit > .ken-hero__textblock-cta {
    height: 100%;
    margin-top: 0;
  }

  &-progress {
    height: 3px;
    background: $ken-color-grey-m;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &-progress-inner {
    height: 3px;
    background: $ken-color-rhubarb;
    width: 0;

    &.-w-1 {
      width: 33%;
    }

    &.-w-2 {
      width: 66%;
    }

    &.-w-3 {
      width: 100%;
    }
  }

  &-discount-message {
    padding: 6px 14px;
    color: $ken-color-white;
    background-color: $ken-color-orangestone;
    font-size: 12px;
    cursor: pointer;
    border: none;
    position: fixed;
    bottom: 145px;
    width: 100%;
    z-index: 1;
    display: none;

    &.-mobile-open {
      display: block;

      @include bp('ken-breakpoint-m') {
        display: none;
      }
    }

    @include bp('ken-breakpoint-l') {
      display: block;
      position: initial;
      border-radius: 16px;
      width: auto;

      &.-mobile-open {
        display: block;
      }
    }

    span {
      font-family: var(--fontNameSemibold);
    }
  }

  &-cart {
    position: fixed;
    z-index: 100;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $ken-color-grey-xs;
    display: none;

    &.-mobile-open {
      display: flex;
      flex-direction: column;
      @include bp('ken-breakpoint-m') {
        flex-direction: row;
      }
    }

    @include bp('ken-breakpoint-m') {
      position: relative;
      width: auto;
      display: flex;
      z-index: 0;
      margin-left: 0;
      height: 100%;
    }

    &-ctas {
      @include bp('ken-breakpoint-max-m', 'max') {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;
        padding: 20px;
      }
    }

    &-summary-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 60px;

      @include bp('ken-breakpoint-m') {
        flex-direction: row;
        height: 100%;
      }
    }

    &-summary,
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 100%;

      @include bp('ken-breakpoint-m') {
        width: 170px;
        height: 67px;
      }
    }

    &-summary {
      @include bp('ken-breakpoint-m') {
        padding: 0 10px
      }
    }

    &-link {
      background-color: $ken-color-rhubarb;
      color: $ken-color-white;
      font-size: 12px;
      width: 100%;
      height: 45px;
      border: none;
      font-family: var(--fontNameSemibold);
      cursor: pointer;

      &.-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      span {
        font-family: var(--fontNameSemibold);
        display: none;
      }

      @include bp('ken-breakpoint-m') {
        width: 170px;
        height: 65px;
        font-size: 14px;

        span {
          display: inline;
        }
      }
    }

    &-back {
      width: 45px;
      padding: 10px;

      @include bp('ken-breakpoint-m') {
        display: none;
      }
    }

    &-img {
      width: 19px;
    }

    &-label {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      padding-left: 12px;
    }

    &-quantity {
      font-size: 14px;
      color: $ken-color-white;
      background-color: $ken-color-grey-l;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;

      &.-active {
        background-color: $ken-color-rhubarb;
      }
    }
  }
}
