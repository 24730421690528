.ken-awards-carousel {
  padding: 40px 0;

  &__wrapper {
    padding: 0 20px;

    @include bp('ken-breakpoint-s') {
      margin: 0 auto;
    }
  }

  &__slide {
    text-align: center;
    padding: 0 15px;

    &__content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  
    &__title {
      @include ken-text-h6;
    }

    &__image {
      margin-bottom: 30px;
    }

    &__link {
      @include ken-pdp-awards-link;
    }
  }

  &__swiper-scrollbar-wrapper {
    .swiper-scrollbar {
      position: initial;
      margin: 40px auto 0;
      height: 3px;
      border-radius: 0;
      background-color: $ken-color-grey-s;

      @include bp('ken-breakpoint-s') {
        width: 30%;
      }

      &-drag {
        border-radius: 0;
        background-color: $ken-color-rhubarb;
      }
    }
  }
}
