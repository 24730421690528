.ken-homepagerecipes {
  &__recipe {
    display: flex;
    flex-direction: column;
    width: 162px;

    @include bp('ken-breakpoint-m') {
      width: 376px;
    }

    &--1 {
      order: 2;

      @include bp('ken-breakpoint-m') {
        order: 1;
      }
    }

    &--2 {
      order: 3;
    }

    &__button-wrapper {
      position: absolute;
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      width: 162px;
      padding: 5px;

      @include bp('ken-breakpoint-m') {
        width: 376px;
        padding: 10px;
      }
    }

    button.dln-btnIcon {
      $size: 40px;
      width: $size;
      height: $size;
      background-color: rgba($ken-color-white, 0.8);
      border-radius: 50%;

      .dln-icn {
        $size: 24px;
        width: $size;
        height: $size;
        font-size: $size;
        color: $ken-color-rhubarb;
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: 149px;
      flex-grow: 0;

      @include bp('ken-breakpoint-m') {
        height: 355px;
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 2;
    }

    &__title {
      margin: 10px 0;
      font-family: var(--fontNameBold);
      font-size: 14px;
      font-weight: bold;
      line-height: 1.21;
      color: $ken-color-black;

      @include bp('ken-breakpoint-m') {
        margin: 15px 0 8px;
      }
    }

    &__statsgroup {
      display: flex;
      flex-direction: column;

      @include bp('ken-breakpoint-m') {
        flex-direction: row;
      }
    }

    &__stat {
      display: flex;
      align-items: center;
      color: $ken-color-grey-xxl;

      &:nth-child(2) {
        margin: 8px 0;

        @include bp('ken-breakpoint-m') {
          margin: 0 24px;
        }

      }

      &-desc {
        margin: 0 7px;
        font-family: var(--fontNameRegular);
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
      }

      .dln-icn {
        $size: 24px;
        width: $size;
        height: $size;
        font-size: 24px;
      }
    }
  }
}
