.ken-compare {
  &__popup__show-differences {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 35px;
    order: 4;

    @include bp('ken-breakpoint-m') {
      padding-right: 20px;
    }

    @include bp('ken-breakpoint-l') {
      padding-right: 0;
    }

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding-right: 46px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 36px;
        height: 20px;
        border: 1px solid $ken-color-black;
        border-radius: 12px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 50%;
        transform: translate(-20px, -50%);
        background-color: $ken-color-grey-m;
        transition: all .5s ease-in-out;
        right: 0;
      }

      &.--show-differences {
        &::after {
          background-color: $ken-color-rhubarb;
          transform: translate(-4px, -50%);
        }
      }
    }
  }
}