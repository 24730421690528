.ken-header {
  &__navbar {
    display: none;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 30px;
    // width: calc(100% - 360px);

    @include bp('ken-breakpoint-l') {
      display: flex;
    }

    > li {
      padding: 0 20px;

      > button {
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        font-family: var(--fontNameBold);
        font-size: 13px;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: 0.2px;
        color: $ken-color-black;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
      }

      &:hover {
        > button {
          border-bottom: 2px solid $ken-color-black;
        }
      }
    }
  }
}
