.ken-videoFull__spacer {
  display: none;
}

.ken-howtoyoutubevideos {
  max-width: 1440px;
  margin: 0 auto;

  &.swiper-container {
    height: 228px;
    padding: 0 0 60px;

    @include bp('ken-breakpoint-s') {
      height: 574px;
      padding: 80px 0;
    }
  }

  &__video {
    &.swiper-slide {
      width: 312px;
      height: 175px;

      @include bp('ken-breakpoint-s') {
        width: 684px;
        height: 386px;
      }

      .ken-video__player {
        top: -1px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &::after {
      display: none;
    }

    i {
      width: 20px;
      height: 20px;
    }

    @include bp('ken-breakpoint-s') {
      display: flex;
    }
  }

  .swiper-button-prev {
    left: calc(50% - 406px);

    [dir='rtl'] & {
      right: calc(50% - 406px);
      left: auto;
    }
  }

  .swiper-button-next {
    right: calc(50% - 406px);

    [dir='rtl'] & {
      left: calc(50% - 406px);
      right: auto;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp('ken-breakpoint-s') {
      display: none;
    }

    &-bullet {
      width: 8px;
      height: 8px;
      margin: 0 10px !important;
      background-color: $ken-color-grey-xl;

      &-active {
        width: 12px;
        height: 12px;
        margin: 0 8px !important;
        background-color: $ken-color-black;
      }
    }
  }
}

.ken-paperless-howtovideos {
  padding: 60px 0;

  @include bp('ken-breakpoint-s') {
    padding: 100px 0;
  }

  &__title h3 {
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 28px;
    font-family: var(--fontNameSemibold);
    text-transform: uppercase;

    @include bp('ken-breakpoint-m') {
      margin-top: 100px;
      margin-bottom: 50px;
      font-size: 30px;
    }
  }

  &__cta {
    @include ken-button;
    @include ken-button--primary;
  }

  &__cta-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  // Video player overrides

  .ken-video__caption {
    background: $ken-color-rhubarb;
    box-shadow: 0 0 0 2px $ken-color-rhubarb;
  }
}

.ken-howtoyoutubevideos.swiper-container {
  padding: 0;
  height: 265px;

  @include bp('ken-breakpoint-s') {
    height: 386px;
  }
}