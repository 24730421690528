.ken-hero {
  &--secondary {
    .ken-hero__mask {
      &-mobile {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        &--top--white {
          /*
          height: 50% !important;
          background: linear-gradient(to bottom, $ken-color-white-mask-90, transparent);
          //background: linear-gradient(to bottom, rgba(245, 245, 245, 1) 25%, rgba(245, 245, 245, 0.5) 50%, rgba(245, 245, 245, 0) 100%);
          */
          background: linear-gradient(to bottom, $ken-color-grey-xs 25%, $ken-color-grey-xs-mask-50 50%, $ken-color-none 100%);
        }

        &--top--black {
          /*
          height: 50% !important;
          background: linear-gradient(to bottom, $ken-color-black-mask-90, transparent);
          //background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
          */
          background: linear-gradient(to bottom, $ken-color-black 25%, $ken-color-black-mask-50 50%, $ken-color-none 100%);
        }

        &--cover--white {
          background-color: $ken-color-white-mask-50;
        }

        &--cover--black {
          background-color: $ken-color-black-mask-50;
        }

        &.disable-mobile-modifier {
          display: none;
        }
      }
    }
  }
}

.ken-hero__mask {
  @include bp('ken-breakpoint-s') {
    &-mobile {
      display: none !important;
    }
    &-desktop {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;

      &--left--white {
        /*
        // linear but flat and coarse
        width: 50% !important;
        background: linear-gradient(to right, $ken-color-white-mask-90, transparent);
        */
        // more punctual and compliant than the graphic sketch
        background: linear-gradient(90deg, $ken-color-grey-xs 32%, $ken-color-grey-xs-mask-80 35%, $ken-color-grey-xs-mask-50 40%, $ken-color-grey-xs-mask-40 42%, $ken-color-none 50%);
      }

      &--left--black {
        background: linear-gradient(90deg, $ken-color-black  32%, $ken-color-black-mask-80 35%, $ken-color-black-mask-50 40%, $ken-color-black-mask-40 42%, $ken-color-none 50%);
      }

      &--cover--white {
        background-color: $ken-color-white-mask-50;
      }

      &--cover--black {
        background-color: $ken-color-black-mask-50;
      }

      &.disable-desktop-modifier {
        display: none;
      }

    }
  }
}

html[dir='rtl'] .ken-hero__mask {
  @include bp('ken-breakpoint-s') {
    &-desktop {
      &--left--white {
        background: linear-gradient(to left, $ken-color-grey-xs 32%, $ken-color-grey-xs-mask-80 35%, $ken-color-grey-xs-mask-50 40%, $ken-color-grey-xs-mask-40 42%, $ken-color-none 50%);
      }
      &--left--black {
        background: linear-gradient(to left, $ken-color-black 32%, $ken-color-black-mask-80 35%, $ken-color-black-mask-50 40%, $ken-color-black-mask-40 42%, $ken-color-none 50%);
      }
    }
  }
}
