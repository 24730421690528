.ken-header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  z-index: $ken-header-z-index;
  transition: top 0.3s ease-out;
  background-color: $ken-color-white;

  @include bp('ken-breakpoint-l') {
    max-height: unset;
  }

  &--hidden {
    top: -69px;

    @include bp('ken-breakpoint-l') {
      top: -83px;
    }
  }

  a,
  a:link,
  a:visited {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  button {
    border: none;
    border-radius: 0;
    background: none;

    &:focus {
      outline: none;
    }
  }

  &__gap {
    width: 100%;

    &--stripeLinks {
      height: 39px;
    }

    &--headerBand {
      height: 69px;

      @include bp('ken-breakpoint-l') {
        height: 87px;
      }

      &--lite {
        height: 67px;

        @include bp('ken-breakpoint-l') {
          height: 56px;
        }
      }
    }
  }

  .--hidden {
    display: none;
  }
}
