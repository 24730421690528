.ken-homepagecategoryslider {
  .ken-hero {
    &__textblock-kicker {
      display: block;
    }
  }

  .ken-categorycarousel.ken-topcategorycarousel {
    @extend .ken-categorycarousel;
    position: relative;

    &:not(hideMobileNavBtns) .swiper-container {
      width: calc(100% - 50px);
    }

    .hidden {
      visibility: hidden;
    }

    .swiper-wrapper {
      &.centered {
        justify-content: center;
      }
      &.sliderActive {
        @include bp('ken-breakpoint-s') { 
          position: relative;
          left: -40%;
        }
      }
      .swiper-slide {
        .ken-titleimage--text {
          font-weight: normal;
          text-shadow: none;
        }

        @include bp('ken-breakpoint-s') {
          min-height: 140px;
          justify-content: flex-start;
          padding: 0;

          &:hover {
            border-bottom-color: $ken-color-grey-m;
          }
        }
        &-active,
        &-active:hover {
          border-bottom: 3px solid  $ken-color-rhubarb;

          .ken-titleimage--text {
            font-weight: bold;
            text-shadow: 0 0 1px #444;
          }
        }
      }
    }

    &.hideMobileNavBtns {
      .swiper-wrapper {
        transform: translateX(0) !important;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: flex!important;
      top: calc(50% - 9px);
    }

    &.hideMobileNavBtns {
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }

    &.hideDesktopNavBtns {
      @include bp('ken-breakpoint-s') {
        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }
      }
    }
  }

  .ken-subcategoryslider {
    @include bp('ken-breakpoint-m') {
      .--noCarousel {
        .ken-subcategorylist__item.swiper-slide {
          pointer-events: auto;
        }
      }
    }
    &.hideBottom {
      .ken-subcategorylist__subslider-pane {
        display: none;
      }
    }
  }

  .ken-hero__textblock-title {
    @include ken-text-mont-bold-h3;
  }

  @include bp('ken-breakpoint-s') { 
    .ken-hero__textblock--desktop-light {
      & .ken-hero__textblock-title {
          color: $ken-color-white;
      }
    }
  }

  @include bp('ken-breakpoint-max-s', 'max') {
    .ken-hero__textblock--mobile-light {
      & .ken-hero__textblock-title {
          color: $ken-color-white;
      }   
    }
  }
}