$rwd-m-padleft: 5.3334vw;
$rwd-m-slidew: 43.334vw;
$rwd-m-titleimageh: 76.087%;
$rwd-m-cardimgcanvash: 68%;
$rwd-m-cardtexth: 32%;
$rwd-d-slidew: 23.8334%;
$rwd-d-slideh: 375px;
$rwd-d-titleimageh: 76.4648%;
$rwd-d-cardimgcanvash: 76.72727%;
$rwd-d-cardtexth: 23.273%;

.ken-editorialcard {
  height: auto;
  width: 100%;
  overflow: hidden;
  padding: 40px $rwd-m-padleft;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $ken-color-black;
  background-color: $ken-color-grey-xs;

  @include bp('ken-breakpoint-s') {
    position: relative;
    padding: 67px 20px 36px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 25px;
    @include ken-text-h2;
    text-align: center;

    @include bp('ken-breakpoint-s') {
      justify-content: center;
      margin: 0 37px 20px;
      padding: 0;
      text-align: initial;
    }

    @include bp('ken-breakpoint-xl') {
      margin: 0 0 30px;
    }
  }

  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      height: 69.334vw;
      padding: 0;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: $rwd-m-slidew;
        max-width: $rwd-m-slidew;
        padding: 15px;
        background-color: $ken-color-white;
        text-align: center;

        @include bp('ken-breakpoint-s') {
          padding: 20px 0 0;
          height: 31.25vw;
          width: $rwd-d-slidew;
          max-height: $rwd-d-slideh;
          max-width: $rwd-d-slidew;
        }

        .ken-titleimage {
          height: $rwd-m-titleimageh;
          max-height: 100%;

          &--imgcanvas {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: $rwd-m-cardimgcanvash;
            max-height: $rwd-m-cardimgcanvash;
            width: 100%;
            overflow: hidden;
            margin: 0;
            padding: 0;

            @include bp('ken-breakpoint-s') {
              height: $rwd-d-cardimgcanvash;
              max-height: $rwd-d-cardimgcanvash;
            }
          }

          &--img {
            height: auto!important;
            width: auto!important;
            max-height: 100%;
            max-width: 100%;
          }

          &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: auto;
            min-height: $rwd-m-cardtexth;
            overflow: hidden;
            color: $ken-color-black;
            font-family: var(--fontNameBold);
            font-size: 14px;
            font-weight: bold;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            text-decoration: none;
            text-shadow: none;

            @include bp('ken-breakpoint-s') {
              min-height: $rwd-d-cardtexth;
              margin-top: 0;
              font-size: 16px;
              line-height: 1.38;
              letter-spacing: -.3px;
            }
          }

          @include bp('ken-breakpoint-s') {
            height: $rwd-d-titleimageh;
            width: 100%;
            justify-content: flex-start;
          }
        }
      }

      @include bp('ken-breakpoint-s') {
        height: $rwd-d-slideh;
        padding: 0 20px;
      }

      @include bp('ken-breakpoint-l') {
        padding: 0;
      }

    }
  }

  &__ctalink {
    @extend .ken-button--link;
    @extend .ken-button--secondary--small;
    padding: 10px;
    font-family: var(--fontNameBold);
    font-size: 12px;
    font-weight: bold;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: none;
  }

  .swiper-footer {
    max-width: none;
    margin: 30px 0 0;
    width: 100%;
    padding: 0;

    @include bp('ken-breakpoint-s') {
      height: 40px;
      width: 49.3334%;
      max-width: 592px;
      margin: 20px auto 0;
      padding: 0;
    }

    .swiper-scrollbar {
      width: 100%;
      height: 3px;
      background-color: $ken-color-grey-s;

      margin: 0;
      padding-top: 0;

      @include bp('ken-breakpoint-s') {
        width: calc(100% - 90px);
        margin-right: 10px;
        order: 1;
      }

      &-drag {
        background-color: $ken-color-rhubarb;
      }
    }

    .ken-icon-activewrap {
      padding: 4px 3px 3px 4px;
      border-radius: 5px;
      background-color: $ken-color-none;

      &:hover,
      &:active {
        background-color: $ken-color-grey-xs;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;

      &::after {
        display: none !important;
        text-indent: -99999cm;
      }

      &.swiper-button-disabled {
        cursor: not-allowed;
      }

      @include bp('ken-breakpoint-s') {
        display: flex !important;
        padding: 8px;
      }

      &:not(.ken-icon-activewrap),
      &.ken-icon-activewrap::after {
        @extend .ken-icon;
        height: 17px;
        width: 17px;
      }
    }

    .swiper-button-prev {
      order: 2;
    }

    .swiper-button-next {
      order: 3;
    }

    .swiper-button-prev::after,
    .swiper-button-prev:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-left-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-left-grey;
      }
    }

    .swiper-button-next::after,
    .swiper-button-next:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-right-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-right-grey;
      }
    }
  }

  &.ken-swiper-disabled {
    .swiper-wrapper {
      justify-content: center;

      .swiper-slide:nth-child(2) {
        margin-left: 10px;

        @include bp('ken-breakpoint-s') {
          margin-left: 20px;
        }
      }
    }

    .swiper-footer {
      display: none;
    }

    &-desktop {
      @include bp('ken-breakpoint-s') {
        .swiper-wrapper {
          justify-content: center;

          .swiper-slide:last-child {
            margin-right: 0;
          }
        }
        .swiper-footer {
          display: none;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-editorialcard {
    &.ken-swiper-disabled {
      .swiper-wrapper {
        .swiper-slide:nth-child(2) {
          margin-right: 10px;

          @include bp('ken-breakpoint-s') {
            margin-right: 20px;
          }
        }
      }

      .swiper-footer {
        display: none;
      }

      &-desktop {
        @include bp('ken-breakpoint-s') {
          .swiper-wrapper {
            justify-content: center;

            .swiper-slide:last-child {
              margin-right: 0;
            }
          }
          .swiper-footer {
            display: none;
          }
        }
      }
    }

    @include bp('ken-breakpoint-s') {
      .swiper-footer {
        /*
        margin-right: calc((183px * 1.56284) + 20px);
        */

        .swiper-pagination {
          order: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          right: 0;
          left: 0;
        }
        .swiper-button-prev {
          order: 3;
        }
        .swiper-button-next {
          order: 2;
        }
      }
    }
  }
}
