$ctasDesktopWidth: 286px;

.ken-compare {
  &__tray {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(500px);
    background: $ken-color-cheddar;
    border-top: 1px solid $ken-color-black;
    transition: transform .5s ease-in-out;
    z-index: 3;

    &.--open {
      transform: translateY(0)!important;
      .ken-compare__tray__title {
        i {
          transform: rotate(180deg);
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: var(--fontNameBold);
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: $ken-color-black;
      padding: 18px 20px;
      cursor: pointer;

      i {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        transition: transform .3s ease-in-out;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 20px;

      @include bp('ken-breakpoint-m') {
        flex-direction: row;
        padding: 15px 20px;
      }

      @include bp('ken-breakpoint-l') {
        padding: 15px 0;
      }

      &__products {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include bp('ken-breakpoint-m') {
          width: calc(100% - #{$ctasDesktopWidth});
          padding-right: 10px;
        }

        .--product {
          position: relative;
          display: flex;
          background: $ken-color-white;
          width: 32%;
          border: 1px dashed $ken-color-grey-l;
          min-height: 65px;

          @include bp('ken-breakpoint-m') {
            min-height: 120px;
          }

          &.--fill {
            border: 1px solid $ken-color-black;
          }

          button {
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            border: none;
            width: 20px;
            height: 20px;
            cursor: pointer;
            @include bp('ken-breakpoint-m') {
              width: 30px;
              height: 30px;
            }
          }

          .--product-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;
            width: 100%;

            @include bp('ken-breakpoint-m') {
              flex-direction: row;
              justify-content: unset;
              align-items: center;
            }

            .--product-image {
              display: block;
              width: 100px;
              height: 100px;
              padding: 10px;

              img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
              }
            }

            .--product-info {
              width: 100%;
              @include bp('ken-breakpoint-m') {
                width: calc(100% - 100px);
                padding-left: 5px;
              }
            }

            .--product-name {
              display: none;
              @include bp('ken-breakpoint-m') {
                display: block;
                font-family: var(--fontNameBold);
                font-weight: bold;
                font-size: 13px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.23;
                letter-spacing: 0.2px;
                color: $ken-color-black;
                margin-bottom: 5px;
              }
            }

            .--product-code {
              display: inline-block;
              width: 100%;
              font-family: var(--fontNameRegular);
              font-size: 11px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.09;
              letter-spacing: normal;
              text-align: center;
              color: $ken-color-grey-xxl;
              @include bp('ken-breakpoint-m') {
                font-size: 12px;
                line-height: 1.33;
                text-align: left;
              }
            }
          }
        }

      }
      &__ctas {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include bp('ken-breakpoint-m') {
          flex-direction: column;
          width: $ctasDesktopWidth;
        }

        .--label {
          display: none;
          @include bp('ken-breakpoint-m') {
            display: block;
            font-family: var(--fontNameLight);
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: $ken-color-black;
            width: 100%;
            margin-bottom: 15px;
          }
        }

        .--compare,
        .--clear {
          width: calc(50% - 5px);
          @include bp('ken-breakpoint-m') {
            width: 100%;
            max-width: 165px;
          }
        }

        .--compare {
          @include bp('ken-breakpoint-m') {
            margin-bottom: 10px;
          }
        }

        .--clear {
          order: 2;
        }
      }
    }
  }
}
