.ken-articleblock-one {
  background: $ken-color-white;
  padding: 60px 0 30px;
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    padding: 50px 0;
    margin: 0 auto;
  }

  &__title {
    @include ken-text-h1;
    text-align: center;
    margin-bottom: 20px;

    @include bp('ken-breakpoint-s') {
      margin-bottom: 30px;
    }
  }

  &__content {
    @include ken-text-mont-article-light;
    text-align: center;
    margin-bottom: 30px;

    @include bp('ken-breakpoint-s') {
      margin-bottom: 50px;
    }
  }

  &__image {
    max-width: 100%;
  }

  ul, ol, p, table {
    @include ken-text-mont-body-light;
    margin: 30px 0 60px;
  }

  li {
    @include ken-text-mont-body-light;
    list-style-position: inside;
  }

  table {
    border: 1px solid var(--ui-04);
    border-collapse: collapse;
  }

  td, th {
    @include ken-text-mont-body-light;
    text-align: left;
    padding: 8px;
  }

  a, td a, p a, li a {
    @include ken-text-link;
  }
}