.ken-compare {
  &__popup__content {
    &__products {
      display: flex;
      margin-top: 20px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      padding: 0 20px;

      @include bp('ken-breakpoint-m') {
        max-width: 75%;
        margin-top: 10px;
        align-self: flex-end;
        padding: 0;
      }

      .--product {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 33.333333333%;
        order: 0;
        border: 1px solid transparent;
        border-right-color: $ken-color-grey-m;

        @include bp('ken-breakpoint-m') {
          padding: 0 20px;
        }

        &:first-child.--fill {
          @include bp('ken-breakpoint-m') {
            border-left-color: $ken-color-grey-m;
          }
        }

        &:nth-child(3) {
          border-right-color: transparent;
        }

        &-close-cta {
          border: 0;
          background-color: transparent;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }

        &-image {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 100%;
          padding: 25px 25px 10px;
          img {
            display: block;
            max-width: 100%;
          }
        }

        &-info {
          display: block;
          width: 100%;
          flex: 1;
          padding: 0 5px;

          .--product:first-child & {
            padding: 0 5px 0 0;
          }

          .--product:last-child & {
            padding: 0 0 0 5px;
          }

          .dlg-omnibus-price {
            @include bp('ken-breakpoint-m') {
              flex-direction: column;
              align-items: flex-start;
              gap: 0;
            }

            &__main-price,
            &__old-price {
              font-size: 11px;
            }

            &__main-price {
              @include bp('ken-breakpoint-m') {
                font-size: 14px;
              }
            }

            &__old-price {
              gap: 0;

              @include bp('ken-breakpoint-m') {
                font-size: 12px;
                gap: 5px;
              }
            }
          }
        }

        &-name {
          display: none;
          font-family: var(--fontNameBold);
          font-weight: bold;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: 0.2px;
          color: $ken-color-black;

          @include bp('ken-breakpoint-m') {
            display: block;
          }
        }

        &-code {
          display: block;
          font-family: var(--fontNameRegular);
          font-size: 11px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.09;
          letter-spacing: normal;
          color: $ken-color-grey-xxl;
          width: 100%;
          padding: 0;

          @include bp('ken-breakpoint-m') {
            font-size: 12px;
            line-height: 1.33;
          }
        }

        &-link {
          display: inline-block;
          max-width: 95%;
          margin: 0 5px;
          font-family: var(--fontNameSemibold);
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $ken-color-black;
          text-align: start;

          @include bp('ken-breakpoint-m') {
            display: none;
          }
        }

        &-cta {
          display: none;
          @include bp('ken-breakpoint-m') {
            display: inline-block;
            margin: 15px auto 0;
            max-width: 95%;
          }
        }
      }
    }
  }
}