.ken-compare {
  &__popup {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -99999px;
    right: 0;
    background-color: $ken-color-white;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    z-index: 100;
    &.--open {
      visibility: visible;
      opacity: 1;
      display: flex;
      flex-direction: column;
      left: 0;
    }
  }
}