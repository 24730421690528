.ken-header-v2 {
  &__menu-item {
    position: absolute;
    top: 68px;
    left: 0;
    z-index: -20;
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba($ken-color-grey-m, 0.6);
    backdrop-filter: blur(15px);
    opacity: 0;

    .--active & {
      display: block;
    }

    &.--visible {
      opacity: 1;
    }

    &__wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      background-color: $ken-color-white;
      border-top: 2px solid $ken-color-grey-xxs;
    }

    &__top-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 2px solid $ken-color-grey-xxs;
    }

    &__category-tiles {
      display: flex;
      flex-wrap: wrap;
      gap: 24px 9px;
      width: 981px;
      padding: 35px 34px;
      border-right: 2px solid $ken-color-grey-xxs;

      @include bp('ken-breakpoint-xl') {
        gap: 16px;
        padding: 40px 39px;
      }
    }

    &__promo-cards {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 35px 30px;

      @include bp('ken-breakpoint-xl') {
        gap: 16px;
        padding: 40px;
      }
    }

    &__promo-row {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 35px;

      @include bp('ken-breakpoint-xl') {
        padding: 39px;
      }
    }
  }
}
