.dln-kenwood {
  .dlg-omnibus-price {
    padding: 10px 0;
    font-family: var(--fontNameBold);

    &__main-price {
      font-weight: 700;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: .2px;
      color: $ken-color-black;
    }

    &__old-price {
      font-size: 12px;
      line-height: 1.33;
      color: $ken-color-grey-xxl;
    }
  }
}
