.ken-plp {
  &__applied-filters__wrapper {
    background-color: $ken-color-grey-xs;
    padding: 10px 20px;
    @include bp('ken-breakpoint-s') {
      padding: 10px 5px;
    }
    @include bp('ken-breakpoint-l') {
      padding: 10px 0;
    }
  }
  &__applied-filters  {
    background-color: transparent;
    .ken-plp__applied-filters__slide { // full class name in order to override swiper ootb rule
      width: auto;
      align-self: center;
    }

    button {
      display: flex;
      align-items: center;
      border: 0;
      background-color: $ken-color-white;
      width: auto;
      padding: 8px 10px;
      cursor: pointer;
      font-family: var(--fontNameLight);
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: $ken-color-black;

      &.--no-background {
        background-color: transparent;
      }

      i {
        width: 25px;
        height: 25px;
        margin-left: 5px;
      }
    }
  }
}