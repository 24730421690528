.ken-editorialslider {
  @include ken-swiper-arrows;
  @include ken-swiper-scrollbar;

  padding: 50px 0;

  @include bp('ken-breakpoint-s') {
    padding: 100px 0;
  }

  &__wrapper {
    margin: 0 20px;

    @include bp('ken-breakpoint-s') {
      margin: 0 auto;
    }
  }

  &__title {
    @include ken-text-h2;
    text-align: center;
    margin-bottom: 30px;
  }

  .swiper-container {
    .slide-wrapper {
      justify-content: center;
    }

    .swiper-slide {
      transition:all 200ms linear;
      transform: scale(0.8);
    
      &.swiper-slide-active{
        transform: scale(1);
      }
    }

    .swiper-button-prev {
      left: 80px;
    }

    .swiper-button-next {
      right: 80px;
    }

    .swiper-footer {
      @include ken-swiper-scrollbar;
      margin: 30px auto 0;
      max-width: 388px;
    }
  }

  /* Overrides for KEN Editorial Content */

  .ken-editorialcontent {
    &__card {
      flex-direction: column;

      @include bp('ken-breakpoint-s') {
        flex-direction: row;
      }

      &__kicker {
        @include ken-text-mont;
        letter-spacing: .8px;
        text-transform: uppercase;
      }

      &__title {
        @include ken-text-h3;
      }

      &__content {
        @include ken-text-mont-body-light;
      }

      &__link {
        font-family: var(--fontNameSemibold);

        &--primary {
          @extend .ken-button--primary;
        }
  
        &--secondary {
          @extend .ken-button--secondary;
        }
  
        &--alternative {
          @extend .ken-button--alternative;
        }
      }
    }
  }
}
