$ken-editorialaccordion-content-hmargins: 6.896552%;

.ken-editorialaccordion {
  width: calc(100% - 40px);
  background-color: $ken-color-grey-xs;

  @media (min-width: 1240px) {
    width: 100%;
  }

  &__wrapper {
    margin: 0 20px;
    padding-bottom: 18px;
  }

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    padding-top: 18px;
    background-color: $ken-color-grey-xs;
    cursor: pointer;
  }

  &__titles {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: $ken-color-black;
  }

  &__title,
  &__subtitle,
  &__content {
    margin-right: 24px;
  }

  &__title {
    @include ken-text-h5;
    font-size: 16px;
    line-height: 24px;
  }

  &__subtitle,
  &__content,
  &__content p {
    @include ken-text-body-light;
    font-family: var(--fontNameRegular);
    font-size: 14px;
  }

  &__subtitle {
    opacity: 1;
    max-height: none;
    transition: all 600ms ease;
    margin-top: 12px;
  }

  &__icon {
    position: absolute;
    right: 0;
    display: flex;
    align-self: flex-start;
    height: 16px;
    width: 16px;
    margin: 4px 1px;

    &:before {
      width: 16px;
      top: 7px;
    }

    &:after {
      height: 16px;
      left: 7px;
    }
  }

  &__content {
    ul,
    ol {
      margin: 10px 0;

      > li {
        margin-left: 1em;
        padding-left: .5em;
      }
    }

    h3 {
      display: block;
      margin: 30px 0 10px 0;
      line-height: 17px;
      font-family: var(--fontNameBold);
      font-weight: bold;
      font-size: 14px;
    }

    em {
      font-style: italic;
    }

    a {
      text-decoration: underline;
    }

    b,
    strong {
      font-weight: bold;
    }
  }

  @include bp('ken-breakpoint-s') {
    &__wrapper {
      padding-bottom: 0;
    }

    &__header {
      padding-bottom: 18px;
    }

    &__titles {
      flex-direction: row;
    }

    &__subtitle {
      display: flex;
      align-self: flex-end;
      margin-top: 0;
    }

    &__content {
      opacity: 0;
      max-height: 0;
      margin-top: 25px;
      margin-left: $ken-editorialaccordion-content-hmargins;
      margin-right: $ken-editorialaccordion-content-hmargins;
      transition: all .3s ease-in-out;
    }

    &__icon {
      display: block;
    }
  }

  &.ken-simple-css-accordion {
    input[type='checkbox'] {
      &:checked + .ken-simple-css-accordion__wrapper {

        .ken-editorialaccordion__header {
          padding-bottom: 0;
        }

        .ken-editorialaccordion__subtitle {
          transition: all 30ms ease;
          opacity: 0;
          max-height: 0;
        }

        .ken-simple-css-accordion__content-desktop {
          position: relative;
          visibility: visible;
          opacity: 1;
          max-height: none;
        }

        @include bp('ken-breakpoint-s') {
          padding-bottom: 18px;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-editorialaccordion {
    &__title,
    &__subtitle,
    &__content {
      margin-right: 0;
      margin-left: 24px;
    }

    &__icon {
      right: unset;
      left: 0;
    }
  }
}
