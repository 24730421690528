.dln-kenwood {
  .dln-paperless-product {
    padding: 35px 20px;

    @include bp('ken-breakpoint-m') {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 60px 20px;
      max-width: 1200px;
      margin: 0 auto;
    }

    &__product-details {
      display: flex;
      align-items: center;
      justify-content: center;

      @include bp('ken-breakpoint-m') {
        flex-direction: column;
        max-width: 300px;
        margin-right: 25px;
      }

      @include bp('ken-breakpoint-l') {
        margin-right: 90px;
      }

      &-image {
        width: 180px;
        flex-shrink: 0;

        @include bp('ken-breakpoint-m') {
          width: 300px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      &-title {
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        @include bp('ken-breakpoint-m') {
          margin-left: 0;
          margin-top: 30px;
          align-items: center;
          flex-shrink: 0;
        }
      }

      &-name {
        font-size: 18px;
        font-family: var(--fontNameSemibold);

        @include bp('ken-breakpoint-m') {
          font-size: 20px;
          text-align: center;
        }
      }

      &-number {
        margin-top: 10px;
        font-size: 12px;
        color: $ken-color-grey-xxl;
      }
    }

    // Manuals section

    &__manuals {
      padding-top: 35px;

      @include bp('ken-breakpoint-m') {
        padding-top: 0;
        flex-grow: 1;
      }

      &-header {
        padding-bottom: 35px;

        @include bp('ken-breakpoint-m') {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 55px;
        }
      }

      &-title {
        text-align: center;

        span {
          font-size: 28px;
          text-transform: uppercase;
          font-family: var(--fontNameSemibold);

          @include bp('ken-breakpoint-m') {
            font-size: 30px;
          }
        }
      }

      &-selector {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include bp('ken-breakpoint-m') {
          padding-top: 0;
        }

        &-label {
          padding-right: 15px;

          span {
            font-size: 13px;
          }
        }

        &-button {
          background-color: transparent;
          border: 1px solid $ken-color-grey-m;
          padding: 8px 10px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          cursor: pointer;
          width: 70px;

          span {
            font-size: REM(18px);
          }

          i {
            margin-left: 5px;
            font-size: 12px;
            transform: rotateX(180deg);
          }

          &.--open {
            i {
              transform: rotateX(0deg);
            }
          }
        }

        &-items {
          position: absolute;
          border: 1px solid $ken-color-grey-m;
          background-color: $ken-color-white;
          max-height: 215px;
          overflow-y: auto;
          top: 40px;
          right: 0;

          @include bp('ken-breakpoint-m') {
            max-height: 155px;
          }
        }

        &-item {
          width: 227px;
          display: block;
          padding: 20px 32px;
          background-color: $ken-color-white;
          border: none;
          cursor: pointer;
          font-size: REM(18px);

          &:hover {
            background-color: $ken-color-grey-m;
          }

          @include bp('ken-breakpoint-m') {
            width: 164px;
          }
        }
      }

      &-download {
        display: flex;
        flex-direction: column;

        &-item {
          display: flex;
          align-items: center;
          padding: 20px 0;
          text-decoration: none;
          border-bottom: 1px solid $ken-color-grey-m;

          @include bp('ken-breakpoint-m') {
            padding: 26px 0;
          }
        }

        &-info {
          padding: 0 16px;
          display: flex;
          flex-direction: column;

          @include bp('ken-breakpoint-m') {
            padding: 0 28px;
            flex-grow: 1;
          }
        }

        &-name {
          padding-bottom: 5px;
          color: $ken-color-black;
          font-size: 16px;
        }

        &-size {
          color: $ken-color-grey-l;
          font-size: 14px;
        }

        &-pdf-icon {
          width: 25px;
          height: 30px;
          background-image: url("#{$pathAssets}/images/kenwood/paperless/pdf.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &-icon {
          margin-left: auto;

          &::after {
            content: 'download';
            font-size: 12px;
            font-family: var(--fontNameBold);
            text-decoration: underline;
            color: $ken-color-black;
          }
        }
      }
    }
  }
}