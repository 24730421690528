.ken-basic-form {
  padding: 20px;

  @include bp('ken-breakpoint-m') {
    padding: 0;
  }

  &__title {
    margin-bottom: 30px;
    font-family: var(--fontNameExtra);
    font-size: 28px;
    line-height: 1.21;
    text-align: center;
    color: $ken-color-black;

    @include bp('ken-breakpoint-m') {
      line-height: 1.29;
      text-align: left;
    }
  }

  .dlg-gigya-input-text-wrapper {
    .gigya-label {
      position: static;
      padding: 0;
      transform: none;
      font-family: var(--fontNameRegular);
      font-size: 12px;
      line-height: 1.33;
      color: $ken-color-grey-l;
    }

    input {
      border: 1px solid $ken-color-black !important;
      border-radius: 0 !important;
      padding: 15px 12px;
      font-family: var(--fontNameRegular);
      font-size: 13px;
      line-height: 1.15;
      color: $ken-color-black;
    }
  }

  [data-screenset-element-id=gigya-newsletter-screen] {
    .gigya-custom-checkbox {
      display: flex;
      margin: 0;

      .gigya-input-checkbox {
        &+.gigya-label:before {
          width: 14.4px;
          height: 14.4px;
          border: 1px solid $ken-color-black;
          border-radius: 0;
          margin-top: 0;
        }

        &:checked+.gigya-label {
          &:before {
            border: 1px solid $ken-color-rhubarb;
          }

          &:after {
            left: 2px;
            font-size: 11px;
            color: $ken-color-rhubarb;
          }

          .gigya-checkbox-text {
            color: $ken-color-grey-xxl;
          }
        }
      }

      .gigya-checkbox-text {
        display: flex;
        padding-left: 5px;
        font-family: var(--fontNameRegular);
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        color: $ken-color-grey-xxl;
      }
    }

    .dlg-gigya-button-wrapper {
      display: flex;
      justify-content: center;

      input[type=submit] {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
        padding: 11px 20px;
        background-color: $ken-color-rhubarb;
        font-family: var(--fontNameSemibold);
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: center;
        color: $ken-color-white;
      }
    }

    #gigya-subscribe-with-email-form>.gigya-layout-row {
      .gigya-layout-row:nth-child(1),
      .gigya-layout-row:nth-child(2),
      .gigya-layout-row:nth-child(4) {
        @include bp('ken-breakpoint-m') {
          width: 250px;
          margin-right: 20px;
        }
      }

      .gigya-layout-row:nth-child(4) {
        @include bp('ken-breakpoint-m') {
          margin-right: 0;
        }
      }

      .gigya-layout-row:nth-child(5) {
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          border: 2px solid $ken-color-grey-xxl;
          background-color: $ken-color-white;
          color: $ken-color-grey-xxl;
        }

        .gigya-toggle-content {
          margin: 30px 0 0;
        }
      }
    }
  }
}
