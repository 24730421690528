.ken-subcategorylist {
  margin: 0;

  .ken-hero {
    margin: 0 auto;

    &__textblock {
      background-color: $ken-color-white;
      color: $ken-color-black;

    &--mobile-light {
      @include bp('ken-breakpoint-max-s', 'max') {
        color: $ken-color-white;
        background-color: $ken-color-black;
      }
    }

      &-kicker {
        display: block;
      }

      &-title {
        @include ken-text-mont-bold-h3;
        color: inherit;
      }

      &-content {
        font-size: 14px;
        line-height: 1.43;
      }

      &--mobile-light {
        @include bp('ken-breakpoint-max-s', 'max') {
          color: $ken-color-white;
          background-color: inherit;
        }
      }
    }

    &--secondary {
      .ken-hero__textblock {
        background-color: $ken-color-none;
      }
    }
    @include bp('ken-breakpoint-s') {
      &__textblock {
        &--desktop-light {
          color: $ken-color-white;
        }
      }
    }
  }

  &__subslider-pane {
    background-color: $ken-color-grey-xs;
    padding: 0 20px;
  }

  &__sideText {
    display: flex;
    justify-content: center;
    padding: 35px 0 0;
    background: $ken-color-grey-xs;
    color: $ken-color-black;

    .ken-titlesubtitlelink {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__title {
        margin: 0 25px;
        color: inherit;
        @include ken-text-mont-bold-h3;
        font-weight: normal;
      }

      &__subtitle {
        margin-top: 10px;
        color: inherit;
        font-family: var(--fontNameRegular);
        font-size: 14px;
        line-height: 1.43;
      }

      &__link {
        @extend .ken-button--link;
        padding: 10px 0;
        color: inherit;
        font-family: var(--fontNameBold);
        font-weight: bold;
        text-transform: none;
      }
    }
  }

  &__slider {
    &.swiper-container {
      width: 100%;
      display: block;
      padding: 30px 0;

      &.--noCarousel {
        > .swiper-wrapper {
          justify-content: center;

          > .swiper-slide {
            width: auto;
            margin: 0 5px;
          }
        }

        > .swiper-footer {
          display: none;
        }
      }
    }
  }

  &__item {
    &.swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px;
      text-align: center;
      background-color: $ken-color-white;
      height: 240px;
    }
  }

  & .swiper-footer {
    max-width: none;
    margin: 0;
    width: 100%;
    padding: 35px 0 5px;

    .swiper-scrollbar {
      height: 3px;
      width: 100%;
      margin: 0;
      padding-top: 0;
      background-color: $ken-color-grey-s;

      &-drag {
        background-color: $ken-color-rhubarb;
      }
    }

    .ken-icon-activewrap {
      padding: 4px 3px 3px 4px;
      border-radius: 5px;
      background-color: $ken-color-none;

      &:hover,
      &:active {
        background-color: $ken-color-grey-xs;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;

      &::after {
        display: none !important;
        text-indent: -99999cm;
      }

      &.swiper-button-disabled {
        cursor: not-allowed;
      }

      &:not(.ken-icon-activewrap),
      &.ken-icon-activewrap::after {
        @extend .ken-icon;
        height: 17px;
        width: 17px;
      }
    }

    .swiper-button-prev {
      order: 2;
    }
    .swiper-button-next {
      order: 3;
    }

    .swiper-button-prev::after,
    .swiper-button-prev:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-left-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-left-grey;
      }
    }

    .swiper-button-next::after,
    .swiper-button-next:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-right-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-right-grey;
      }
    }
  }
}
