.ken-product-tile {
  &__productInfo {
    text-decoration: none;
    cursor: pointer;
  }
  &__name-and-price {
    display: flex;
    flex-direction: column;
    min-height: 70px;

    .--out-of-stock & {
      opacity: .5;
    }

  }

  //texts section
  &__name {
    width: calc(100% - 80px);
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        width: 100%;
      }
    }
  }

  &__identifier {
    font-family: var(--fontNameBold);
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.2px;
    color: $ken-color-black;
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: 0.17px;
      }
    }

    @include bp('ken-breakpoint-s') {
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: 0.2px;
    }
  }

  &__modelNumber {
    font-family: var(--fontNameRegular);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: $ken-color-grey-xxl;
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        font-size: 11px;
        line-height: 1.09;
      }
    }

    @include bp('ken-breakpoint-s') {
      font-size: 12px;
      line-height: 1.33;
    }
  }


  //price section
  &__price {
    .--list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      list-style: none;
      margin-top: 2px;
    }

    .--mainPrice {
      font-family: var(--fontNameBold);
      font-size: 14px;
      color: $ken-color-black;
      width: 100%;
    }

    .--strikePrice {
      text-decoration: line-through;
    }

    .--strikePrice,
    .--labelPrice {
      font-size: 12px;
      color: $ken-color-grey-xxl;
    }

    .--labelPrice {
      word-break: break-all;
    }
  }

  &__vat-label {
    font-family: var(--fontNameLight);
    font-size: 10px;
    color: $ken-color-black;
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        display: none;
      }
    }
  }

  //common styles
  &__identifier,
  &__model-number {
    display: block;
  }
}