$hs-white-mask: $ken-color-white-mask-50;
$hs-black-mask: $ken-color-black-mask-50;
$hs-grey-mask: $ken-color-black-mask-03;
$hs-blue-mask: $ken-color-blue-mask;

$hs-bg-spot: $ken-color-white;
$hs-border-spot: $ken-color-white;
$hs-border-hover-spot: $ken-color-rhubarb;

$hs-mobile-slide-separator: $ken-color-grey-s;
$hs-white: $ken-color-white;
$hs-grey: $ken-color-grey-sm;

$hs-bp-s: 'ken-breakpoint-s';
$hs-bp-m: 'ken-breakpoint-m';
$hs-bp-l: 'ken-breakpoint-l';

@mixin hs-font-book {
  @include ken-text-body-mont;
}

@mixin hs-font-heavy {
  @include ken-text-body-mont;
  font-weight: bold;
}