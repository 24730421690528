.ken-category-cards {
  background-color: $ken-color-white;
  padding: 40px 5px;

  @include bp('ken-breakpoint-m') {
    padding-block: 150px;
  }

  &__text {
    max-width: 708px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    margin-inline: auto;
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-family: var(--fontNameSemibold);

    @include bp('ken-breakpoint-m') {
      font-size: 72px;
      line-height: 80px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--fontNameRegular);

    @include bp('ken-breakpoint-m') {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__cards {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 52px;
    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    max-width: 400px;

    @include bp('ken-breakpoint-m') {
      max-width: 388px;
    }

    img {
      max-width: 100%;
      object-fit: cover;
      display: block;

      @include bp('ken-breakpoint-m') {
        // max-height: 300px;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      font-family: var(--fontNameSemibold);
    }

    &-subtitle {
      font-size: 14px;
      line-height: 20px;
      font-family: var(--fontNameRegular);
    }

    &-link {
      @include ken-button;
      @include ken-button--primary;
    }
  }
}
