$tweakpoint-price-counter: 1270px;

.ken-configurator__price-counter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  color: $ken-color-black;
  padding: 0 15px;
  background-color: $ken-color-grey-s;
  width: 100%;

  @include bp('ken-breakpoint-m') {
    background-color: $ken-color-white;
    gap: 8px;
    padding: 0 10px;
    justify-content: flex-start;
  }

  .ken-configurator__price-counter__label,
  .ken-configurator__price-counter__prices {
    display: flex;
    align-items: center;
  }

  &__label {
    font-family: var(--fontNameSemibold);
    font-size: 10px;
    text-transform: uppercase;
    align-self: center;
    line-height: 15px;

    @include bp('ken-breakpoint-m') {
      font-size: 14px;
      line-height: 11px;
      text-transform: none;
    }
  }

  &__values {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__discount {
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    color: $ken-color-orangestone;
    align-self: center;
    margin-right: 5px;

    @include bp('ken-breakpoint-m') {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      justify-content: flex-start; 
      gap: 8px;
    }

    @include bp('ken-breakpoint-l') {
      flex-direction: column;
      align-items: flex-end;
    }

    @media (min-width: $tweakpoint-price-counter) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__insteadOf {
    font-family: var(--fontNameRegular);
    font-size: 12px;
    text-decoration: line-through;
    white-space: nowrap;
    margin-right: 5px;

    @include bp('ken-breakpoint-m') {
      font-size: 14px;
      margin-right: 0;
    }
  }

  &__total {
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  @include bp('ken-breakpoint-m') {
    &__total {
      order: 1;
      margin-left: 5px;
    }

    &__insteadOf {
      order: 2;
    }
  }
}
