.ken-pdp__main-info {
  &__prices {
    &__price-stock-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
      align-items: center;
    }

    &__vat-included {
      font-family: var(--fontNameRegular);
      font-size: 12px;
      color: $ken-color-grey-xxl;
    }

    &__stock {
      align-self: flex-end;
      margin-bottom: 5px;
      @include bp('ken-breakpoint-m') {
        align-self: unset;
        margin-bottom: unset;
      }
    }
  }

  &__prices-wrapper {
    padding: 25px 20px;
    background-color: $ken-color-grey-xs;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__wishlist {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: transparent;
      margin-right: 20px;

      @include bp('ken-breakpoint-s') {
        margin-right: 0;
      }

      .ken-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__add-to-cart,
  &__configure,
  &__notify-me {
    flex: 1;
  }

  &__add-to-cart,
  &__configure {
    text-transform: uppercase;
  }

  &__where-to-buy {
    margin-left: 10px;
    text-transform: uppercase;
    padding: 10px;

    @include bp('ken-breakpoint-s') {
      padding: 11px 20px;
    }
  }
}

.ken-pdp__prices {
  display: flex;
  flex-direction: column;
  font-family: var(--fontNameRegular);
  margin-bottom: 12px;
  gap: 7px;

  .--list {
    display: flex;
    align-items: baseline;
    list-style: none;
    gap: 7px;
  }

  .--mainPrice {
    font-family: var(--fontNameBold);
    font-size: 20px;
  }

  .--rrpLabel,
  .--strikePrice,
  .--discountPrice,
  .--lastLowerPriceLabel,
  .--lastLowerPrice,
  .--discountLastLowerPrice {
    font-size: 14px;
    color: $ken-color-grey-xxl;
  }

  .--strikePrice,
  .--lastLowerPrice.--line-through {
    text-decoration: line-through;
  }

  .--discountPrice,
  .--discountLastLowerPrice {
    font-family: var(--fontNameSemibold);
    color: $ken-color-rhubarb;
  }

  .--discountPrice::before,
  .--discountLastLowerPrice::before {
    content: '(';
  }

  .--discountPrice::after,
  .--discountLastLowerPrice::after {
    content: ')';
  }

  .--icon {
    position: relative;
    top: 2px;
  }

  .--weeeTax {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 4px;
    margin-block: 5px;
    font-size: 12px;

    .--icon {
      top: 0;
    }
  }
}
