.ken-header {
  &__gap {
    width: 100%;

    &--stripeLinks {
      height: 39px;
    }

    &--headerBand {
      height: 69px;

      @include bp('ken-breakpoint-l') {
        height: 87px;
      }

      &--lite {
        height: 67px;

        @include bp('ken-breakpoint-l') {
          height: 56px;
        }
      }
    }
  }
}
