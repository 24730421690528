$ken-box-margin-right: 20px;

.ken-pdp__recipes {
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
  }

  &__title {
    @include ken-pdp-components-title;
    text-align: center;
    margin-bottom: 28px;
  }

  &__button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    padding: 5px;

    @include bp('ken-breakpoint-m') {
      padding: 10px;
    }
  }

  &__box-wrapper {
    @include bp('ken-breakpoint-s') {
      margin-right: $ken-box-margin-right;
      flex: 0 0 calc(25% - #{($ken-box-margin-right * 3) / 4});
    }

    &:nth-child(4) {  
      margin-right: 0;
    }
  }

  button.dln-btnIcon {
    $size: 40px;
    width: $size;
    height: $size;
    background-color: rgba($ken-color-white, 0.8);
    border-radius: 50%;

    .dln-icn {
      $size: 24px;
      width: $size;
      height: $size;
      font-size: $size;
      color: $ken-color-rhubarb;
    }
  }

  &__box {
    margin-bottom: 30px;

    &__title {
      line-height: 17px;
      margin-bottom: 8px;
      &--url {
        @include  ken-pdp-recipes-title;
        text-decoration: none;
        color: $ken-color-black;
      }
    }

    &__image-wrapper {
      margin-bottom: 10px;
      @include bp('ken-breakpoint-s') {
        margin-bottom: 15px;
      }
    }

    &__image {
      max-width: 100%;
      width: 100%;
    }
  }

  &__wrapper {
    @include bp('ken-breakpoint-s') {
      justify-content: center;
    }
  }

  &__features {
    display: flex;
    flex-direction: column;
    color: $ken-color-grey-l;
    @include bp('ken-breakpoint-s') {
      flex-direction: row;
    }

    .dln-icn {
      $size: 24px;
      width: $size;
      height: $size;
      font-size: 24px;
      margin: 0 8px 0 0;
    }
  }

  &__feature {
    margin: 0 0 8px;
    display: flex;
    align-items: center;

    &:first-child {
      @include bp('ken-breakpoint-s') {
        margin: 0 15px 8px 0;
      }
    }
  }

  &__swiper-scrollbar-wrapper {
    @include bp('ken-breakpoint-s') {
      display: none;
    }

    .swiper-scrollbar {
      width: 100%;
      position: initial;
      height: 3px;
      border-radius: 0;
      background-color: $ken-color-grey-s;
  
      &-drag {
        border-radius: 0;
        background-color: $ken-color-rhubarb;
      }
    }
  }
}