.ken-product-tile {
  &__promo-and-stock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;
  }
  &__promo {
    span {
      display: inline-block;
      font-family: var(--fontNameBold);
      font-size: 11px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      padding: 4px 5px;
      margin-bottom: 5px;
      .--lite & {
        @include bp('ken-breakpoint-max-s', 'max') {
          font-size: 10px;
        }
      }
    }

    &-label {
      background-color: $ken-color-rhubarb-08;
      color: $ken-color-rhubarb;
    }

    &-label-discount {
      background-color: $ken-color-rhubarb;
      color: $ken-color-white;
    }
  }
  &__stock {
    font-family: var(--fontNameSemibold);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: $ken-color-purple;
    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        display: none;
      }
    }
  }

  &__lite-info {
    .ken-product-tile__stock {
      .--lite & {
        @include bp('ken-breakpoint-max-s', 'max') {
          display: block;
          text-align: left;
          padding-top: 7px;
        }
      }
    }
  }
}