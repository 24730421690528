.ken-cookwithus {
  &__recipe {
    width: 100%;
    height: 100%;

    &__imgcanvas {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100%;
      overflow: hidden;
    }

    &__image{
      display: block;
      height: auto;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }

    &__button-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      padding: 5px;

      @include bp('ken-breakpoint-m') {
        padding: 10px;
      }
    }

    button.dln-btnIcon {
      $size: 40px;
      width: $size;
      height: $size;
      background-color: rgba($ken-color-white, 0.8);
      border-radius: 50%;

      .dln-icn {
        $size: 24px;
        width: $size;
        height: $size;
        font-size: $size;
        color: $ken-color-rhubarb;
      }
    }

    &__infoblock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 2;
    }

    &__title {
      margin: 10px 0;
      font-family: var(--fontNameBold);
      font-size: 14px;
      font-weight: bold;
      line-height: 1.21;
      color: $ken-color-black;

      @include bp('ken-breakpoint-s') {
        margin: 15px 0 8px;
      }
    }

    &__features {
      display: flex;
      flex-direction: column;

      @include bp('ken-breakpoint-s') {
        flex-direction: row;
      }
    }

    &__feature {
      display: flex;
      align-items: center;
      color: $ken-color-grey-xxl;

      &:nth-child(2) {
        margin: 8px 0;

        @include bp('ken-breakpoint-s') {
          margin: 0 20px;
        }

      }

      &-desc {
        margin: 0 7px;
        font-family: var(--fontNameRegular);
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
      }

      .dln-icn {
        $size: 24px;
        width: $size;
        height: $size;
        font-size: 24px;
      }
    }
  }
}
