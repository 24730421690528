$ken-video--plyr-controls-basecolor: var(--plyr-accent-color);
$support-02: $ken-video--plyr-controls-basecolor;
$ui-03: #eaeaea; // temp

@mixin ken-video-ctr-fgcolor($fgcolor: $ken-video--plyr-controls-basecolor) {
  color: $fgcolor;
}

@mixin ken-video-ctr-bgcolor($bgcolor: $ken-video--plyr-controls-basecolor) {
  background: $bgcolor;
}

@mixin ken-video-ctr-boxshad($color: $ken-video--plyr-controls-basecolor) {
  box-shadow: 0 0 0 2px $color;
}

@mixin ken-video-ctr-colorize($fgcolor: $ken-color-white, $bgcolor: $ken-video--plyr-controls-basecolor) {
  @include ken-video-ctr-fgcolor($fgcolor);
  @include ken-video-ctr-bgcolor($bgcolor);
}

@mixin ken-video-ctr-colorize-invert($fgcolor: $ken-video--plyr-controls-basecolor, $bgcolor: $ken-color-white) {
  @include ken-video-ctr-fgcolor($fgcolor);
  @include ken-video-ctr-bgcolor($bgcolor);
}

@keyframes pulse {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.ken-video {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include bp ('ken-breakpoint-s') {
    gap: 48px;
  }

  &.ready {
    .ken-video__content__link {
      display: block;
    }

    .ken-video__content__playIcon {
      opacity: 1;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @include bp ('ken-breakpoint-s') {
      gap: 30px;
    }

    &__title {
      font-size: 28px;
      font-family: var(--fontNameSemibold);

      @include bp ('ken-breakpoint-s') {
        font-size: 36px;
      }
    }

    &__subtitle {
      font-size: 14px;
      font-family: var(--fontNameRegular);

      @include bp ('ken-breakpoint-s') {
        font-size: 20px;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }

    &__preview {
      width: 100%;
      height: 100%;
      background-color: $ui-03;

      &__image {
        width: 100%;
      }
    }

    &__caption {
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      padding: 0;
      background: rgba($ken-color-white, 0.2);
      border-radius: 50%;
      box-shadow: 0 0 0 2px $ken-color-white;

      .ken-video__content__playIcon {
        width: 8px;
        height: 8px;
        margin-left: 4px;
        border-style: solid;
        border-width: 7px 0 7px 9px;
        ;
        border-color: transparent transparent transparent $ken-color-white;
        transition: 100ms all ease;
        cursor: pointer;
      }
    }

    &__video-title {
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      @include bp ('ken-breakpoint-s') {
        bottom: auto;
        top: calc(50% + 80px);
      }

      &__text {
        font-size: 20px;
        font-family: var(--fontNameSemibold);
        text-align: center;

        @include bp ('ken-breakpoint-s') {
          font-size: 30px;
          font-family: var(--fontNameBold);
        }
      }
    }

    &__player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .plyr {
        &__control {
          &.plyr__controls__item {

            &.plyr__tab-focus,
            &:hover {
              @include ken-video-ctr-bgcolor;
              @include ken-video-ctr-boxshad;
              opacity: 0.8;
            }
          }

          &--overlaid {
            @include ken-video-ctr-colorize-invert();
            box-shadow: 0 0 0 2px $ken-video--plyr-controls-basecolor;
            transform: scale(1) translate(-50%, -50%) !important;
            z-index: -1;

            &:hover,
            &.plyr__tab-focus {
              @include ken-video-ctr-colorize();
              transform: scale(1.2, 1.2);
              box-shadow: 0 0 0 2px $ken-video--plyr-controls-basecolor;
            }
          }
        }

        &__progress,
        &__volume {
          input[type=range] {
            @include ken-video-ctr-fgcolor;


            &.plyr__tab-focus,
            &:hover {
              @include ken-video-ctr-fgcolor($ken-video--plyr-controls-basecolor);
              @include ken-video-ctr-boxshad(transparent);
            }
          }
        }

        &__buffer {
          @include ken-video-ctr-fgcolor($ken-color-white-mask-50);
        }

        &__volume,
        &__menu {
          button {

            &.plyr__tab-focus,
            &:hover {
              @include ken-video-ctr-bgcolor;
              @include ken-video-ctr-boxshad;
            }
          }
        }
      }

      .ken-video__content__button-close {
        transform: scale(1);
        position: absolute;
        top: 0;
        right: 0;
        height: 3em;
        width: 3em;
        z-index: 50;
      }

      .plyr--paused~.ken-video__content__button-close {
        background: $ken-color-black-mask-80;
        @include ken-video-ctr-boxshad;
        top: 2px;
        right: 2px;
      }

      .ken-btn-close__icon {
        @include ken-button--plus--bw('white', '45');
      }

      &.visible {
        z-index: 3;
      }
    }
  }
}