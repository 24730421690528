.ken-plp__filters {
  &__mobile-popup__top {
    height: 80px;
    display: flex;
    align-items: center;
    background-color: $ken-color-grey-xs;
    padding: 0 20px;
    overflow: hidden;
    border-bottom: 2px solid $ken-color-grey-s;

    button {
      background: none;
      border: none;
      cursor: pointer;
    }

    .--label {
      font-family: var(--fontNameBold);
      font-weight: bold;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $ken-color-black;
      width: 50%;
      padding-right: 5px;
    }

    .--right-section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
    }

    .--clear-cta {
      margin-right: 20px;
      font-family: var(--fontNameSemibold);
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $ken-color-black;
      text-decoration: underline;
    }

    .--close-cta {
      width: 40px;
      height: 40px;
      margin-right: -8px;
      i {
        width: 40px;
        height: 40px;
      }
    }
  }
}
