.dln-kenwood {
  .dln-paperless-register {
    padding: 30px 0;

    &__title {
      text-transform: uppercase;
      margin-bottom: 35px;
      padding: 0 30px;
      text-align: center;
      font-size: 28px;
      font-family: var(--fontNameSemibold);

      @include bp('ken-breakpoint-m') {
        margin-bottom: 70px;
      }
    }

    &__items {
      padding: 0 20px;

      @include bp('ken-breakpoint-s') {
        width: 580px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
      }

      @include bp('ken-breakpoint-l') {
        width: 1180px;
      }

      @include bp('ken-breakpoint-xl') {
        width: 1250px;
      }
    }

    &__item {
      width: 283px;
      display: flex;
      margin-bottom: 18px;
      margin-left: auto;
      margin-right: auto;

      @include bp('ken-breakpoint-m') {
        margin-left: 0;
        margin-right: 0;
      }

      &-image {
        width: 50px;
        flex-shrink: 0;

        img {
          width: 100%;
        }
      }

      &-text {
        padding-left: 15px;

        &-title {
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 16px;
          font-family: var(--fontNameBold);
        }

        &-text {
          font-size: 14px;
          font-family: var(--fontNameRegular);
          line-height: 20px;
          color: $ken-color-grey-xxl;
        }
      }
    }

    &__cta {
      @include ken-button;
      @include ken-button--primary;

      &-wrapper {
        text-align: center;
        margin-top: 30px;

        @include bp('ken-breakpoint-m') {
          margin-top: 50px;
        }
      }
    }
  }
}