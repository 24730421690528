$rwd-trans-xspacing: 8.334%;
$rwd-wid-textblock-default: 42.2222%;
$ken-hero-nomedia-room-height: 500;

@mixin ken-hero--standard-kicker {
  @include ken-text-body-light;
  color: inherit;
  font-family: var(--fontNameRegular);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ken-hero {
  position: relative;
  overflow: hidden;

  &-wrapper {
    position: relative;
  }

  &__picture {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &-image {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__video-container {
    display: block;

    video {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  &__media-fallback-placeholder {
    height: 100%;
    width: 100%;
    width: 100%;
    background-color: $ken-color-grey-xs;
    opacity: .9;

    .fallback-message {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      @extend .ken-text-h5;
      color: $ken-color-error;
      text-align: center;
    }

    @include bp('ken-breakpoint-s') {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $ken-color-none;
      opacity: 1;

      .fallback-message {
        width: auto;
        height: #{$ken-hero-nomedia-room-height}px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: #{$rwd-trans-xspacing};
      }
    }
  }

  &__textblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 44px 20px 48px;
    background-color: $ken-color-grey-xs;
    color: $ken-color-black;

    &--mobile-light {
      @include bp('ken-breakpoint-max-s', 'max') {
        color: $ken-color-white;
        background-color: $ken-color-black;
      }
    }

    &-titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-kicker,
    &-title,
    &-content {
      text-align: center;
    }

    &-kicker {
      margin: 0 0 10px;
      @include ken-hero--standard-kicker;
    }

    &-title {
      @include ken-text-h1;
      color: inherit;

      &.--montserrat-bold {
        font-family: var(--fontNameBold);
        font-weight: bold;
      }
    }

    &-content {
      margin-top: 10px;
      color: inherit;
      font-family: var(--fontNameRegular);
      font-size: 16px;
      line-height: 1.5;
    }

    &-links {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 20px;

      .ken-hero__textblock-cta {
        margin-top: 0;
      }

      @include bp('ken-breakpoint-s') {
        flex-direction: row;
        gap: 16px;
      }
    }

    &-cta {
      margin-top: 30px;
      align-self: center;
      text-transform: uppercase;

      &--primary {
        @extend .ken-button--primary;
      }

      &--secondary {
        @extend .ken-button--secondary;
      }

      &--alternative {
        @extend .ken-button--alternative;
      }

      &--link {
        @extend .ken-button--link;
        padding: 10px 0;
        color: inherit;
        text-transform: initial;
      }
    }
  }

  &__breadcrumb {
    margin: 0 20px 10px;
  }

  @include bp('ken-breakpoint-s') {
    &__picture {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;

      &-image {
        width: auto;
        max-width: 100%;
        min-width: 70.555% !important;
      }
    }

    &__video-wrapper,
    &__video-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;

      video {
        max-height: 642px;
        max-width: 100%;
        min-width: 70.555% !important;
        width: auto !important;
      }
    }

    &__textblock {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      margin: 0;
      width: auto;
      max-width: #{$rwd-wid-textblock-default};
      padding: 0 0 0 #{$rwd-trans-xspacing};
      justify-content: center;
      background-color: $ken-color-none;
      color: inherit;

      &-titles {
        align-items: flex-start;
      }

      &-kicker,
      &-title,
      &-content {
        text-align: left;
      }

      &-title {
        font-size: 28px;
        line-height: 34px
      }

      &-kicker {
        margin-bottom: 5px;
        font-size: 10px;
        line-height: 14px;
      }

      &-content {
        font-size: 12px;
      }

      &-cta {
        align-self: flex-start;
        margin-top: 15px;
        font-size: 10px;
        line-height: 14px;
      }

      &--desktop-light {
        color: $ken-color-white;
      }
    }

    &__breadcrumb {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      margin: 0 0 10px #{$rwd-trans-xspacing};

      .ken-breadcrumb {
        margin: 0;
        padding: 15px 0;
      }
    }
  }

  @include bp('ken-breakpoint-m') {
    &__textblock {
      &-title {
        @include ken-text-h1;
        color: inherit;
      }

      &-kicker {
        margin-bottom: 12px;
        @include ken-hero--standard-kicker;
      }

      &-content {
        font-size: 14px;
      }

      &-cta {
        margin-top: 30px;
        font-size: 12px;
        line-height: 23px;
        @include ken-button--standard-text-size;
      }
    }
  }

  &--default {
    .ken-hero__textblock-title {
      @include bp('ken-breakpoint-s') {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }

  &--centered {
    .ken-hero-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @include bp('ken-breakpoint-s') {
        height: auto;
        display: block;
      }

      .ken-hero-wrapper>.ken-hero__picture {
        position: absolute;

        @include bp('ken-breakpoint-s') {
          position: static;
        }
      }

      .ken-hero__textblock {
        position: absolute;
        background-color: $ken-color-none;
      }

      .ken-hero__textblock,
      .ken-hero__textblock>* {
        max-width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
  }

  &--secondary {
    .ken-hero__textblock {
      position: flex;
      padding: 18px 20px;
      background-color: $ken-color-none;

      &-title {
        @include bp('ken-breakpoint-s') {
          font-size: 48px;
          line-height: 56px;
        }
      }

      &-content {
        font-size: 14px;
        line-height: 20px;

        @include bp('ken-breakpoint-s') {
          font-size: 12px;
          line-height: 18px;
        }

        @include bp('ken-breakpoint-m') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &--mobile-light {
        @include bp('ken-breakpoint-max-s', 'max') {
          color: $ken-color-white;
        }
      }

      @include bp('ken-breakpoint-s') {
        width: auto;
        max-width: 35.1389%;
        padding: 0 0 0 #{$rwd-trans-xspacing};

        &--desktop-light {
          color: $ken-color-white;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ken-hero {
    @include bp('ken-breakpoint-s') {
      &__textblock {
        padding: 0 #{$rwd-trans-xspacing} 0 0;

        &-kicker,
        &-title,
        &-content {
          text-align: right;
        }
      }
    }
  }
}