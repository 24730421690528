.ken-articleimage {
  background: $ken-color-white;
  padding: 30px 0;
  margin: 0 20px;

  @include bp('ken-breakpoint-s') {
    padding: 50px 0;
    margin: 0 auto;
  }

  &__image {
    max-width: 100%;
  }
}
