.ken-header-v2 {
  .ken-button {
    .dln-drawer {
      position: relative;
      display: block;
      visibility: visible;
      top: 10px;
    }
  }

  .ken-btnIcon--wishlist {
    .dln-drawer {
      top: 26px;
      left: -9px;

      .cta_container {
        .text {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
