.ken-editorialcontent {
  &__cards {
    display: flex;
    flex-direction: column;
  }

  &__card {
    display: flex;

    &:not(.ken-editorialcontent__card__slide) {
      flex-wrap: wrap;
      flex: 0 0 100%;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
      @include bp('ken-breakpoint-s') {
        margin-bottom: 30px;
      }
    }

    &.--switch-odd {
      &:nth-child(even) {
        .ken-editorialcontent__card__media {
          @include bp('ken-breakpoint-s') {
            order: 2;
          }
        }

        .ken-editorialcontent__card__textgroup-wrapper {
          @include bp('ken-breakpoint-s') {
            order: 1;
          }
        }
      }
    }

    &.--force-right {
      .ken-editorialcontent__card__media {
        @include bp('ken-breakpoint-s') {
          order: 2;
        }
      }

      .ken-editorialcontent__card__textgroup-wrapper {
        @include bp('ken-breakpoint-s') {
          order: 1;
        }
      }
    }

    &__media {
      display: flex;
      flex: 0 0 100%;

      @include bp('ken-breakpoint-s') {
        flex: 0 0 50%;
      }

      &--white-bg {
        @include bp('ken-breakpoint-s') {
          flex: 0 0 48%;
        }
      }

      picture,
      img,
      video {
        width: 100%;
        display: block;
      }

      video {
        object-fit: cover;
        aspect-ratio: 334 / 250;
        @include bp('ken-breakpoint-s') {
          aspect-ratio: 600 / 450;
        }
      }
    }

    &__textgroup-wrapper {
      @extend .ken-bg-color--white;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      padding: 20px 30px;

      @include bp('ken-breakpoint-s') {
        flex: 0 0 50%;
      }

      &--white-bg {
        @include bp('ken-breakpoint-s') {
          flex: 0 0 52%;
        }
      }
    }

    &__textgroup {
      margin: 0 auto;
      text-align: center;

      @include bp('ken-breakpoint-s') {
        text-align: left;
        max-width: 90%;
      }
    }

    &__kicker {
      @include ken-text-h5;
      margin-bottom: 15px;
    }

    &__title {
      @include ken-text-mont-h3;
      margin-bottom: 15px;
    }

    &__content {
      @include ken-text-mont-body-light;
    }

    &__link {
      @include ken-text-link;
      display: block;
      margin-top: 20px;
    }
  }
}
