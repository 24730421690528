.ken-productmoodboard {
  background-color: $ken-color-grey-xs;
  padding: 50px 20px;

  a,
  a:link,
  a:visited {
    text-decoration: none;
  }

  & &__tab {
    border-top: 1px solid $ken-color-grey-m;
    padding-top: 30px;

    @include bp('ken-breakpoint-m') {
      border-top: 0;
      padding-top: 0;
    }
  }

  & &__container {
    // override
    & .swiper-pagination-bullets-dynamic .swiper-pagination-bullets,
    &.swiper-container-horizontal>.swiper-pagination-bullets,
    &.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
      z-index: 0;
      bottom: 0;

      .swiper-pagination-bullet {
        margin: 0 10px;
        border-bottom: 2px solid transparent;
        transform: scale(1);

        &-active {
          font-family: var(--fontNameSemibold);
          font-weight: bold;
          border-bottom: 3px solid $ken-color-rhubarb;
        }
      }

      .swiper-pagination-bullet-active-prev,
      .swiper-pagination-bullet-active-prev-prev,
      .swiper-pagination-bullet-active-next,
      .swiper-pagination-bullet-active-next-next {
        transform: scale(1);
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: space-between;
      width: calc(50% - 10px);
      height: fit-content;
      position: relative;
      margin: 0;
      cursor: grab;
      overflow: visible;

      @include bp('ken-breakpoint-m') {
        margin: 0 auto 60px;
        border-bottom: 1px solid $ken-color-grey-m;
      }

      &-bullet {
        width: auto;
        height: auto;
        opacity: 1;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        background: transparent;
        font-family: var(--fontNameRegular);
        font-size: 18px;
        line-height: 1.22;
        color: $ken-color-black;
        text-transform: uppercase;
        cursor: pointer;
        padding: 0 2px;

        @include bp('ken-breakpoint-m') {
          font-size: 24px;
          line-height: 1.25;
        }
      }
    }
  }
}
