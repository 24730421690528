$ken-color-black: #000000;
$ken-color-white: #ffffff;
$ken-color-none: transparent;

$ken-color-grey-xxxl: #212121;
$ken-color-grey-xxl: #555555;
$ken-color-grey-xxl-alpha: #55555580;
$ken-color-grey-xl: #6b6b6b;
$ken-color-grey-l: #949494;
$ken-color-grey-m: #d8d8d8;
$ken-color-grey-sm: #efefef;
$ken-color-grey-s: #e4e4e4;
$ken-color-grey-xs: #f5f5f5;
$ken-color-grey-xxs: #f2f2f2;

$ken-color-green: #477707;
$ken-color-spinach: #23583f;
$ken-color-rhubarb: #d11b59;
$ken-color-orange: #d44300;
$ken-color-purple: #7c2582;
$ken-color-cabbage: #9d629c;
$ken-color-cheddar: #ffcc33;
$ken-color-pumpkin: #d0810b;
$ken-color-orangestone: #DB7829;
$ken-color-lemon: #b6bd00;
$ken-color-cinnamon: #946f49;
$ken-color-deep-blue: #0B1423;

$ken-color-rhubarb-50: #d11b5980;
$ken-color-rhubarb-08: rgba(209, 27, 89, 0.08);

$ken-color-success: #00c980;
$ken-color-error: #ea2020;
$ken-color-warning: #ff9700;

$ken-color-stock: #099700;

$ken-color-grey-xs-mask-40: rgba($ken-color-grey-xs, 0.4);
$ken-color-grey-xs-mask-50: rgba($ken-color-grey-xs, 0.5);
$ken-color-grey-xs-mask-80: rgba($ken-color-grey-xs, 0.8);
$ken-color-grey-xs-mask-90: rgba($ken-color-grey-xs, 0.9);

$ken-color-white-mask-50: rgba($ken-color-white, 0.5);
$ken-color-white-mask-70: rgba($ken-color-white, 0.7);
$ken-color-white-mask-90: rgba($ken-color-white, 0.9);

$ken-color-black-mask-03: rgba($ken-color-black, 0.03);
$ken-color-black-mask-40: rgba($ken-color-black, 0.4);
$ken-color-black-mask-50: rgba($ken-color-black, 0.5);
$ken-color-black-mask-80: rgba($ken-color-black, 0.8);
$ken-color-black-mask-90: rgba($ken-color-black, 0.9);

$ken-color-blue-mask: rgba($ken-color-deep-blue, 0.5);

$ken-bg-color-list: (
  none: $ken-color-none,
  black: $ken-color-black,
  white: $ken-color-white,
  grey-55: $ken-color-grey-xxl,
  grey-6b: $ken-color-grey-xl,
  grey-94: $ken-color-grey-l,
  grey-d8: $ken-color-grey-m,
  grey-e4: $ken-color-grey-s,
  grey-f5: $ken-color-grey-xs,
  green: $ken-color-green,
  spinach: $ken-color-spinach,
  rhubarb: $ken-color-rhubarb,
  orange: $ken-color-orange,
  purple: $ken-color-purple,
  cabbage: $ken-color-cabbage,
  cheddar: $ken-color-cheddar,
  lemon: $ken-color-lemon,
  cinnamon: $ken-color-cinnamon
);

$ken-text-color-list: (
  black: $ken-color-black,
  white: $ken-color-white,
  grey: $ken-color-grey-xxl,
);

@each $key, $val in $ken-bg-color-list {
  .ken-bg-color--#{$key} {
    background-color: #{$val} !important;
  }
}

@each $key, $val in $ken-text-color-list {
  .ken-text-color--#{$key} {
    color: #{$val} !important;
  }
}
