.ken-compare {
  &__popup__content__results {
    .ken-simple-css-accordion {
      margin-bottom: 25px;
    }

    .ken-simple-css-accordion__title {
      background-color: $ken-color-grey-xs;
      padding: 10px 20px;
      cursor: pointer;

      @include bp('ken-breakpoint-m') {
        background-color: $ken-color-white;
        padding: 10px 5px;
      }

      @include bp('ken-breakpoint-l') {
        padding: 10px 0;
      }

      h4 {
        font-family: var(--fontNameBold);
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $ken-color-black;

        @include bp('ken-breakpoint-m') {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
        }
      }

      i {
        margin-left: 10px;
      }
    }

    .ken-simple-css-accordion__content-desktop {

      .--inner-content {
        background-color: $ken-color-white;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        border-bottom: 1px solid $ken-color-grey-m;

        @include bp('ken-breakpoint-m') {
          padding: 0;
        }

        &:first-child {
          border-top: 1px solid $ken-color-grey-m;
        }

        .--different-only & {
          display: none;
        }

        &.--different {
          display: flex!important;
          background-color: $ken-color-grey-xs;
          .--data {
            span {
              color: $ken-color-error;
              font-family: var(--fontNameSemibold);
            }
          }
        }

        .--label {
          width: 100%;
          text-align: center;
          padding: 7px 0;
          order: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 auto;

          @include bp('ken-breakpoint-m') {
            width: 25%;
            text-align: left;
            padding: 10px;
            justify-content: flex-start;
            border-right: 1px solid $ken-color-grey-m;
          }

          span {
            font-family: var(--fontNameSemibold);
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            letter-spacing: normal;
            color: $ken-color-black;
          }
        }

        .--data {
          width: 33%;
          margin: 10px 0;
          order: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 5px;
          border: 1px solid transparent;
          border-right-color: $ken-color-grey-m;

          @include bp('ken-breakpoint-m') {
            width: 25%;
            justify-content: flex-start;
            margin: 0;
            padding: 16px 10px 16px 20px;
          }

          &:not(.--fill) {
            @include bp('ken-breakpoint-m') {
              display: none;
            }
          }

          &:nth-child(4) {
            border-right-color: transparent;
          }

          span {
            font-family: var(--fontNameLight);
            font-size: 13px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            letter-spacing: normal;
            text-align: center;
            color: $ken-color-black;
            display: inline-block;
            word-break: break-word;

            i {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}