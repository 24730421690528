.ken-homepagerecipes {
  &__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    width: 100%;
    margin-bottom: 20px;
    overflow-x: hidden;

    @include bp('ken-breakpoint-m') {
      order: 2;
      width: 427px;
      margin-bottom: 0;
    }

    &__image {
      height: 355px;
      width: auto;
    }

    &__text-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 355px;
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 324px;
    }

    &__title {
      width: 100%;
      font-family: var(--fontNameExtra);
      font-size: 28px;
      line-height: 1.21;
      text-align: center;
      color: $ken-color-white;

      @include bp('ken-breakpoint-m') {
        font-size: 32px;
        line-height: 1.25;
      }
    }

    &__subtitle {
      margin: 15px 0 20px;
      width: 100%;
      font-family: var(--fontNameRegular);
      font-size: 14px;
      font-weight: 300;
      line-height: 1.43;
      text-align: center;
      color: $ken-color-white;

      @include bp('ken-breakpoint-m') {
        margin: 20px 0;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      min-width: 163px;
      padding: 11px 20px;
      background-color: $ken-color-rhubarb;
      font-family: var(--fontNameSemibold);
      font-size: 12px;
      font-weight: 600;
      line-height: 1.92;
      letter-spacing: 0.5px;
      text-align: center;
      color: $ken-color-white;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
