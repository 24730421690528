.ken-plp-text-style {
  font-family: var(--fontNameLight);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: $ken-color-black;
}

.ken-plp-description {
  background: $ken-color-grey-xs;

  &__text {
    margin: 0 auto;
    padding: 50px 20px;
    @extend .ken-plp-text-style;
    * {
      @extend .ken-plp-text-style;
    }

    @include bp('ken-breakpoint-s') {
      padding: 50px 0;
      max-width: 590px;
    }
  }
}