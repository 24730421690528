.ken-editorialcontent {
  padding: 0 20px;

  @include bp('ken-breakpoint-s') {
    margin: 0;
  }

  .ken-editorialcontent {
    &__top-element {
      margin-bottom: 20px;
      @include bp('ken-breakpoint-s') {
        margin-bottom: 30px;
      }
  
      &__image {
        width:100%;
      }
    }

    &__bottom-element {
      margin-top: 20px;
      @include bp('ken-breakpoint-s') {
        margin-top: 30px;
      }
  
      &__image {
        width:100%;
      }
    }
  }
}