@mixin ken-text-h1 {
  font-family: var(--fontNameExtra);
  font-size: 32px;
  line-height: 40px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin ken-text-h2 {
  font-family: var(--fontNameExtra);
  font-size: 28px;
  line-height: 34px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin ken-text-h3 {
  font-family: var(--fontNameExtra);
  font-size: 24px;
  line-height: 32px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 28px;
    line-height: 36px;
  }
}

@mixin ken-text-mont-bold-h3 {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 28px;
    line-height: 36px;
  }
}

@mixin ken-text-mont-h3 {
  font-family: var(--fontNameSemibold);
  font-size: 24px;
  line-height: 34px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 28px;
    line-height: 40px;
  }
}

@mixin ken-text-h4 {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 24px;
    line-height: 30px;
  }
}

@mixin ken-text-h5 {
  font-family: var(--fontNameSemibold);
  font-size: 18px;
  line-height: 22px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin ken-text-h6($boldMobile: true) {
  @if $boldMobile == true {
    font-family: var(--fontNameBold);
    font-weight: bold;
  } @else {
    font-family: var(--fontNameSemibold);
  }
  font-size: 16px;
  line-height: 22px;
  color: $ken-color-black;
  @include bp('ken-breakpoint-s') {
    font-family: var(--fontNameSemibold);
  }
}

@mixin ken-text-body-light {
  font-family: var(--fontNameExtra);
  font-size: 14px;
  line-height: 20px;
  color: $ken-color-black;
}

@mixin ken-text-body-mont {
  font-family: var(--fontNameRegular);
  font-size: 14px;
  line-height: 1.43;
  color: $ken-color-black;
}

@mixin ken-text-mont-body-light {
  font-family: var(--fontNameLight);
  font-size: 14px;
  line-height: 20px;
  color: $ken-color-black;
}

@mixin ken-text-body-light-alt {
  @include ken-text-body-light;
  color: $ken-color-grey-xl;
}

@mixin ken-text-mont-article-light {
  font-family: var(--fontNameLight);
  font-size: 16px;
  line-height: 24px;
  color: $ken-color-black;
}

@mixin ken-text-small-light {
  font-family: var(--fontNameExtra);
  font-size: 12px;
  line-height: 16px;
  color: $ken-color-grey-xxl;
}

@mixin ken-text-link {
  font-family: var(--fontNameExtra);
  font-size: 14px;
  line-height: 20px;
  color: $ken-color-black;
  text-decoration: underline;
}

@mixin ken-text-mont {
  font-family: var(--fontNameRegular);
  font-size: 12px;
  line-height: 20px;
  color: $ken-color-black;
}

/* Fonts present on PDP */

@mixin ken-pdp-text-small {
  font-family: var(--fontNameLight);
  font-size: 12px;
  color: $ken-color-black;
}

@mixin ken-pdp-title {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 30px;
  line-height: 1.13;
  color: $ken-color-black;
}

@mixin ken-pdp-badge {
  font-family: var(--fontNameBold);
  font-size: 11px;
  color: $ken-color-white;
  text-transform: uppercase;
  line-height: 1;
}

@mixin ken-pdp-description-title {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 14px;
  color: $ken-color-black;
}

@mixin ken-pdp-description-content {
  font-family: var(--fontNameLight);
  font-size: 14px;
  color: $ken-color-black;
}

@mixin ken-pdp-price-current {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 20px;
  color: $ken-color-black;
}

@mixin ken-pdp-price-where-to-buy {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 12px;
  color: $ken-color-black;
}

@mixin ken-pdp-price-strike {
  font-family: var(--fontNameLight);
  font-size: 14px;
  text-decoration: line-through;
}

@mixin ken-pdp-awards-link {
  font-family: var(--fontNameMedium);
  font-size: 14px;
  color: $ken-color-black;
}

@mixin ken-pdp-components-title {
  font-family: var(--fontNameExtra);
  font-size: 32px;
  line-height: 1.21;
  color: $ken-color-black;
}

@mixin ken-pdp-average-number {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 50px;
  line-height: 0.8;
  color: $ken-color-black;
}

@mixin ken-pdp-reviewer {
  font-family: var(--fontNameSemibold);
  font-size: 13px;
  line-height: 1.23;
  color: $ken-color-black;
}

@mixin ken-pdp-faq-accordion-title {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 16px;
  color: $ken-color-black;
}

@mixin ken-pdp-sticky-menu-product-title {
  font-family: var(--fontNameSemibold);
  color: $ken-color-black;
  font-size: 14px;

  @include bp('ken-breakpoint-m') {
    font-family: var(--fontNameBold);
    font-weight: bold;
    font-size: 26px;
  }
}

@mixin ken-pdp-sticky-menu-product-code {
  font-family: var(--fontNameRegular);
  font-size: 16px;
  color: $ken-color-grey-xxl;
}

@mixin ken-pdp-sticky-menu-sections {
  font-family: var(--fontNameRegular);
  font-size: 12px;
  color: $ken-color-black;

  @include bp('ken-breakpoint-m') {
    text-transform: uppercase;
  }
}

@mixin ken-pdp-compare-key {
  font-family: var(--fontNameSemibold);
  font-size: 13px;
  color: $ken-color-black;
}

@mixin ken-pdp-compare-value {
  font-family: var(--fontNameLight);
  font-size: 13px;
  color: $ken-color-black;
}

@mixin ken-pdp-compare-code {
  font-family: var(--fontNameRegular);
  font-size: 12px;
  color: $ken-color-grey-xxl;
  text-decoration: none;
}

@mixin ken-pdp-compare-price {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 12px;
  color: $ken-color-black;
}

@mixin ken-pdp-compare-strike {
  font-family: var(--fontNameLight);
  font-size: 12px;
  color: $ken-color-grey-xxl;
  text-decoration: line-through;
}

@mixin ken-pdp-promo-kicker {
  font-family: var(--fontNameLight);
  font-size: 14px;
  color: $ken-color-black;
  text-transform: uppercase;
}

@mixin ken-pdp-promo-title {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 16px;
  color: $ken-color-black;

  @include bp('ken-breakpoint-s') {
    font-size: 24px;
  }
}

@mixin ken-pdp-promo-link {
  font-family: var(--fontNameMedium);
  font-size: 14px;
  color: $ken-color-black;
}

@mixin ken-pdp-recipes-title {
  font-family: var(--fontNameBold);
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: $ken-color-black;
}

.ken-text-h1 {
  @include ken-text-h1;
}

.ken-text-h2 {
  @include ken-text-h2;
}

.ken-text-h3 {
  @include ken-text-h3;
}

.ken-text-h4 {
  @include ken-text-h4;
}

.ken-text-h5 {
  @include ken-text-h5;
}

.ken-text-h6 {
  @include ken-text-h6;
}

.ken-text-body-mont {
  @include ken-text-body-mont;
}

.ken-text-body-light {
  @include ken-text-body-light;
}

.ken-text-body-light-alt {
  @include ken-text-body-light-alt;
}

.ken-text-small-light {
  @include ken-text-small-light;
}

.ken-text-link {
  @include ken-text-link;
}

.ken-messages--warning {
  display: flex;
  justify-content: center;
  background-color: inherit;
  color: #d11b59;
  font-weight: 700;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function REM($value) {
  $stripValue: strip-unit($value);
  $remValue: ($stripValue / 16) + rem;
  @return $remValue;
}
