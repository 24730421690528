.ken-footer {

  .ken-bottom-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    &__entry, &__entry a, &__slash {
      font-family: var(--fontNameRegular);
      font-size: 12px;
      line-height: 1.5;
      color: $ken-color-grey-xxl !important;
    }

    &__slash {
      margin: 0 10px;
    }

    span.ken-bottom-links__slash:nth-of-type(4) {
      display: none;

      @include bp('ken-breakpoint-s') {
        display: inline-block;
      }
    }
  }
}
