.ken-footer {

  .ken-newsletter {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $ken-color-grey-m;
    padding: 20px 25px;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include bp('ken-breakpoint-s') {
        width: 630px;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__slogan {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      @include bp('ken-breakpoint-s') {
        align-items: flex-start;
        margin-bottom: 0;
      }
    }

    &__textbold {
      @include ken-text-h4;
      line-height: 1.2;
      text-align: center;

      @include bp('ken-breakpoint-s') {
        text-align: left;
      }
    }

    &__text {
      font-family: var(--fontNameRegular);
      font-weight: 300;
      font-size: 12px;
      line-height: 1.33;
      text-align: center;

      @include bp('ken-breakpoint-s') {
        text-align: left;
      }
    }

    &__button {
      width: 196px;
      height: 40px;
      border: 1px solid $ken-color-rhubarb;
      border-radius: 0;
      background: none;
      font-family: var(--fontNameSemibold);
      font-size: 12px;
      font-weight: 600;
      line-height: 1.67;
      letter-spacing: 0.2px;
      color: $ken-color-rhubarb;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
