.ken-footer {

  .ken-payment {
    &-links {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @include bp('ken-breakpoint-s') {
        justify-content: flex-end;
        flex-grow: 1;
        flex-wrap: nowrap;
      }
    }

    &-entry {
      display: flex;
      align-items: center;
      margin: 0 15px 10px;

      @include bp('ken-breakpoint-s') {
        margin: 0 0 10px 30px;
      }
    }
  }
}

[dir="rtl"] {
  .ken-footer {

    .ken-payment {
      &-entry {
        display: flex;
        align-items: center;
        margin: 0 15px 10px;

        @include bp('ken-breakpoint-s') {
          margin: 0 30px 10px 0;
        }
      }
    }
  }
}
