$iconsPathCore: '#{$pathAssets}/images/kenwood/icons/Core';
$inputSize: 14px;
$iconSize: 14px;

.ken-input-checkbox {
  appearance: none;
  position:relative;
  height: $inputSize;
  width: $inputSize;
  border: 1px solid $ken-color-black;
  cursor: pointer;
  transition: all 0.15s ease 0s;

  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    background-image: url("#{$iconsPathCore}/Check.svg");
    background-size: $iconSize $iconSize;
    background-position: center center;
    background-repeat: no-repeat;
    width: $iconSize;
    height: $iconSize;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked {
    border-color: $ken-color-rhubarb;
    &::after {
      opacity: 1;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
}