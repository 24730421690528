.dln-kenwood {
  .dln-paperless-header {
    max-width: 1440px;
    margin: 0 auto;
    background-color: $ken-color-rhubarb;
    padding: 30px 10px;
    text-align: center;

    @include bp('ken-breakpoint-m') {
      padding: 70px 0;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 24px;
      font-family: var(--fontNameSemibold);
      color: $ken-color-white;

      @include bp('ken-breakpoint-m') {
        font-size: 40px;
      }
    }

    &__subtitle {
      font-size: 16px;
      max-width: 330px;
      margin: 0 auto;
      color: $ken-color-white;

      @include bp('ken-breakpoint-m') {
        font-size: 20px;
        max-width: none;
      }
    }
  }
}