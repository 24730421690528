.ken-header {
  &__logo {
    width: 150px;

    @include bp('ken-breakpoint-l') {
      width: 180px;
    }

    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
