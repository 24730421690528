.ken-header {
  &__navdrawer {
    width: 100%;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-l') {
      background: linear-gradient(
        90deg,
        $ken-color-white 50%,
        $ken-color-grey-xs 50%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          $ken-color-grey-xs 50%,
          $ken-color-white 50%
        );
      }
    }

    &--mobile {
      transform: translateX(0);
      height: 100%;
      transition: transform 0.3s;
      overflow-y: auto;
      position: absolute;

      &.--shrunk {
        transform: translateX(100%);
      }
    }

    &--desktop {
      position: absolute;
      left: 0;
      top: 99px;
      display: none;
      align-items: stretch;
      height: 0;
      transition: height 0.3s ease;
      overflow: hidden;

      @include bp('ken-breakpoint-l') {
        display: flex;
        height: 0;
        top: 87px;
      }

      li:hover > & img {
        display: block;
      }

      li:hover > & {
        transition-property: height;
        transition-delay: 200ms;
        height: 665px; 
        display: flex;
      }
    }

    &__mobile-container {
      width: 100%;
      height: calc(100vh - 108px);
      display: flex;
      transition: height 0.3s;
      position: relative;

      @include bp('ken-breakpoint-l') {
        display: none;
      }

      &.--no-stripe,
      &.--stripe-hidden {
        height: calc(100vh - 69px);
      }

      &.--shrunk {
        height: 0;
      }
    }

    &__spacer {
      position: absolute;
      left: 0;
      display: none;
      height: 40px;
      width: 100%;

      &.--vertical {
        top: 0;
        left: 250px;
        height: 100%;
        width: 60px;

        @include bp('ken-breakpoint-l') {
          left: 376px;
        }

        [dir='rtl'] & {
          left: unset;
          right: 250px;

          @include bp('ken-breakpoint-l') {
            right: 376px;
          }
        }
      }

      li:hover > & {
        display: block;
      }
    }

    & &__wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }

    & &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      background-color: $ken-color-grey-xs;

      span {
        font-family: var(--fontNameBold);
        font-size: 14px;
        font-weight: bold;
        line-height: 2.14;
        letter-spacing: 0.2px;
        color: $ken-color-black;
        text-transform: uppercase;
      }
    }

    & &__viewalllink {
      width: 100%;
      height: 59px;
      padding: 0 20px;

      @include bp('ken-breakpoint-l') {
        height: unset;
        padding: 0;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid $ken-color-grey-s;
        font-family: var(--fontNameBold);
        font-size: 14px;
        font-weight: 600;
        line-height: 2.14;
        letter-spacing: 0.2px;
        color: $ken-color-black;

        @include bp('ken-breakpoint-l') {
          border-bottom: none;
          border-top: 1px solid $ken-color-grey-s;
          padding-top: 20px;
        }
      }
    }

    &__nav-list {
      width: 100%;
      padding: 0 20px;
      border-top: 2px solid $ken-color-grey-s;

      @include bp('ken-breakpoint-l') {
        padding: 0;
        border-top: none;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-of-type {
        padding-bottom: 120px;

        @include bp('ken-breakpoint-s') {
          padding-bottom: 0;
        }
      }

      &.--main-menu {
        padding-top: 28px;
      }

      &--list-separator {
        width: 30px;
        margin: 0;
        border: none;
        border-top: 1px solid $ken-color-grey-m;
      }

      li {
        width: 100%;
        height: 59px;
        border-bottom: 1px solid $ken-color-grey-s;

        @include bp('ken-breakpoint-l') {
          height: 32px;
          border-bottom: none;
          margin: 0;
          padding-bottom: 16px;

          &.--with-separator {
            height: 62px;
            padding-top: 10px;
            padding-bottom: 0;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        span {
          font-family: var(--fontNameRegular);
          font-size: 14px;
          line-height: 2.14;
          letter-spacing: 0.2px;
          color: $ken-color-black;

          @include bp('ken-breakpoint-l') {
            border-bottom: 2px solid transparent;
            line-height: 1.43;
          }
        }

        .ken-icon-wrapper {
          .ken-icon {
            position: relative;
            top: 0;
            left: 0;
            transition: left 0.3s ease-out;
          }
        }

        li:not([data-id]):hover > &,
        li.--active > & {
          span:first-child {
            @include bp('ken-breakpoint-l') {
              border-bottom: 2px solid $ken-color-black;
            }
          }

          .ken-icon-wrapper {
            .ken-icon {
              @include bp('ken-breakpoint-l') {
                left: 15px;
              }

              [dir='rtl'] & {
                @include bp('ken-breakpoint-l') {
                  left: -15px;
                }
              }
            }
          }
        }

        &.--main-menu span {
          font-family: var(--fontNameBold);
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    & &__iconlinks {
      width: 100%;
      padding: 15px 20px;
      border-top: 2px solid $ken-color-grey-s;

      li {
        width: 100%;
        padding: 15px 0;

        &.ken-header__navdrawer__iconlinks__logout {
          margin-top: 15px;
          padding-top: 36px;
          border-top: 1px solid $ken-color-grey-s;
        }

        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
        }

        i,
        img {
          $size: 24px;
          width: $size;
          height: $size;
          font-size: 11.5px;
        }

        span {
          margin: 0 15px;
          font-family: var(--fontNameRegular);
          font-size: 14px;
          letter-spacing: 0.2px;
          color: $ken-color-black;
        }
      }
    }

    &__aside,
    &__content {
      padding: 54px 20px 148px;

      @include tp('ken-tweakpoint-l') {
        padding: 54px 0 148px;
      }
    }

    &__aside {
      flex-grow: 0;
      width: 310px;
      padding-right: 60px;

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: 60px;
      }

      @include bp('ken-breakpoint-l') {
        width: 436px;
      }
    }

    &__content {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: calc(100% - 310px);
      min-width: 652px;
      height: 100%;
      padding-left: 60px;
      background-color: $ken-color-grey-xs;

      @include bp('ken-breakpoint-l') {
        width: calc(100% - 436px);
      }

      [dir='rtl'] & {
        right: unset;
        left: 0;
        padding-left: 0;
        padding-right: 60px;
      }

      &.--level1 {
        display: flex;
      }

      &.--level2 {
        opacity: 0;
        transition: opacity 0.3s;
      }

      &.--visible {
        z-index: $ken-header-z-index + 1;
        opacity: 1;
      }
    }

    &__level-wrapper {
      display: flex;
      justify-content: space-between;
      width: 597px;

      &.--fullWidth {
        width: 100%;
      }
    }

    &__media {
      $mediaSize: 286px;
      width: $mediaSize;

      @include bp('ken-breakpoint-l') {
        &--double {
          & .ken-header__navdrawer__media {
            &__image-wrapper,
            &__image-wrapper img,
            &__image-hover {
              width: calc(#{$mediaSize} * 2 + 19px);
            }
          }

          figcaption {
            text-align: center;
            text-decoration: underline;

            a {
              @include ken-text-body-mont;
              font-family: var(--fontNameSemibold);
              margin-top: 10px;
              display: block;
            }
          }
        }
      }
      &__image-wrapper {
        width: $mediaSize;
        height: $mediaSize;

        img {
          position: absolute;
          width: $mediaSize;
          height: $mediaSize;
          display: none;
        }

        &:hover {
          .ken-header__navdrawer__media__image-hover {
            opacity: 1;
          }
        }
      }

      &__image-hover {
        position: absolute;
        display: flex;
        justify-content: stretch;
        align-items: center;
        width: $mediaSize;
        height: $mediaSize;
        background-color: rgba($ken-color-black, 0.55);
        opacity: 0;
        transition: opacity 0.3s ease;

        span {
          padding: 20px;
          font-family: var(--fontNameBold);
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
          text-align: center;
          color: $ken-color-white;
          margin: 0 auto;
        }
      }

      figcaption {
        margin-top: 10px;

        &,
        & a {
          font-family: var(--fontNameSemibold);
          font-size: 14px;
          font-weight: 500;
          line-height: 1.43;
          color: $ken-color-black;
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    & &__recipe {
      width: 286px;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &__image-wrapper {
        display: flex;
        justify-content: center;
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;

        img {
          width: auto;
          height: 263px;
        }
      }

      &__textgroup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 2;
        flex-shrink: 0;
        width: 100%;
        padding-top: 4px;
      }

      &__title {
        padding: 10px 0;
        font-family: var(--fontNameBold);
        font-size: 14px;
        font-weight: bold;
        line-height: 1.21;
        color: $ken-color-black;
      }

      &__statsgroup {
        display: flex;
      }

      &__stat {
        display: flex;
        align-items: center;
        margin-right: 24px;

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 24px;
        }

        .dln-icn {
          font-size: 24px;
          color: $ken-color-grey-l;
        }

        &-desc {
          margin: 0 7px;
          font-family: var(--fontNameRegular);
          font-size: 12px;
          font-weight: 300;
          line-height: 1.33;
          color: $ken-color-grey-xxl;
        }
      }
    }

    & &__level2-links {
      width: 286px;
    }

    .ken-icon {
      width: 13px;
      height: 13px;

      [dir='rtl'] & {
        transform: rotate(180deg);

        &--account,
        &--wishlist {
          transform: rotate(0deg);
        }
      }
    }
  }
}
