.ken-attachmentsslider {
    @include ken-swiper-arrows;
    @include ken-swiper-scrollbar;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .swiper-container {
            width: 100%;
            max-width: 100%;
            min-width: 0;

            .swiper-wrapper {
                .swiper-slide {
                    width: auto;
                }
            }

            .swiper-footer {
                @include ken-swiper-scrollbar;
                flex-direction: row-reverse;
                gap: 36px;
                width: auto;
                margin: 30px 20px 0;

                @include bp('ken-breakpoint-s') {
                    margin: 30px 116px 0;
                    max-width: 84vw;
                }

                .ken-attachmentsslider__navigation {
                    display: none;

                    @include bp('ken-breakpoint-s') {
                        display: flex;
                        gap: 18px;
                    }
                }
            }
        }
    }

    &__textgroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
        width: 100%;
        max-width: 100%;
        padding: 0 20px;

        @include bp('ken-breakpoint-s') {
            margin-bottom: 10px;
            width: 593px;
            padding: 0;
        }

        h2 {
            font-family: var(--fontNameSemibold);
            color: $ken-color-black;
            font-size: 24px;

            @include bp('ken-breakpoint-s') {
                font-size: 32px;
            }
        }

        p {
            font-size: 14px;

            @include bp('ken-breakpoint-s') {
                font-size: 16px;
            }
        }
    }

    &__attachment {
        min-width: fit-content;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: fit-content;
            font-family: var(--fontNameMedium);
            color: $ken-color-black;
            text-decoration: underline;
            font-size: 14px;

            @include bp('ken-breakpoint-s') {
                font-size: 16px;
            }

            img {
                width: 200px;
                height: 200px;

                @include bp('ken-breakpoint-s') {
                    width: 286px;
                    height: 286px;
                }
            }
        }
    }

    &__cta a {
        @extend .ken-button--primary;
        text-transform: uppercase;
    }
}