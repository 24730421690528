@mixin ken-swiper-arrows {
  .ken-icon-activewrap {
    padding: 4px 3px 3px 4px;
    border-radius: 5px;
    background-color: $ken-color-none;
  
    &:hover,
    &:active {
      background-color: $ken-color-grey-xs;
    }
  }
  
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  
    &::after {
      display: none !important;
      text-indent: -99999cm;
    }
  
    &.swiper-button-disabled {
      cursor: not-allowed;
    }
  
    @include bp('ken-breakpoint-s') {
      display: flex !important;
      margin: 8px;
    }
  
    &:not(.ken-icon-activewrap),
    &.ken-icon-activewrap::after {
      @extend .ken-icon;
      height: 17px;
      width: 17px;
    }
  }
  
  .swiper-button-prev {
    order: 2;
  }
  .swiper-button-next {
    order: 3;
  }
  
  .swiper-button-prev::after,
  .swiper-button-prev:not(.ken-icon-activewrap) {
    @extend .ken-icon--arrow-left-rhubarb;
  
    &.swiper-button-disabled:not(.ken-icon-activewrap),
    &.swiper-button-disabled.ken-icon-activewrap::after {
      @extend .ken-icon--arrow-left-grey;
    }
  }
  
  .swiper-button-next::after,
  .swiper-button-next:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-right-rhubarb;
  
    &.swiper-button-disabled:not(.ken-icon-activewrap),
    &.swiper-button-disabled.ken-icon-activewrap::after {
      @extend .ken-icon--arrow-right-grey;
    }
  }
}

@mixin ken-swiper-scrollbar {
  .swiper-scrollbar {
    width: 100%;
    height: 3px;
    background: $ken-color-grey-s;

    margin: 0;
    padding-top: 0;

    &-drag {
      background-color: $ken-color-rhubarb;
    }
  }
}