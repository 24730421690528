.ken-header {
  .ken-btnIcon {
    $size: 24px;
    width: $size;
    height: $size;
    margin-left: 25px;

    &:first-child {
      margin-left: 9px;
    }

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 25px;
    }

    &--wishlist,
    &--account {
      display: none;

      @include bp('ken-breakpoint-l') {
        display: block;
      }

      .ken-drawer-padding {
        display: none;
      }
    }

    &--account.hover,
    &--account:hover {
      .ken-drawer-padding {
        position: relative;
        display: block;
        width: 100%;
        height: 6px;
      }

      .dln-drawer--account {
        display: flex;
      }
    }

    &--hamburger {
      @include bp('ken-breakpoint-l') {
        display: none;
      }
    }

    a,
    i {
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 11.5px;
      cursor: pointer;

      &.dln-btn {
        height: auto;
      }

      &.ken-btnIcon__checkoutCounterText {
        display: inline-block;
        width: auto;
        height: 10px;
        margin: 0 auto;
        padding: 0;
        font-family: var(--fontNameBold);
        font-size: 12px;
        font-weight: 700;
        line-height: 100%;
        text-align: center;
        color: $ken-color-white;
      }
    }

    &__wrap {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__checkoutCounter {
      position: absolute;
      top: -4px;
      right: -10px;
      display: flex;
      align-items: center;
      align-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $ken-color-rhubarb;
    }
  }

  .ken-button {
    &__wishlist,
    &__cart {
      width: 100%;
      height: 100%;
      padding: 0;

      & > .ken-btnIcon__wrap {
        position: relative;
      }
    }
  }
}
