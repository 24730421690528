.ken-header {
  &__top-links {
    display: flex;
    justify-content: space-between;

    li {
      margin-left: 30px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 30px;
      }

      a {
        font-family: var(--fontNameRegular);
        font-size: 12px;
        line-height: 1.5;
        color: $ken-color-grey-xxl;
      }
    }
  }
}
