/* Responsive breakpoints */

$breakpoints: (
  'ken-breakpoint-xs': 360px,
  'ken-breakpoint-s': 768px,
  'ken-breakpoint-m': 1024px,
  'ken-breakpoint-l': 1200px,
  'ken-breakpoint-xl': 1440px,

  'ken-breakpoint-max-xs': 359px,
  'ken-breakpoint-max-s': 767px,
  'ken-breakpoint-max-m': 1023px,
  'ken-breakpoint-max-l': 1199px,
  'ken-breakpoint-max-xl': 1439px
) !default;

@mixin bp($breakpoint, $breakpoint-rule: 'min') {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media ('#{$breakpoint-rule}-width': map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin bp-range($breakpoint-min, $breakpoint-max) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint-min) and  map-has-key($breakpoints, $breakpoint-max) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint-min)) and (max-width: map-get($breakpoints, $breakpoint-max)) {
      @content;
    }
  }
    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint-min}` or `#{$breakpoint-max}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

// These are breakpoints which include 20px added side paddings.
$tweakpoints: (
  'ken-tweakpoint-xs': 400px,
  'ken-tweakpoint-s': 808px,
  'ken-tweakpoint-m': 1064px,
  'ken-tweakpoint-l': 1240px,
  'ken-tweakpoint-xl': 1480px,

  'ken-tweakpoint-max-xs': 399px,
  'ken-tweakpoint-max-s': 807px,
  'ken-tweakpoint-max-m': 1063px,
  'ken-tweakpoint-max-l': 1239px,
  'ken-tweakpoint-max-xl': 1479px
) !default;

@mixin tp($tweakpoint, $tweakpoint-rule: 'min') {
  // If the key exists in the map
  @if map-has-key($tweakpoints, $tweakpoint) {
    // Prints a media query based on the value
    @media ('#{$tweakpoint-rule}-width': map-get($tweakpoints, $tweakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$tweakpoint}`. '
      + 'Available tweakpoints are: #{map-keys($tweakpoints)}.';
  }
}
