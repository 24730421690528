.ken-articleslinked {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  background-color: $ken-color-grey-xs;

  @include bp('ken-breakpoint-s') {
    padding-top: 80px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    @include bp('ken-breakpoint-xl') {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-family: var(--fontNameExtra);
    font-size: 28px;
    color: $ken-color-black;
    line-height: 1.21;
    text-align: center;

    @include bp('ken-breakpoint-s') {
      font-size: 32px;
      line-height: 1.25;
    }
  }

  & &__articles {
    &__container {
      max-width: 100%;

      > .swiper-scrollbar {
        width: 100%;
        height: 3px;
        left: 0;
        bottom: 30px;
        border-radius: 0;
        background-color: $ken-color-grey-s;

        .swiper-scrollbar-drag {
          background-color: $ken-color-rhubarb;
        }
      }
    }

    &__wrapper {
      align-items: stretch;
      padding-bottom: 63px;

      @include bp('ken-breakpoint-s') {
        padding-bottom: 60px;
      }
    }
  }
}
