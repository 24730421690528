.ken-clubcomponent {
    padding: 2rem;
    background-color: $ken-color-none,;
    text-align: center;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        flex-direction: column;

        h3 {
            font-weight: 600;
        }

        &__view-more {
            @extend .ken-button--link;
            padding: 10px 0;
            color: inherit;
            font-family: var(--fontNameBold);
            font-weight: bold;
            text-transform: none;

            @include bp('ken-breakpoint-s') {
                position: absolute;
                right: 0;
            }
        }
    }

    &__stories {
        display: flex;
        justify-content: space-between;
        column-gap: 5%;

        &__story-card {
            overflow: hidden;
            text-align: left;
            transition: transform 0.3s;

            &__story-image {
                width: 100%;
                height: auto;
            }

            &__story-details {
                padding: 1rem;

                h3 {
                    margin-bottom: 15px;
                    font-family: var(--fontNameSemibold);
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.22;
                    color: $ken-color-black;
                }

                p {
                    font-size: 0.9rem;
                    color: $ken-color-black;
                    margin-bottom: 1rem;
                }

                &__read-more {
                    @extend .ken-button--link;
                    padding: 10px 0;
                    color: inherit;
                    font-family: var(--fontNameBold);
                    font-weight: bold;
                    text-transform: none;
                }
            }
        }
    }

    &__cta {
        margin-top: 2rem;

        p {
            font-size: REM(20px);
            color: $ken-color-black;
            margin-bottom: 1rem;
            font-weight: 600;
            line-height: REM(24px);
        }

        &__join-now {

            &:hover {

            }
        }
    }

    &__slider {
        &--hidden-mobile.swiper-footer {
            display: none;
        }

        &--hidden-desktop.swiper-footer {
            @include bp('ken-breakpoint-s') {
                display: none;
            }
        }
    }

    .swiper-container {
        max-width: 100%;
        margin: 0;
        display: flex;
        justify-content: flex-start;

        .swiper-wrapper {
            padding: 0;
            align-items: center;

            .swiper-slide {
                padding: 0;

                @include bp('ken-breakpoint-s') {
                    padding: 0 0 10px;
                }

                .ken-promobanner {
                    padding: 18px 0 38px;

                    @include bp('ken-breakpoint-s') {
                        padding: 0;
                    }
                }
            }

            @include bp('ken-breakpoint-s') {
                padding: 0;
            }
        }
    }

    .swiper-footer {
        max-width: none;
        margin: 0;
        width: 100%;
        padding: 0 20px;

        @include bp('ken-breakpoint-s') {
            height: 40px;
            width: calc(100% - 74px);
            margin: 0;
            padding: 0;
        }

        @include bp('ken-breakpoint-xl') {
            width: 100%;
            margin: 0;
        }

        .swiper-scrollbar {
            width: 100%;
            height: 3px;
            background-color: $ken-color-grey-s;

            margin: 0;
            padding-top: 0;

            @include bp('ken-breakpoint-s') {
                width: calc(100% - 90px);
                margin-right: 20px;
                order: 1;
            }

            &-drag {
                background-color: $ken-color-rhubarb;
            }
        }

        .ken-icon-activewrap {
            padding: 4px 3px 3px 4px;
            border-radius: 5px;
            background-color: $ken-color-none;

            &:hover,
            &:active {
                background-color: $ken-color-grey-xs;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none !important;

            &::after {
                display: none !important;
                text-indent: -99999cm;
            }

            &.swiper-button-disabled {
                cursor: not-allowed;
            }

            @include bp('ken-breakpoint-s') {
                display: flex !important;
                margin: 8px;
            }

            &:not(.ken-icon-activewrap),
            &.ken-icon-activewrap::after {
                @extend .ken-icon;
                height: 17px;
                width: 17px;
            }
        }

        .swiper-button-prev {
            order: 2;
        }
        .swiper-button-next {
            order: 3;
        }

        .swiper-button-prev::after,
        .swiper-button-prev:not(.ken-icon-activewrap) {
            @extend .ken-icon--arrow-left-rhubarb;

            &.swiper-button-disabled:not(.ken-icon-activewrap),
            &.swiper-button-disabled.ken-icon-activewrap::after {
                @extend .ken-icon--arrow-left-grey;
            }
        }

        .swiper-button-next::after,
        .swiper-button-next:not(.ken-icon-activewrap) {
            @extend .ken-icon--arrow-right-rhubarb;

            &.swiper-button-disabled:not(.ken-icon-activewrap),
            &.swiper-button-disabled.ken-icon-activewrap::after {
                @extend .ken-icon--arrow-right-grey;
            }
        }
    }
}
