.ken-paperless-attachments {
  .ken-attachments__title {
    font-size: 28px;
    font-family: var(--fontNameSemibold);
    text-transform: uppercase;

    @include bp('ken-breakpoint-m') {
      font-size: 30px;
    }
  }
}