.ken-configurator__hmc-banner,
.ken-configurator__owner-banner {
  width: 335px;
  height: 455px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include bp('ken-breakpoint-m') {
    width: 250px;
    min-width: 250px;
    height: 430px;
    margin: 0 10px;
  }

  @include bp('ken-breakpoint-l') {
    margin-right: 30px;
  }

  @include bp('ken-breakpoint-xl') {
    margin-right: 55px;
  }

  &-close {
    position: absolute;
    z-index: 1;
    top: 28px;
    right: 28px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &-content {
    padding: 40px;

    @include bp('ken-breakpoint-m') {
      padding: 40px 31px;
    }
  }

  &-title {
    font-size: 18px;
    font-family: var(--fontNameMedium);
    color: $ken-color-white;
    padding-bottom: 16px;
    line-height: 22px;

    @include bp('ken-breakpoint-m') {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-desc {
    font-size: 14px;
    color: $ken-color-white;
    padding-bottom: 30px;
    line-height: 18px;
    font-family: var(--fontNameLight);

    @include bp('ken-breakpoint-m') {
      font-size: 12px;
    }
  }
}

.ken-configurator__hmc-banner {
  overflow: hidden;

  &-background-image {
    width: 100%;
  }

  &-content {
    position: absolute;
    top: 0;
  }

  &-cta button {
    width: 100%;

    @include bp('ken-breakpoint-m') {
      width: auto;
    }
  }

  &-cta-mobile {
    display: flex;
    justify-content: center;
    margin: 0 40px 40px;

    button {
      width: 100%;
    }

    @include bp('ken-breakpoint-m') {
      margin: 0;

      button {
        width: auto;
      }
    }
  }
}

.ken-configurator__owner-banner {
  background-color: $ken-color-rhubarb;

  &-table {
    margin-top: 15px;

    table {
      border-collapse: collapse;
    }

    tr {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: $ken-color-rhubarb 1px solid;
      background-color: $ken-color-white;
      padding: 8px 12px;

      @include bp('ken-breakpoint-m') {
        padding: 8px;
      }

      &:last-child {
        border-bottom: none;
      }

      td {
        font-size: 12px;
        color: $ken-color-black;
        font-family: var(--fontNameLight);
  
        strong {
          font-size: 12px;
          color: $ken-color-black;
          font-family: var(--fontNameMedium);
          font-weight: inherit;
          padding-right: 5px;
        }
      }
    }
  }

  &-cta-mobile {
    display: flex;
    justify-content: center;
    padding: 0 40px;
    width: 100%;

    button {
      width: 100%;
    }

    @include bp('ken-breakpoint-m') {
      padding: 0;
      width: auto;

      button {
        width: auto;
      }
    }
  }
}

.ken-configurator__promo-banner {
  max-width: 389px;
  background: linear-gradient(259.82deg, #E4AB33 -0.51%, #DB7829 99.7%);
  overflow: hidden;

  &-content {
    position: relative;
    padding: 24px;

    &::after {
      content: "";
      background: linear-gradient(224.13deg, rgba(255, 255, 255, 0.25) -0.19%, rgba(255, 255, 255, 0) 98.71%);
      position: absolute;
      width: 300px;
      height: 300px;
      right: -150px;
      bottom: -150px;
      z-index: 0;
      rotate: 45deg;
    }

    @include bp('ken-breakpoint-m') {
      padding: 40px;
    }
  }

  &-pretitle {
    font-size: 10px;
    font-family: var(--fontNameLight);
    text-transform: uppercase;
    color: $ken-color-white;
    padding-bottom: 8px;
    line-height: 12px;
  }

  &-title {
    font-size: 18px;
    font-family: var(--fontNameBold);
    text-transform: uppercase;
    color: $ken-color-white;
    padding-bottom: 18px;
    line-height: 22px;
  }

  &-desc {
    font-size: 14px;
    color: $ken-color-white;
    padding-bottom: 16px;
    line-height: 18px;
  }

  &-cta {
    border: none;
    background: none;
    color: $ken-color-white;
    font-family: var(--fontNameMedium);
    line-height: 15px;
    text-decoration: underline;
    cursor: pointer;
  }
}
