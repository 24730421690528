.ken-pdp__where-to-buy {
  .ken-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px 25px 0 0;
    border: 0;
    background: transparent;
    cursor: pointer;

    .ken-icon {
      width: 33px;
      height: 33px;
    }
  }

  .ken-hatch {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  
    &__title {
      @include ken-text-h3;
      text-align: center;
      flex-basis: 100%;
      margin-bottom: 38px;
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 50%;
      margin-bottom: 60px;
      border-left: 1px solid $ken-color-grey-m;
  
      @include bp('ken-breakpoint-s') {
        flex-basis: 33.3%;
        margin-bottom: 0;
      }
  
      &:nth-child(3n+2) {
        border-left:0;
      }

      &:nth-child(n+5) {
        @include bp('ken-breakpoint-s') {
          margin-top: 60px;
        }
      }
  
      &__logo {
        min-height: 120px;
      }
  
      &__cta {
        text-transform: uppercase;
        margin-top: 22px;
      }
  
      &__price {
        @include ken-pdp-price-where-to-buy;
      }
    }
  }
}


