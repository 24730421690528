.ken-footer {

  .ken-country {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 40px;
    border: none;
    background: none;

    @include bp('ken-breakpoint-m') {
      margin: 0;
    }

    &__code {
      $size: 15px;
      width: $size;
      height: $size;
    }

    &__name {
      margin: 0 5px;
      font-family: var(--fontNameRegular);
      font-size: 12px;
      line-height: 1.5;
      color: $ken-color-grey-xxl;
    }

    &__arrow {
      @extend .ken-icon;
      @extend .ken-icon--arrow-down-grey-xxl;
      $size: 8px;
      width: $size;
      height: $size;

      &:hover {
        @extend .ken-icon--arrow-down-grey;
      }
    }
  }
}
