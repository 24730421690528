.ken-plp__filters {
  &__mobile-popup__content {
    overflow-y: auto;
    background-color: $ken-color-white;
    flex: 1;
    margin-bottom: 80px;


    .ken-simple-css-accordion {

      &__wrapper {
        border-bottom: 1px solid $ken-color-grey-s;
      }

      &__icon {
        &:after {
          width: 13px;
          height: 13px;
          background-image: url("#{$iconsPathSmall}/down-black.svg");
          background-color: transparent;
          left: 0;
        }

        &:before {
          content: unset;
        }
      }
      input[type='checkbox'] {
        &:checked + .ken-simple-css-accordion__wrapper {
          .ken-simple-css-accordion__icon {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__title {
        background-color: $ken-color-white;
        border-left: 3px solid transparent;
        padding: 10px 20px 10px 17px;
        min-height: 60px;

        &.--has-selected-filters {
          border-left-color: $ken-color-black;
        }

        .ken-simple-css-accordion__label {
          font-family: var(--fontNameBold);
          font-weight: bold;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: 0.2px;
          color: $ken-color-black;
          width: calc(100% - 40px);
          text-transform: uppercase;

          &.--multi-label {
            width: 50%;
            padding-right: 5px;
          }
        }

        .ken-simple-css-accordion__second-label {
          font-family: var(--fontNameLight);
          font-size: 13px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: normal;
          color: $ken-color-black;
          padding-right: 10px;
          width: 40%;
          text-align: right;
        }
      }

      &__content {
        padding: 0 40px 20px 20px;
      }
    }
  }
}
