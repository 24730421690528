.ken-product-tile {
  &__key-features {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    li {
      position: relative;
      font-family: var(--fontNameLight);
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $ken-color-black;
      padding-left: 8px;
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: $ken-color-black;
        top: 5px;
        left: 0;
        border-radius: 50%;
      }

      @include bp('ken-breakpoint-s') {
        font-size: 13px;
        line-height: 1;
      }
    }

    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        display: none;
      }
    }

    @include bp('ken-breakpoint-s') {
      margin-top: 0;
    }
  }
}