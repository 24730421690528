.ken-video--mobile {
  display: block!important;
  @include bp('ken-breakpoint-s') {
    display: none!important;
  }
}

.ken-video--desktop {
  display: none!important;
  @include bp('ken-breakpoint-s') {
    display: block!important;
  }
}