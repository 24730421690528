@mixin sections-margin($margin: 0 20px, $margin-bp-s: 0) {
  margin: $margin;
  @include bp('ken-breakpoint-s') {
    margin: $margin-bp-s;
  }
}

.ken-pdp__main-wrapper {
  margin-bottom: 40px;
  @include bp('ken-breakpoint-s') {
    margin-bottom: 70px;
  }
}

/* GRID TEMPLATE */

.ken-pdp__main-grid {
  position: relative;
  display: grid;
  grid-template-areas: "titles"
                       "reviews"
                       "gallery"
                       "colors"
                       "voltage"
                       "price"
                       "description"
                       "benefits";
  grid-template-columns: 1fr;

  @include bp('ken-breakpoint-s') {
    grid-template-areas:  "gallery titles titles"
                          "gallery description description"
                          "gallery colors reviews"
                          "gallery voltage voltage"
                          "gallery price price"
                          ". benefits benefits";
    grid-template-columns: 1fr 20% 20%;
    grid-gap: 20px;
  }

  &.--missing-voltage {
    grid-template-areas: "titles"
                       "reviews"
                       "gallery"
                       "colors"
                       "price"
                       "description"
                       "benefits";

    @include bp('ken-breakpoint-s') {
      grid-template-areas:  "gallery titles titles"
                            "gallery description description"
                            "gallery colors reviews"
                            "gallery price price"
                            "gallery benefits benefits";
      grid-template-rows: fit-content(100px) fit-content(150px) fit-content(50px) fit-content(155px) auto;
    }
  }

  .ken-pdp__main-info {
    &__wrapper {
      @include sections-margin;
      grid-area: titles;
    }

    &__reviews {
      @include sections-margin;
      grid-area: reviews;
      justify-content: flex-end;

      @include bp('ken-breakpoint-s') {
        margin-top: 0;
      }

      .ken-pdp__main-info__anchor {
        color: inherit;
        text-decoration: none;
        display: flex;

        &.--underline {
          text-decoration: underline;
        }
      }

      &--colors-missing {
        grid-area: colors;
      }
    }

    &__colors {
      @include sections-margin(20px 20px 0);
      grid-area: colors;
    }

    &__gallery-wrapper {
      @include sections-margin(10px 20px 0);
      grid-area: gallery;
      overflow: hidden;
    }

    &__voltage {
      @include sections-margin(25px 0 0);
      grid-area: voltage;
    }

    &__prices {
      @include sections-margin(25px 0 0);
      grid-area: price;

      @include bp('ken-breakpoint-s') {
        align-self: end;
      }
    }

    &__short-desc {
      @include sections-margin(25px 20px 0);
      grid-area: description;
    }

    &__benefits {
      @include sections-margin(20px 20px 0);
      padding-top: 20px;
      border-top: 1px solid $ken-color-grey-xxl;
      grid-area: benefits;

      @include bp('ken-breakpoint-s') {
        padding-top: 0;
        border: 0;
      }
    }
  }
}

.ken-pdp__main-info {
  &__main-category{
    @include ken-pdp-text-small;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__title {
    @include ken-pdp-title;
    letter-spacing: .8px;
  }

  &__title-badge-wrapper {
    display: flex;
    align-items: center;
  }

  &__badges {
    display: flex;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;
    width: 100%;
    flex-wrap: wrap;
  }

  &__badge {
    @include ken-pdp-badge;
    display: flex;
    margin: 0 0 5px 6px;
    padding: 4px;
    background-color: $ken-color-rhubarb-08;
    color: $ken-color-rhubarb;

    &--discount {
      background: $ken-color-rhubarb;
      color: $ken-color-white;
    }
  }

  &__code {
    @include ken-pdp-text-small;
    color: $ken-color-grey-xxl;
    margin-top: 8px;
  }

  &__short-desc {
    &__title {
      @include ken-pdp-description-title;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    &__description {
      @include ken-pdp-description-content;
    }
  }
}
