.ken-header-v2 {
  &__header-band {
    width: 100%;
    background-color: $ken-color-white;

    &__wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;

      @include bp('ken-breakpoint-m') {
        padding: 0 40px;
      }
    }
  }

  &__menus {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__burger-menu {
    @include bp('ken-breakpoint-m') {
      display: none;
    }
  }

  &__logo {
    height: 20px;

    a {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  &__nav-menu {
    display: none;
    list-style: none;

    @include bp('ken-breakpoint-m') {
      display: flex;
      gap: 12px;
      margin-left: 30px;
    }

    @include bp('ken-breakpoint-l') {
      gap: 20px;
      margin-left: 80px;
    }
  }

  &__nav-item {
    font-size: REM(13px);
    font-style: normal;
    font-weight: 500;
    line-height: REM(20px);
    letter-spacing: 0.186px;
    padding: 23px 0;

    @include bp('ken-breakpoint-l') {
      font-size: REM(14px);
      padding: 24px 0;
    }

    &.--active,
    &:hover {
      border-bottom: 2px solid $ken-color-black;

      @include bp('ken-breakpoint-l') {
        padding: 24px 0 22px;
      }
    }

    &__button {
      color: $ken-color-black;
      text-decoration: none;
      cursor: pointer;

      &[href] {
        color: $ken-color-rhubarb;
      }

      .--active &,
      .--inactive & {
        font-weight: 600;
      }

      .--inactive & {
        color: $ken-color-grey-l;
      }

      .--active &[href],
      .--inactive &[href] {
        color: $ken-color-rhubarb;
      }
    }
  }
}
