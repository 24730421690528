.ken-configurator__step-three-products {
  width: 100%;
  background-color: $ken-color-grey-xxs;
  padding: 20px;

  @include bp('ken-breakpoint-m') {
    width: 490px;
    background-color: $ken-color-white;
    padding: 0;
    margin-left: 85px;
  }

  &-item {
    display: flex;
    padding: 20px 8px;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-m') {
      background-color: $ken-color-grey-xs;
      box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);
    }

    &-img {
      width: 55px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-items: center;
      margin-right: 17px;

      img {
        width: 100%;
      }
    }

    &-details {
      @include bp('ken-breakpoint-m') {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
      }

      &-name {
        font-size: 14px;
        font-family: var(--fontNameLight);

        > span {
          display: block;
        }
      }

      &-wrapper {
        display: flex;
        margin-top: 10px;
      }

      &-price {
        font-size: 14px;
        font-family: var(--fontNameSemibold);
        margin-right: 16px;

        &.-desktop {
          display: none;

          @include bp('ken-breakpoint-m') {
            display: inline-block;
          }
        }

        &.-mobile {
          display: block;

          @include bp('ken-breakpoint-m') {
            display: none;
          }
        }
      }

      &-strikethrough-price {
        font-size: 14px;
        font-family: var(--fontNameRegular);
        text-decoration: line-through;
        margin-right: 8px;

        &.-desktop {
          display: none;

          @include bp('ken-breakpoint-m') {
            display: inline-block;
          }
        }

        &.-mobile {
          display: block;

          @include bp('ken-breakpoint-m') {
            display: none;
          }
        }
      }

      &-quantity {
        font-size: 14px;
        font-family: var(--fontNameRegular);

        span {
          font-family: var(--fontNameSemibold);
          margin-left: 4px;
          font-size: 16px;
        }
      }

      &-remove,
      &-change {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
        font-family: var(--fontNameRegular);
      }

      &-remove {
        margin-left: 16px;
      }
    }
  }

  &-product {
    &-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: var(--fontNameSemibold);
      margin-top: 30px;
      margin-bottom: 25px;
    }
  }

  &-accessories {
    margin-bottom: 20px;

    &-empty {
      font-size: 14px;
      font-family: var(--fontNameSemibold);
      margin-bottom: 20px;
    }

    &-add {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
      font-family: var(--fontNameRegular);
      margin-left: auto;
    }

    &-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 25px;

      span {
        font-size: 14px;
        font-family: var(--fontNameSemibold);
      }
    }
  }
}
