.ken-app-component {
  &__wrapper {
    padding: 0 20px;

    @include bp('ken-breakpoint-m') {
      padding: 0;
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
    }
  }

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;

    @include bp('ken-breakpoint-m') {
      width: 50%;
      max-width: 720px;
    }
  }

  &__picture,
  &__picture img {
    width: 100%;
  }

  &__content {
    padding-top: 40px;

    @include bp('ken-breakpoint-m') {
      width: 50%;
      padding-top: 0;
      padding-left: 110px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 520px;
    }
  }

  &__title {
    padding-bottom: 15px;
    font-size: 24px;
    font-family: var(--fontNameSemibold);
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;

    @include bp('ken-breakpoint-m') {
      font-size: 28px;
      line-height: 36px;
      text-align: left;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--fontNameLight);
    text-align: center;
    padding-bottom: 30px;

    @include bp('ken-breakpoint-m') {
      text-align: left;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--fontNameBold);
    text-align: center;
    padding-bottom: 15px;

    @include bp('ken-breakpoint-m') {
      text-align: left;
    }
  }

  &__apps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 330px;
    margin: 0 auto;

    @include bp('ken-breakpoint-m') {
      margin: 0;
    }
  }

  &__app {
    width: 156px;

    &:nth-child(odd) {
      margin-right: 15px;
    }
  }

  &__app-link,
  &__app-link img {
    width: 100%;
  }
}