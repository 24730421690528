.ken-product-tile {
  &__reviews-and-compare {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }

  &__reviews,
  &__bv-review,
  &__compare {
    display: flex;
    align-items: center;
    width: 50%;
    .ken-review-star {
      width: 16px;
      height: 16px;
    }

    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        width: 100%;
      }
    }
  }

  &__bv-review {
    min-height: 24px;
  }

  &__review-number {
    font-family: var(--fontNameLight);
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $ken-color-grey-xxl;
  }

  &__compare {
    justify-content: flex-end;
    display: flex;
    &-label {
      cursor: pointer;
      font-family: var(--fontNameLight);
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $ken-color-black;
      padding-right: 7px;
      order: 1;
    }

    input {
      order: 2;
      border-radius: 0;
      &:checked + label {
        font-family: var(--fontNameRegular);
      }
    }
  }
}
