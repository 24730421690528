.ken-pdp__bundle {
  background-color: var(--ui-03);
  padding: 24px 15px;
  @include bp('ken-breakpoint-m') {
    padding: 60px 30px;
  }

  &__wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__title {
    font-family: var(--fontNameSemibold);
    font-size: REM(18px);
    line-height: 22px;
    margin-bottom: 24px;
    @include bp('ken-breakpoint-m') {
      font-size: REM(20px);
      line-height: 24px;
      margin-bottom: 40px;
    }
  }

  &__info-wrapper {
    @include bp('ken-breakpoint-m') {
      display: inline-block;
    }
  }

  &__products {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--ui-01);
    position: relative;
    @include bp('ken-breakpoint-m') {
      border-bottom: none;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 33px;
    }

    .--plus {
      display: block;
      position: relative;
      width: 20px;
      height: 20px;
      margin-bottom: 16px;
      margin-inline: auto;

      &:last-child {
        display: none;
      }

      @include bp('ken-breakpoint-m') {
        margin-inline: unset;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 8.5px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: var(--brand-04);
        width: 20px;
        height: 3px;
      }
      &::after {
        top: 0;
        width: 3px;
        height: 20px;
      }
    }
  }

  &__product:first-child {
    .--image {
      @include bp('ken-breakpoint-m') {
        max-width: 220px !important;
      }
    }

    .--title {
      @include bp('ken-breakpoint-m') {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    background-color: var(--brand-03);
    padding: 4px 16px 4px 4px;
    margin-bottom: 16px;
    @include bp('ken-breakpoint-m') {
      flex-direction: column;
      gap: 20px;
      padding: 51px 40px;
    }

    .--image {
      display: block;

      width: 84px;
      @include bp('ken-breakpoint-m') {
        width: 100%;
        max-width: 168px;
      }
    }

    .--title {
      font-family: var(--fontNameSemibold);
      font-size: REM(14px);
      line-height: 18px;
      text-align: left;
      width: calc(100% - 84px - 90px);
      @include lineClamp(2);
      @include bp('ken-breakpoint-m') {
        width: 100%;
        flex-direction: column;
        line-height: 20px;
        text-align: center;
        max-width: 236px;
      }
    }

    .--price {
      font-family: var(--fontNameMedium);
      font-size: REM(14px);
      line-height: 20px;
      width: 90px;
      text-align: right;
      @include bp('ken-breakpoint-m') {
        flex-direction: column;
        line-height: 17px;
        text-align: center;
        width: auto;
      }
    }

    .--free-gift {
      display: flex;
      align-items: center;
      font-family: var(--fontNameSemibold);
      font-size: REM(12px);
      line-height: 16px;
      padding: 5px;
      padding-top: 7px;
      background-color: var(--brand-01);
      color: var(--brand-03);
      text-align: center;
    }
  }

  &__totals {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 24px;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      margin-top: 40px;
      gap: 32px;
    }

    .--prices-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      @include bp('ken-breakpoint-m') {
        margin-bottom: 0;
        gap: 15px;
      }
    }

    .--subtotal {
      font-family: var(--fontNameSemibold);
      font-size: REM(16px);
      line-height: 24px;
      align-self: flex-end;

      @include bp('ken-breakpoint-m') {
        align-self: unset;
      }
    }

    .--prices {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .--old-price {
      font-family: var(--fontNameRegular);
      font-size: REM(14px);
      line-height: 20px;
      color: var(--brand-02);
      text-decoration: line-through;
    }

    .--price {
      font-family: var(--fontNameSemibold);
      font-size: REM(16px);
      line-height: 24px;
    }

    .--savings-wrapper {
      display: flex;
      width: 100%;
      order: -1;
      justify-content: flex-end;
      @include bp('ken-breakpoint-m') {
        order: unset;
        width: auto;
        justify-content: unset;
      }
    }

    .--savings {
      display: flex;
      align-items: center;
      font-family: var(--fontNameSemibold);
      font-size: REM(12px);
      line-height: 16px;
      padding: 5px;
      padding-top: 7px;
      color: var(--brand-01);
      background-color: var(--support-01--light);
      text-align: center;
    }

    button {
      width: 100%;
      @include bp('ken-breakpoint-m') {
        max-width: 187px;
      }
    }
  }
}
