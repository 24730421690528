.ken-attachments {
  max-width: 1199px;
  padding: 20px;

  & &__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      justify-content: space-between;
    }

    &--no-link {
      justify-content: center;
    }
  }

  & &__title {
    margin-bottom: 30px;
    font-family: var(--fontNameExtra);
    font-size: 28px;
    line-height: 1.21;
    text-align: center;
    color: $ken-color-black;
    @include bp('ken-breakpoint-m') {
      margin-bottom: 0;
      font-size: 32px;
      line-height: 1.25;
    }

    .ken-attachments__title-wrapper--no-link & {
      margin-bottom: 0;
    }
  }

  & &__link {
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    color: $ken-color-black;
  }

  & &__products__container {
    width: 100%;
  }

  & &__products__wrapper {
    height: 100%;
    margin-bottom: 31px;

    @include bp('ken-breakpoint-m') {
      margin-bottom: 19px;
    }
  }

  & &__product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 172px;
    height: 250px;
    border-right: 1px solid $ken-color-grey-m;
    padding: 25px 20px;

    [dir='rtl'] & {
      border-right: none;
      border-left: 1px solid $ken-color-grey-m;
    }

    @include bp('ken-breakpoint-m') {
      width: 200px;
      padding: 25px 30px;
    }

    &:last-child {
      border: none;
    }

    &__image {
      width: 100%;
      height: 120px;
      margin-bottom: 25px;
    }

    &__title {
      font-family: var(--fontNameBold);
      font-size: 14px;
      font-weight: bold;
      line-height: 1.29;
      letter-spacing: 0.2px;
      text-align: center;
      color: $ken-color-black;
      text-decoration: none;
    }
  }

  & &__navigation {
    display: flex;
    align-items: center;
    width: 100%;

    &--hidden {
      &-tablet {
        @include bp('ken-breakpoint-m') {
          display: none;
        }
      }

      &-desktop {
        @include bp('ken-breakpoint-l') {
          display: none;
        }
      }
    }

    .swiper-scrollbar {
      flex-grow: 2;
      flex-shrink: 0;
      height: 3px;
      border-radius: 0;
      background-color: $ken-color-grey-s;

      &-drag {
        background-color: $ken-color-rhubarb;
      }
    }

    .swiper-button {
      &-disabled i {
        color: gray; 
      }
     
      &-next,
      &-prev {
        display: none;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        position: static;
        $size: 24px;
        width: $size;
        height: $size;
        margin-left: 18px;
        color: $ken-color-rhubarb;

        [dir='rtl'] & {
          margin-left: 0;
          margin-right: 18px;
        }

        @include bp('ken-breakpoint-m') {
          display: flex;
        }

        &::after {
          content: '';
        }

        i {
          $size: 19px;
          width: $size;
          height: $size;

          [dir='rtl'] & {
            transform: rotate(180deg);
          }
        }
      }

      &__prev {
        margin-left: 27px;

        [dir='rtl'] & {
          margin-right: 27px;
        }
      }
    }
  }
}
