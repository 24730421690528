.ken-promobannerslider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #1a1d1f;
  padding-left: 20px;
  height: auto;
  overflow: hidden;

  @include bp('ken-breakpoint-xl') {
    padding-left: 120px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    @include ken-text-h2;
    font-family: var(--fontNameBold);
    font-weight: bold;
    text-align: center;

    @include bp('ken-breakpoint-s') {
      justify-content: initial;
      margin: 0 0 20px;
      padding: 0;
      text-align: initial;
    }

    @include bp('ken-breakpoint-xl') {
      margin: 0 0 20px;
    }
  }

  .ken-promobanner {
    &__texts {
      @include bp('ken-breakpoint-s') {
        max-width: 60%;
        padding: 32px 0 32px 37px;

        &-kicker {
          font-size: 10px;
          line-height: 12px;
          transition: all 0.2s ease 0s;
        }

        &-title {
          font-size: 24px;
          line-height: 28px;
          transition: all 0.2s ease-in-out 0s;
        }

        &-cta {
          font-size: 12px;
          transition: all 0.2s ease 0s;
        }
      }

      @include bp('ken-breakpoint-m') {
        padding: 47px 0 47px 37px;

        &-kicker {
          font-size: 13px;
          line-height: 16px;
        }

        &-title {
          font-size: 26px;
          line-height: 30px;
        }

        &-cta {
          font-size: 13px;
          line-height: 20px;
        }
      }

      @include bp('ken-breakpoint-l') {
        &-kicker {
          font-size: 14px;
          line-height: 20px;
        }

        &-title {
          font-size: 32px;
          line-height: 40px;
        }

        &-title {
          &.--montserrat-bold {
            font-family: var(--fontNameBold);
            font-weight: bold;
          }
        }

        &-cta {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &__slider {
    &--hidden-mobile.swiper-footer {
      display: none;
    }

    &--hidden-desktop.swiper-footer {
      @include bp('ken-breakpoint-s') {
        display: none;
      }
    }
  }

  .swiper-container {
    max-width: 100%;
    margin: 0;

    .swiper-wrapper {
      padding: 0;
      align-items: center;

      .swiper-slide {
        padding: 0;

        @include bp('ken-breakpoint-s') {
          padding: 0 0 10px;
        }

        .ken-promobanner {
          padding: 18px 0 38px;

          @include bp('ken-breakpoint-s') {
            padding: 0;
          }
        }
      }

      @include bp('ken-breakpoint-s') {
        padding: 0;
      }
    }
  }

  .swiper-footer {
    max-width: none;
    margin: 0;
    width: 100%;
    padding: 0 20px;

    @include bp('ken-breakpoint-s') {
      height: 40px;
      width: calc(100% - 74px);
      margin: 0;
      padding: 0;
    }

    @include bp('ken-breakpoint-xl') {
      width: 100%;
      margin: 0;
    }

    .swiper-scrollbar {
      width: 100%;
      height: 3px;
      background-color: $ken-color-grey-s;

      margin: 0;
      padding-top: 0;

      @include bp('ken-breakpoint-s') {
        width: calc(100% - 90px);
        margin-right: 20px;
        order: 1;
      }

      &-drag {
        background-color: $ken-color-rhubarb;
      }
    }

    .ken-icon-activewrap {
      padding: 4px 3px 3px 4px;
      border-radius: 5px;
      background-color: $ken-color-none;

      &:hover,
      &:active {
        background-color: $ken-color-grey-xs;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;

      &::after {
        display: none !important;
        text-indent: -99999cm;
      }

      &.swiper-button-disabled {
        cursor: not-allowed;
      }

      @include bp('ken-breakpoint-s') {
        display: flex !important;
        margin: 8px;
      }

      &:not(.ken-icon-activewrap),
      &.ken-icon-activewrap::after {
        @extend .ken-icon;
        height: 17px;
        width: 17px;
      }
    }

    .swiper-button-prev {
      order: 2;
    }
    .swiper-button-next {
      order: 3;
    }

    .swiper-button-prev::after,
    .swiper-button-prev:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-left-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-left-grey;
      }
    }

    .swiper-button-next::after,
    .swiper-button-next:not(.ken-icon-activewrap) {
      @extend .ken-icon--arrow-right-rhubarb;

      &.swiper-button-disabled:not(.ken-icon-activewrap),
      &.swiper-button-disabled.ken-icon-activewrap::after {
        @extend .ken-icon--arrow-right-grey;
      }
    }
  }
}
