@mixin ken-button--standard-text-size {
  font-size: 12px;
  line-height: 23px;
}
@mixin ken-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: all .5s ease 0s;
  border: none;
  background-color: transparent;
  font-family: var(--fontNameSemibold);
  @include ken-button--standard-text-size;
  letter-spacing: 0.5px;
  padding: 10px 20px;
  cursor: pointer;
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

@mixin ken-button--primary {
  color: $ken-color-white;
  background-color: $ken-color-rhubarb;
  border: 1px solid $ken-color-rhubarb;

  &:not(:disabled):hover {
    background-color: $ken-color-black;
    border: 1px solid $ken-color-black;
    color: $ken-color-white;
  }
}

@mixin ken-button--secondary {
  color: $ken-color-rhubarb;
  background-color: transparent;
  border: 1px solid $ken-color-rhubarb;

  &:not(:disabled):hover {
    color: $ken-color-white;
    background-color: $ken-color-rhubarb;
  }
}

@mixin ken-button--secondary-white {
  color: $ken-color-rhubarb;
  background-color: $ken-color-white;
  border: 1px solid $ken-color-rhubarb;

  &:not(:disabled):hover {
    color: $ken-color-white;
    background-color: $ken-color-rhubarb;
  }
}

@mixin ken-button--tertiary {
  color: $ken-color-white;
  background-color: transparent;
  border: 1px solid $ken-color-white;

  &:not(:disabled):hover {
    color: $ken-color-white;
    background-color: $ken-color-rhubarb;
  }
}

@mixin ken-button--alternative {
  color: $ken-color-rhubarb;
  background-color: $ken-color-white;
  border: 1px solid $ken-color-white;

  &:not(:disabled):hover {
    color: $ken-color-white;
    background-color: $ken-color-rhubarb;
    border: 1px solid $ken-color-rhubarb;
  }
}

@mixin ken-button--link {
  @include ken-text-link;
  padding: 0;

  &:hover {
    opacity: .6;
  }
}

@mixin ken-button--notify-me {
  color: $ken-color-black;
  background-color: $ken-color-white;
  border: 1px solid $ken-color-black;

  &:hover {
    background-color: $ken-color-black;
    color: $ken-color-white;
  }
}

@mixin ken-button--voltage {
  color: $ken-color-black;
  background-color: $ken-color-white;
  border: 1px solid $ken-color-black;
  opacity: .4;

  &:hover {
    opacity: 1;
  }
}

.ken-button--notify-me {
  @include ken-button;
  @include ken-button--notify-me;
}

.ken-button {
  @include ken-button;
}

.ken-button--link {
  @include ken-button;
  @include ken-button--link;
}

.ken-button--primary,
.ken-button--primary--small {
  @include ken-button;
  @include ken-button--primary;
}

.ken-button--secondary,
.ken-button--secondary--small {
  @include ken-button;
  @include ken-button--secondary;
}

.ken-button--secondary-white,
.ken-button--secondary-white--small {
  @include ken-button;
  @include ken-button--secondary-white;
}

.ken-button--tertiary,
.ken-button--tertiary--small {
  @include ken-button;
  @include ken-button--tertiary;
}

.ken-button--alternative,
.ken-button--alternative--small {
  @include ken-button;
  @include ken-button--alternative;
}

.ken-button--primary--small,
.ken-button--secondary--small,
.ken-button--secondary-white--small,
.ken-button--alternative--small {
  line-height: 18px;
}

@mixin ken-button--plus--bw($blackwhite: 'black', $rotatedeg: '0') {
  text-align: center;
  cursor: pointer;
  opacity: 1;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("#{$pathAssets}/images/icons/icon-plus-#{$blackwhite}.svg");
    transform: rotate(#{$rotatedeg}deg);
    transition: all 0.2s ease-in-out;
  }
}

@mixin ken-button--plus-dark {
  @include ken-button--plus--bw();
}

@mixin ken-button--plus-light {
  @include ken-button--plus--bw('white');
}

@mixin ken-button--plus {
  @include ken-button--plus-dark;
}
