.ken-subcategorylist {
  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
    background: $ken-color-grey-xs;

    .ken-hero {
      margin: 0 auto;

      &__textblock {
        background-color: inherit;

        &-content {
          font-size: 14px;
          line-height: 1.43;
        }
      }
    }

    &__subslider-pane {
      display: flex;
      height: 410px;
      margin: -53px 0 0 8.3334%;
      padding: 0;
      overflow: hidden;
    }

    &__sideText {
      width: auto;
      max-width: 200px;
      padding: 0;
      margin: 53px 20px 0 0;
      overflow: hidden;

      .ken-titlesubtitlelink {
        align-items: flex-start;
        text-align: left;

        &__title {
          margin: 0;
          font-size: 28px;
          line-height: 36px;
        }

        &__subtitle {
          margin-top: 15px;
        }

        &__link {
          margin-top: 10px;
        }
      }
    }

    &__slider {
      &.swiper-container {
        height: 410px;
        padding: 73px 0 96px 35px;
        margin: 0;
      }
    }

    &__item {
      &.swiper-slide {
        align-items: center;
        border: 1px solid transparent;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        .swiper-container:not(.swiper-container-initialized) & {
          margin-right: 10px;
        }

        &.swiper-slide-prev {
          opacity: 0;
        }

        &:hover {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        }

        .ken-titleimagelink__cta {
          display: none;
          font-size: 10px;
          padding: 7px 15px;
          cursor: pointer;
          transform: scale(0.75);
        }

        &.swiper-slide-active {
          z-index: 1;
          transform: scale(1.5);
          transition: transform 0.5s;
          margin-right: 42px !important;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);

          .ken-titleimagelink {
            &__title {
              font-size: 11px;
            }

            &__imgcanvas {
              max-height: none;
              margin: 0;
              justify-content: start;
            }

            &__cta {
              display: block;
            }
          }
        }
      }
    }

    .swiper-footer {
      justify-content: flex-end;
      margin-top: 23px;
      height: 40px;
      padding-right: 30px;

      .swiper-scrollbar {
        display: none;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: flex !important;
        margin: 8px;
      }
      .swiper-button-prev {
        order: 2;
      }
      .swiper-button-next {
        order: 3;
      }
    }
  }

  @include bp('ken-breakpoint-m') {
    &__sideText {
      max-width: 285px;
    }

    &__slider {
      &.swiper-container {
        padding: 73px 0 96px 46px;

        &.--noCarousel {
          > .swiper-wrapper {
            > .swiper-slide {
              &:hover,
              &:only-child {
                z-index: 1;
                transform: scale(1.5);
                transition: transform 0.5s;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                  0 1px 2px rgba(0, 0, 0, 0.24);

                .ken-titleimagelink__cta {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .swiper-footer {
      padding-right: 60px;
    }
  }

  @include bp('ken-breakpoint-l') {
    &__slider {
      &.swiper-container {
        padding-left: 94px;
      }
    }

    &__item {
      &.swiper-slide {
        max-width: 183px;
      }
    }

    .swiper-footer {
      padding-right: 120px;
    }
  }

  @include bp('ken-breakpoint-xl') {
    &__slider {
      &.swiper-container {
        padding-left: 67px;
      }
    }
  }
}

html[dir='rtl'] {
  .ken-subcategorylist {
    @include bp('ken-breakpoint-m') {
      &__subslider-pane {
        margin: -53px 8.3334% 0 0;
      }

      &__sideText {
        margin: 53px 0 0 20px;
      }

      &__item {
        &.swiper-slide {
          &.swiper-slide-active {
            margin: -13px 50px 0 70px !important;
          }
        }
      }

      .swiper-footer {
        padding-right: 0;
        padding-left: 30px;

        @include bp('ken-breakpoint-m') {
          padding-left: 60px;
        }

        @include bp('ken-breakpoint-l') {
          padding-left: 120px;
        }

        .swiper-pagination {
          order: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          right: 0;
          left: 0;
        }
        .swiper-button-prev {
          order: 3;
        }
        .swiper-button-next {
          order: 2;
        }
      }
    }
  }
}

.ken-subcategoryslider__item.swiper-slide-prev,
.ken-subcategoryslider__item.swiper-slide-next {
  .ken-subcategorylist {
    opacity: 0;
  }
}
.ken-subcategoryslider__item.swiper-slide-active {
  z-index: 1;
  .ken-subcategorylist {
    opacity: 1;
  }
}
