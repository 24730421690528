.ken-pdp__main-info__reviews {
  display: flex;
  align-items: center;
  font-size: 12px;

  .ken-review-star {
    width: 16px;
    height: 16px;
  }
}

.ken-pdp__reviews {
  margin: 0 20px;
  padding: 100px 0;

  @include bp('ken-breakpoint-s') {
    margin: 0 auto;
  }

  &__title {
    @include ken-pdp-components-title;
    margin-bottom: 30px;
    text-align: center;

    @include bp('ken-breakpoint-s') {
      text-align: initial;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include bp('ken-breakpoint-s') {
      flex-direction: row;
    }
  }

  &__side {
    flex-basis: 20%;
    margin: 0 auto 40px;

    @include bp('ken-breakpoint-s') {
      margin: 0;
    }
  }

  &__overall-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $ken-color-grey-xs;
    padding: 23px 30px;
  }

  &__average {
    @include ken-pdp-average-number;
    margin-bottom: 20px;
  }

  &__list {
    flex-basis: 75%;

    &__single {
      display: flex;
      padding-bottom: 30px;
      border-bottom: 1px solid $ken-color-grey-s;
      margin-bottom: 35px;
      flex-direction: column;

      @include bp('ken-breakpoint-s') {
        flex-direction: row;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      &__info {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        @include bp('ken-breakpoint-s') {
          margin-right: 60px;
          flex-basis: 12%;
          margin-bottom: 0;
        }
      }

      &__text {
        @include bp('ken-breakpoint-s') {
          flex-basis: calc(88% - 60px);
        }
      }

      &__title {
        @include ken-pdp-description-title;
        margin-bottom: 10px;
      }

      &__content {
        @include ken-pdp-description-content;
      }

      &__stars {
        .ken-review-star {
          width: 20px;
          height: 20px;
          margin-right: -3px;
        }
      }

      &__customer {
        @include ken-pdp-reviewer;
        margin-bottom: 5px;
        word-break: break-word;
      }

      &__date {
        @include ken-pdp-text-small;
        color: $ken-color-grey-xxl;
      }

      &.--hidden {
        display: none;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  &__showing-text {
    margin-bottom: 10px;
  }

  &__write-cta {
    @extend .ken-button--secondary;
    width: 100%;
    margin-top: 20px;
    text-transform: uppercase;
  }

  &__show-more-cta {
    text-transform: uppercase;
  }

  .ken-review-star {
    width: 20px;
    height: 20px;
  }
}
