.ken-pdp__main-info__benefits {
  &__item {
    border: 0;
    background: transparent;
    display: inline-flex;
    margin: 10px 5px 0 0;

    @include bp('ken-breakpoint-s') {
      margin: 10px 20px 0 0;
    }

    span {
      @include ken-button--link;
      color: $ken-color-grey-xxl;
      font-family: var(--fontNameRegular);
      font-size: 12px;
    }

    .ken-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}