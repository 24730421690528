.ken-header {
  &__header-band {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid $ken-color-grey-m;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-l') {
      padding: 10px 0 14px;
    }

    &__wrapper {
      @include bp('ken-breakpoint-l') {
        max-width: calc(100% - 2rem) !important;
      }
    }
  }

  &__links-container {
    flex-grow: 2;
  }

  &__top-row {
    display: none;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 16px;

    @include bp('ken-breakpoint-l') {
      display: flex;
    }
  }

  &__bottom-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include bp('ken-breakpoint-l') {
      justify-content: flex-end;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__header-lite {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 20px;
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-l') {
      padding: 10px 0 14px;
    }
  }
}
