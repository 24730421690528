.ken-articleslinked {
  .ken-articleslinked {
    &__article {
      display: flex;
      flex-direction: column;
      width: 286px;
      padding: 0;
      background-color: $ken-color-white;

      &__link {
        display: block;
        width: 100%;
      }

      &__img {
        width: 100%;
        height: auto;
      }

      &__badge {
        width: fit-content;
        background-color: $ken-color-grey-xxl;
        padding: 3px 5px;
        font-family: var(--fontNameSemibold);
        font-size: 11px;
        font-weight: 500;
        line-height: 1.09;
        color: $ken-color-white;
        text-transform: uppercase;
      }

      &__title {
        margin: 6px 0 10px;
        font-family: var(--fontNameSemibold);
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        color: $ken-color-black;
      }

      &__desc {
        font-family: var(--fontNameRegular);
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        color: $ken-color-black;
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      height: 200px;
      padding: 20px;
    }
  }
}
