$ken-simple-css-accordion-size: 14px;

.ken-simple-css-accordion {
  position: relative;

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__title {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__icon {
    @include bp('ken-breakpoint-s') {
      display: none;
    }
  }

  &__icon,
  &__icon-desktop {
    position: relative;
    z-index: 2;
    width: $ken-simple-css-accordion-size;
    height: $ken-simple-css-accordion-size;

    &:before {
      position: absolute;
      content: '';
      width: $ken-simple-css-accordion-size;
      height: 2px;
      background: $ken-color-black;
      top: ($ken-simple-css-accordion-size / 2) - 1;
      transition: all 300ms ease;
    }

    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: $ken-simple-css-accordion-size;
      background: $ken-color-black;
      left: ($ken-simple-css-accordion-size / 2) - 1;
      transition: all 300ms ease;
    }
  }

  &__content,
  &__content-desktop {
    position: absolute;
    visibility: hidden;
    z-index: 1;
    transform: translateY(-100px);
  }

  &__content {
    @include bp('ken-breakpoint-s') {
      position: relative;
      visibility: visible;
      transform: none;
      transition: all 300ms ease;
    }
  }

  input[type='checkbox'] {
    display: none;

    &:checked + .ken-simple-css-accordion__wrapper {
      .ken-simple-css-accordion__content,
      .ken-simple-css-accordion__content-desktop {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
      }

      .ken-simple-css-accordion__icon,
      .ken-simple-css-accordion__icon-desktop {
        &:after{
          transform: rotate(90deg);
        }
      }
    }
  }

}