.ken-relatedproducts {
  &.--pdp {
    .ken-product-tile {
      &:hover {
        .ken-product-tile__color-variants-label {
          opacity: 1;
        }
      }

      &__color-variants-label {
        position: absolute;
        top: 0;
        padding: 5px 0;

        @include bp('ken-breakpoint-s') {
          position: initial;
          padding: 10px 0;
        }
      }
    }
  }
}