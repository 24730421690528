.ken-header-v2 {
  .ken-stripe {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    padding: 7px 0;

    &__container {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $ken-color-black;

      &.ken-stripe--hidden {
        display: none;
      }

      .swiper-button {
        &-prev,
        &-next {
          display: flex;
          justify-content: center;
          align-items: center;
          $size: 16px;
          width: $size;
          height: $size;
          top: 11px;

          &:after {
            content: '';
          }

          @include bp('ken-breakpoint-s') {
            display: none;
          }

          .ken-icon {
            $size: 13px;
            width: $size;
            height: $size;
          }
        }

        &-prev {
          left: 16px;
          right: auto;
        }

        &-next {
          right: 16px;
          left: auto;
        }
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      width: fit-content;
      padding: 4px 20px;

      @include bp('ken-breakpoint-s') {
        border-right: 1px solid $ken-color-grey-m;

        &:last-child {
          border-right: none;
        }
      }

      [dir='rtl'] & {
        @include bp('ken-breakpoint-s') {
          border-right: none;
          border-left: 1px solid $ken-color-grey-m;

          &:last-child {
            border-left: none;
          }
        }
      }

      &,
      & a {
        font-family: var(--fontNameRegular);
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: 0.19px;
        text-align: center;
        color: $ken-color-white;
      }

      & a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @include bp('ken-breakpoint-s') {
    &.isCLSession {
      margin-top: 40px;

      .ken-stripe__container {
        top: 0;
        left: 50%;
        transform: translate(-50%);
        position: fixed;
        z-index: 10;

        &.--hidden {
          display: flex;
        }
      }
    }
  }
}
