.ken-whatsinthebox {
  padding: 60px 15px;
  background-color: $ken-color-white;

  &-bgcolor--gray {
    background-color: $ken-color-grey-xs;
  }

  &__title {
    margin-bottom: 40px;
    font-family: var(--fontNameSemibold);
    font-size: 20px;
    line-height: 1.2;
    color: $ken-color-black;
    text-align: center;
  }

  &__wrapper {
    margin: 0 1.45%;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $ken-color-grey-m;
  }

  &__item {
    height: 53.334vw;
    width: 46.956522%;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .ken-imagelink {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__imgWrap {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 100px;
        max-height: 100px;
        position: relative;
        z-index: 1;
      }

      &__imgOverlay {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 3;
        background-color: rgba(0,0,0,.04);
      }

      &__imgcanvas {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 100px;
        max-height: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2
      }

      &__img {
        height: auto !important;
        width: auto !important;
        max-width: 100%;
        max-height: 100%;
      }

      &__imgFile {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
        user-select: none;
      }

      &__link {
        @include ken-button--link;
        text-decoration: none;
      }

      &__name {
        font-family: var(--fontNameBold);
        font-size: 12px;
        line-height: 1.33;
        color: $ken-color-black;
        text-align: center;
        margin-top: 12px;
      }
    }
  }

  &__showall {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    transition: height 0.3s ease-out;
    width:100%;
  }

  &__showhandle {
    @extend .ken-button--link;
    text-decoration: none;
    margin: 45px auto 0;
    display: flex;
    align-items: center;

    &-caption {
      &--all,
      &--less {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &-caption {
      &--all {
        display: block;
      }

      &--less {
        display: none;
      }
    }

    &-icon {
      @extend .ken-icon;
      @extend .ken-icon--arrow-down-black;
      width: 13px;
      height: 13px;
      margin: 0 0 0 7px;
    }
  }

  .--expanded {
    &__showall {
      transition: height 0.3s ease-out;
      justify-content: flex-start;
    }

    .ken-whatsinthebox__showhandle {
      &-caption {
        &--all {
          display: none;
        }

        &--less {
          display: block;
        }
      }

      &-icon {
        @extend .ken-icon--arrow-up-black;
      }
    }
  }

  @include bp('ken-breakpoint-s') {
    padding: 70px 0;

    &__wrapper {
      margin: auto;
      padding-bottom: 5px;
      justify-content: center;
    }

    &__item {
      width: 20%;
      margin: 0 0 10px 0;
      padding: 0 1.907630%;

      &:nth-child(5),
      &:last-child {
        margin-right: 0;
      }
    }

    &__showall {
      justify-content: flex-start;
    }

    &__showhandle {
      margin: 0 auto 34px;
    }
  }
}

html[dir='rtl'] {
  .ken-whatsinthebox__showhandle-icon {
    margin: 0 7px 0 0;
  }
}
