$iconsPathSmall: '#{$pathAssets}/images/kenwood/icons/small';
$iconsPathCore: '#{$pathAssets}/images/kenwood/icons/Core';
$iconsPathReviews: '#{$pathAssets}/images/kenwood/icons/Reviews';

.ken-icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &--logo {}

  /*
    Header Icons
   */
  &--search {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/search.svg");
  }
  &--headphones {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/headphones.svg");
    &:hover {
      background-image: url("#{$pathAssets}/images/kenwood/icons/header/headphones-rhubarb.svg");
    }
  }
  &--wishlist {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/wishlist.svg");
  }
  &--wishlist-full {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/wishlist-full.svg");
  }
  &--account {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/account.svg");
  }
  &--user-loggedin {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $ken-color-black;
    font-family: var(--fontNameBold);
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: $ken-color-white;
  }
  &--logout {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/logout.svg");
  }
  &--cart {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/cart.svg");
  }
  &--hamburger {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/hamburger.svg");
  }
  &--close {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/close.svg");
  }
  &--close-v2 {
    background-image: url("#{$pathAssets}/images/kenwood/icons/header/close-v2.svg");
  }

  &--plus-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/plus-rhubarb.svg");
  }
  &--plus-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/plus-grey-dark.svg");
  }
  &--plus-grey {
    @extend .ken-icon--plus-grey-l;
  }

  &--minus-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/minus-rhubarb.svg");
  }
  &--minus-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/minus-grey-dark.svg");
  }
  &--minus-grey {
    @extend .ken-icon--minus-grey-l;
  }

  /*
    Social icons
  */
  &--facebook {}
  &--instagram {}
  &--twitter {}
  &--youtube {}
  &--googleplay {}
  &--appstore {}

  /*
    Angle brackets aka "Arrows"
  */
  &--arrow-left-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/left-rhubarb.svg");
  }
  &--arrow-left-white {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/left-white.svg");
  }
  &--arrow-left-black {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/left-black.svg");
  }
  &--arrow-left-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/left-grey-dark.svg");
  }
  &--arrow-left-grey-xxl {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/left-grey-darkest.svg");
  }
  /*
  std grey icons ==> grey-dark:
  coded as a standard inactive/disabled icon color (#949494, that named as 'grey-l', thats over the '-m' medium value, then the first towards darkers) with or w/o alpha-opacity applied
  To be noted for a styleguide of brand theme
  */
  &--arrow-left-grey {
    @extend .ken-icon--arrow-left-grey-l;
  }


  &--arrow-right-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/right-rhubarb.svg");
  }
  &--arrow-right-white {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/right-white.svg");
  }
  &--arrow-right-black {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/right-black.svg");
  }
  &--arrow-right-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/right-grey-dark.svg");
  }
  &--arrow-right-grey-xxl {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/right-grey-darkest.svg");
  }
  &--arrow-right-grey {
    @extend .ken-icon--arrow-right-grey-l;
  }

  &--arrow-up-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/up-rhubarb.svg");
  }
  &--arrow-up-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/up-grey-dark.svg");
  }
  &--arrow-up-grey-xxl {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/up-grey-darkest.svg");
  }
  &--arrow-up-grey {
    @extend .ken-icon--arrow-up-grey-l;
  }
  &--arrow-up-black {
    background-image: url("#{$iconsPathSmall}/up-black.svg");
  }

  &--arrow-down-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/down-rhubarb.svg");
  }
  &--arrow-down-grey-l {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/down-grey-dark.svg");
  }
  &--arrow-down-grey-xxl {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/down-grey-darkest.svg");
  }
  &--arrow-down-grey {
    @extend .ken-icon--arrow-down-grey-l;
  }
  &--arrow-down-black {
    background-image: url("#{$iconsPathSmall}/down-black.svg");
  }

  /* Big Rhubarb Arrows */
  &--arrow-big-left-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Core/Left.svg");
  }
  &--arrow-big-right-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Core/Right.svg");
  }
  &--arrow-big-up-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Core/Up.svg");
  }
  &--arrow-big-down-rhubarb {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Core/Down.svg");
  }

  /* Favorites */
  &--wishlist {
    background-image: url("#{$iconsPathSmall}/heart-black.svg");
  }

  &--wishlist:hover {
    background-image: url("#{$iconsPathSmall}/heart-rhubarb.svg");
  }

  &--wishlist-active {
    background-image: url("#{$iconsPathSmall}/heart-fill-rhubarb.svg");
  }

  &--star-empty {
    background-image: url("#{$iconsPathReviews}/star-empty.svg");
  }
  &--star-full {
    background-image: url("#{$iconsPathReviews}/star-full.svg");
  }
  &--star-half {
    background-image: url("#{$iconsPathReviews}/star-half.svg");
  }
  &--filter {
    background-image: url("#{$iconsPathCore}/filter-black.svg");
  }
  &--close {
    background-image: url("#{$iconsPathCore}/close-black.svg");
  }
  &--down-black {
    background-image: url("#{$iconsPathSmall}/down-black.svg");
  }
  &--check-black {
    background-image: url("#{$iconsPathCore}/check-black.svg");
  }
  &--check-red {
    background-image: url("#{$iconsPathCore}/check-red.svg");
  }
  
  /* Manual */

  &--manual-pdf {
    background-image: url("#{$iconsPathCore}/Pdf-black.svg");
  }
  
  /* Where to Buy */

  &--modal-close {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Core/Close.svg");
  }

  /* Benefits */
  &--shipping {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/delivery-grey.svg");
  }

  &--returns {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/returns-grey.svg");
  }

  &--secure {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/lock-grey.svg");
  }

  &--check {
    background-image: url("#{$pathAssets}/images/kenwood/icons/small/check-green.svg");
  }

  &--play-white {
    background-image: url("#{$pathAssets}/images/kenwood/icons/Big/Play.svg");
  }
}
