$ken-pb-texts-padvert-xl: 99;
$dscale-to--l: .8333;
$dscale-to--m: .7111;
$dscale-to--s: .5333;
$ken-promobanner-texts-padding--xl: #{$ken-pb-texts-padvert-xl}px 0 #{$ken-pb-texts-padvert-xl}px 37px;
$ken-promobanner-texts-padding--l: calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--l}) 0 calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--l}) 37px;
$ken-promobanner-texts-padding--m: calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--m}) 0 calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--m}) 37px;
$ken-promobanner-texts-padding--s: calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--s}) 0 calc(#{$ken-pb-texts-padvert-xl}px * #{$dscale-to--s}) 37px;

.ken-promobanner {
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden;

  &-wrapper {
    position: relative;
  }

  &__picture {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &-image {
      width: 100%;
      max-width: 100%;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 196px;
    padding: 18px 20px 20px;

    &-kicker {
      margin: 0;
      @include ken-text-body-light;
      font-family: var(--fontNameSemibold);
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-title {
      @include ken-text-h3;

      &.--montserrat-bold {
        font-family: var(--fontNameBold);
        font-weight: bold;
      }
    }

    &-cta {
      font-family: var(--fontNameSemibold);
      text-align: inherit;
      align-self: flex-start;
      margin-top: 10px;

      &--primary {
        @extend .ken-button--primary;
      }

      &--secondary {
        @extend .ken-button--secondary;
      }

      &--alternative {
        @extend .ken-button--alternative;
      }

      &--link {
        @extend .ken-button--link;
      }
    }

    &--mobile-light,
    &--mobile-light .ken-promobanner__texts-kicker,
    &--mobile-light .ken-promobanner__texts-title,
    &--mobile-light .ken-promobanner__texts-cta--link {
      @include bp('ken-breakpoint-max-s', 'max') {
        color: $ken-color-white;
      }
    }
  }

  @include bp('ken-breakpoint-s') {
    padding: 0;

    &__texts {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      max-width: 50%;
      padding: #{$ken-promobanner-texts-padding--s};
      background-color: transparent !important;
      transition: all 0.2s ease .0s;

      &--desktop-light,
      &--desktop-light .ken-promobanner__texts-kicker,
      &--desktop-light .ken-promobanner__texts-title,
      &--desktop-light .ken-promobanner__texts-cta--link {
        color: $ken-color-white;
      }

      &-kicker {
        margin-bottom: 12px;
      }
    }
  }

  @include bp('ken-breakpoint-m') {
    &__texts {
      padding: #{$ken-promobanner-texts-padding--m};
    }
  }

  @include bp('ken-breakpoint-l') {
    &__texts {
      padding: #{$ken-promobanner-texts-padding--l};
    }
  }

  @include bp('ken-breakpoint-xl') {
    &__texts {
      padding: #{$ken-promobanner-texts-padding--xl};
    }
  }
}

html[dir='rtl'] .ken-promobanner {
  @include bp('ken-breakpoint-s') {
    &__texts {
      left: auto;
    }
  }
}
