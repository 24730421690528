.ken-colortext {
  padding: 100px 40px;
  text-align: center;

  @include bp('ken-breakpoint-s') {
    padding: 150px 40px;
  }

  &__wrapper {
    max-width: 100%;

    @include bp('ken-breakpoint-s') {
      max-width: 590px;
      margin: 0 auto;
    }
  }

  &__title {
    @include ken-text-h1;
    margin-bottom: 15px;
  }

  &__description {
    font-size: 16px;

    .ken-layout--quote & {
      @include ken-text-h2;
    }
  }

  &__cta {
    display: block;
    margin-top: 30px;
    @include ken-text-link;
  }

  &__author {
    margin-top: 30px;
    font-size: 18px;

    @include bp('ken-breakpoint-s') {
      font-size: 14px;
    }
  }
}
