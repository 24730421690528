$maxHeightFacetDesktop: 358px;

.ken-plp {
  &__filters {
    &__desktop-facet {
      position: relative;
      display: inline-block;
      border-right: 1px solid $ken-color-grey-m;
      margin-bottom: 10px;

      &:last-child {
        border-right-color: transparent;
      }
      > button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: var(--fontNameRegular);
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.2px;
        color: $ken-color-black;
        padding: 1px 20px;

        i {
          width: 13px;
          height: 13px;
          transition: all .3s ease-in-out;
          margin-left: 7px;
        }
      }

      &.--open {
        > button i {
          transform: rotate(180deg);
        }
      }

      &__overlay {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
        z-index: 5;
        width: 280px;
        max-height: $maxHeightFacetDesktop;
        overflow-y: hidden;
        left: 0;
        transform: translateY(10px);
        background-color: $ken-color-white;
        border: 1px solid $ken-color-black;

        &.--on-right {
          left: auto;
          right: 0;
        }

        &.--open {
          visibility: visible;
          opacity: 1;
        }

        &__inner {
          position: relative;
          width: 100%;
          max-height: calc(#{$maxHeightFacetDesktop} - 70px);
          overflow-y: auto;
          padding: 15px 15px 0;
          margin-bottom: 70px;
        }

        &__content {
        }

        &__close {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          max-height: 70px;
          overflow: hidden;
          padding: 15px;
          background-color: $ken-color-white;

          button {
            width: auto;
          }
        }
      }
    }
    &__facet-value {
      position: relative;
      background: none;
      border: none;
      border-bottom: 1px solid $ken-color-grey-s;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 20px 12px 0;
      cursor: pointer;

      font-family: var(--fontNameLight);
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      color: $ken-color-black;
      text-align: left;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        background-image: url("#{$iconsPathCore}/check-green.svg");
        opacity: 0;
        transition: opacity .3s ease-in-out;
        width: 20px;
        height: 20px;
      }

      &.--selected {
        font-family: var(--fontNameSemibold);
        &::after {
          opacity: 1;
        }
      }

      &.--last {
        border: 0;
      }

      &.--no-border {
        border:0!important;
      }

      .--color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }

      @include bp('ken-breakpoint-s') {
        font-size: 13px;
        line-height: 1.15;
      }
    }
  }
}