.ken-pdp__promo-banner {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;

  @include bp('ken-breakpoint-s') {
    height: 60px;
    overflow-y: hidden;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 20px;
    flex-direction: column;
    align-items: flex-start;

    @include bp('ken-breakpoint-s') {
      flex-direction: row;
      align-items: center;
    }
  }

  &.--open {
    height: auto;
    overflow-y: unset;

    .ken-pdp__promo-banner {
      &__image,
      &__info__kicker,
      &__info__cta {
        display: block;
      }

      &__wrapper {
        padding: 35px 0 15px;

        @include bp('ken-breakpoint-s') {
          padding: 0;
        }
      }

      &__info {
        flex-direction: column;

        &__cta {
          transform: rotate(180deg);
        }

        &__link {
          margin: 30px 0 0 0;
        }

        &__title {
          font-size: 24px;
        }
      }
    }
  }

  &__image {
    display: none;
    margin-right: 30px;
    max-width: 100%;
    margin-bottom: 20px;

    @include bp('ken-breakpoint-s') {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding-right: 15px;

    @include bp('ken-breakpoint-s') {
      padding-right: 0;
      flex-direction: row;
    }

    &__kicker {
      @include ken-pdp-promo-kicker;
      display: none;
      margin-bottom: 10px;
    }
  
    &__title {
      @include ken-pdp-promo-title;
    }
  
    &__link {
      @include ken-pdp-promo-link;
      display: none;
      margin: 0 0 0 40px;
      transition: none;

      @include bp('ken-breakpoint-s') {
        display: block;
      }
    }

    &__cta {
      border: 0;
      background: transparent;
      margin-left: auto;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform .3s ease-out;
      cursor: pointer;

      .ken-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
