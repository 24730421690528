.ken-plp {
  &__load-more {
    width: 100%;
    text-align: center;
    margin: 20px 0 30px;

    &__text {
      font-family: var(--fontNameLight);
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $ken-color-black;
      margin-bottom: 20px;
    }
  }
}