.ken-helpmechoose {
  position: relative;
  overflow: hidden;
  color: $ken-color-white;

  &-wrapper {
    position: relative;
    margin: 0 20px;
  }

  &__picture {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &-image {
      width: 100%;
      max-width: 100%;
    }
  }

  &__mask {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $ken-color-black-mask-50;

    @include bp('ken-breakpoint-s') {
      height: 100%;
    }
  }

  &__textblock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    background-color: $ken-color-none;
    color: $ken-color-white;

    &-titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-kicker,
    &-title {
      text-align: center;
    }

    &-kicker {
      margin: 0 0 10px;
      @include ken-text-small-light;
      color: inherit;
      font-family: var(--fontNameRegular);
      text-transform: uppercase;
      letter-spacing: 1px;

    }

    &-title {
      @include ken-text-h2;
      color: inherit;
    }
  }

  &-cta {
    @extend .ken-button--primary--small;
    font-size: 11px;
    margin-top: 30px;
    align-self: center;
    text-transform: uppercase;
  }

  @include bp('ken-breakpoint-s') {
    &-wrapper {
      margin: 0;
      padding: 0;
    }

    &__picture {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;

      &-image {
        width: auto;
        max-width: 100%;
        min-width: 70.555% !important;
      }
    }

    &__textblock {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      margin: 0;
      width: auto;
      justify-content: center;
      align-items: center;
      background-color: $ken-color-none;
      color: inherit;

      &-titles {
        align-items: center;
        max-width: 460px;
      }

      &-kicker,
      &-title {
        text-align: center;
      }

      &-title {
        line-height: 1.25;
        color: inherit;
      }

      &-kicker {
        margin-bottom: 5px;
        line-height: 1.67;
      }
    }

    &-cta {
      align-self: center;
      margin-top: 15px;
      padding: 14px 20px 11px;
      font-size: 10px;
      line-height: 14px;
    }
  }
}
