.ken-header-v2 {
  &__category-tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 163px;
    height: 183px;
    border-radius: 4px;
    background-color: $ken-color-grey-xxs;
    text-decoration: none;
    color: $ken-color-black;
    transition: transform 0.3s ease;

    @include bp('ken-breakpoint-m') {
      width: 173px;
      height: 230px;
    }

    @include bp('ken-breakpoint-xl') {
      width: 213px;
      height: 233px;
    }

    &:hover {
      transform: scale(0.95);

      picture {
        transform: scale(1.1);
      }

      .ken-header-v2__category-tile__title {
        color: $ken-color-rhubarb;
      }
    }

    picture {
      transition: transform 0.3s ease;
    }

    .ken-header-v2__category-tile__title {
      transition: color 0.3s ease;
    }

    &.--view-all {
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: center;

      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: $ken-color-white;
      }
    }

    &__figure {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 6px 13px;

      @include bp('ken-breakpoint-m') {
        padding: 7px 6px;
        gap: 8px;
      }

      .ken-header-v2__category-tile.--product & {
        gap: 8px;

        @include bp('ken-breakpoint-m') {
          padding: 12px 6px;
        }
      }
    }

    &__picture {
      width: 140px;
      height: 119px;
      max-width: 100%;
      border-radius: 4px;

      @include bp('ken-breakpoint-m') {
        width: 159px;
        height: 159px;
      }

      @include bp('ken-breakpoint-xl') {
        width: 181px;
        height: 153px;
      }

      .ken-header-v2__category-tile.--product & {
        width: 92px;
        height: 92px;

        @include bp('ken-breakpoint-m') {
          width: 117px;
          height: 117px;
        }
      }
    }

    &__image {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
    }

    &__figcaption {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      min-height: 31px;
      text-align: center;

      @include bp('ken-breakpoint-m') {
        min-height: 50px;
      }

      .ken-header-v2__category-tile.--product & {
        justify-content: flex-start;
        min-height: 54px;
      }
    }

    &__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      @include bp('ken-breakpoint-xl') {
        font-size: 16px;
      }

      .ken-header-v2__category-tile.--product & {
        font-size: 14px;
      }
    }

    &__subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
}