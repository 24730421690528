.ken-articletext {
  width: 100%;
  padding: 0 20px;
  margin: 30px auto;
  background-color: $ken-color-white;

  @include bp('ken-breakpoint-m') {
    padding: 0;
    margin: 50px auto;
  }

  ul, ol, p, table {
    @include ken-text-mont-body-light;
    margin: 30px 0 60px;
  }

  li {
    @include ken-text-mont-body-light;
    list-style-position: inside;
  }

  table {
    border: 1px solid var(--ui-04);
    border-collapse: collapse;
  }

  td, th {
    @include ken-text-mont-body-light;
    text-align: left;
    padding: 8px;
  }

  a, td a, p a, li a {
    @include ken-text-link;
  }
}
