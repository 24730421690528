.ken-product-tile {
  &__bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    @include bp('ken-breakpoint-s') {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translateY(100%);
      width: calc(100% + 2px);
      margin: 0 -1px;
      padding: 0 15px 15px;
      border: 1px solid $ken-color-black;
      border-top: 0;
      visibility: hidden;
      opacity: 0;
      background-color: $ken-color-white;
      transition: all .3s ease 0s;
    }

    .--lite & {
      @include bp('ken-breakpoint-max-s', 'max') {
        display: none;
      }
    }
  }

  &.hover,
  &:hover {
    .ken-product-tile__bottom {
      opacity: 1;
      visibility: visible;
    }
  }
}