.dln-kenwood {
  .dln-paperless-nav {
    width: 100%;
    position: fixed;
    top: 96px;
    background-color: $ken-color-white;
    top: -50px;
    transition: top 0.15s ease;
    z-index: 10;

    @include bp('ken-breakpoint-m') {
      top: -67px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    }

    &__out-wrapper {
      width: 100%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }

    &__product-name {
      display: none;

      @include bp('ken-breakpoint-m') {
        display: block;
        padding-left: 20px;
        font-size: 14px;
        padding-right: 60px;
        margin-right: 50px;
        font-family: var(--fontNameBold);
        border-right: 1px solid $ken-color-grey-m;
      }
    }

    &__wrapper {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &-fade {
        background: linear-gradient(269deg, rgba(255, 255, 255, 100) 0.53%, rgba(255, 255, 255, 0.00) 88.78%);
        height: 50px;
        width: 40px;
        position: absolute;
        top: 0;
        right: 0;

        @include bp('ken-breakpoint-m') {
          height: 67px;
        }
      }
    }

    &__wrapper::-webkit-scrollbar {
      display: none;
    }

    &__links {
      height: 50px;
      display: flex;
      align-items: center;
      padding-right: 20px;

      @include bp('ken-breakpoint-m') {
        height: 67px;
      }
    }

    &__link {
      text-decoration: none;
      flex-shrink: 0;
      font-family: var(--fontNameSemibold);
      font-size: 12px;
      color: $ken-color-black;
      margin-right: 18px;
      text-transform: uppercase;

      &:first-of-type {
        margin-left: 18px;
      }

      &:last-of-type {
        margin-right: 0;
        padding-right: 40px;
      }

      &.--hidden {
        display: none;
      }

      &.--active {
        text-decoration: underline;
        color: $ken-color-rhubarb;
      }

      &:hover {
        @include bp('ken-breakpoint-m') {
          text-decoration: underline;
          color: $ken-color-rhubarb;
        }
      }
    }
  }
}

// Header impact Paperless
.page-dlgKenwoodPaperless {

  // When the blue stripe is hidden
  &.ken-header--hidden {
    .dln-paperless-nav {
      @include bp('ken-breakpoint-m') {
        top: 94px;
      }
    }
  }

  // When the header is hidden
  &.ken-header--hidden {
    .ken-header {
      @include bp('ken-breakpoint-max-m', 'max') {
        transition: top 0.3s ease;
        top: -62px;
      }
    }

    .dln-paperless-nav {
      top: 0px;

      @include bp('ken-breakpoint-m') {
        top: 0px;
      }
    }
  }
}