.ken-titleimage {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &--imgcanvas {
    display: flex;
    height: 50px;
    width: 50px;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &--img {
    height: auto !important;
    width: auto !important;
    max-height: 100%;
    max-width: 100%;
  }

  &--text {
    height: 20px;
    overflow: hidden;
    margin-top: 10px;
    line-height: 20px;
    font-family: var(--fontNameSemibold);
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    text-shadow: 0 0 1px #444;
  }
}
