.ken-editorialarticlelist {
  &__article {
    display: flex;
    flex-direction: column;
    width: 334px;
    padding: 20px 0;

    @include bp('ken-breakpoint-m') {
      padding: 0;
    }

    &.article {
      &--default {
        @include bp('ken-breakpoint-m') {
          width: 490px;
        }
      }

      &--big {
        @include bp('ken-breakpoint-m') {
          width: 590px;
        }
      }

      &--small {
        @include bp('ken-breakpoint-m') {
          width: 388px;
        }
      }
    }

    &__image {
      width: 100%;

      &.image--mobile {
        height: 218px;

        @include bp('ken-breakpoint-m') {
          display: none;
        }
      }

      &.image--desktop {
        display: none;
        height: 320px;

        @include bp('ken-breakpoint-m') {
          display: inline;
        }
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 2;
    }

    &__text-wrapper {
      padding: 20px 0;
    }

    &__title {
      margin-bottom: 15px;
      font-family: var(--fontNameSemibold);
      font-size: 18px;
      font-weight: 600;
      line-height: 1.22;
      color: $ken-color-black;

      @include bp('ken-breakpoint-m') {
        font-size: 20px;
        line-height: 1.2;
      }
    }

    &__description {
      font-family: var(--fontNameRegular);
      font-size: 14px;
      font-weight: 300;
      line-height: 1.43;
      color: $ken-color-black;
    }

    &__link {
      font-family: var(--fontNameSemibold);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: $ken-color-black;
      text-decoration: underline;
    }
  }
}
