.ken-pdp__gallery {
  display: flex;
  flex-direction: column-reverse;

  @include bp('ken-breakpoint-m') {
    flex-direction: row;
  }

  &__main,
  &__thumbs {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      @include bp('ken-breakpoint-m') {
        align-items: flex-start;
      }

      img, video {
        max-width: 100%;
        width: auto;
        height: auto;
        display: block;
      }
    }
  }

  &__thumbs {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;

    @include bp('ken-breakpoint-m') {
      order: -1;
      width: 82px;
      margin-top: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    &-images {
      padding: 0 3px;
      position: relative;

      @include bp('ken-breakpoint-m') {
        width: 82px;
        max-height: 360px;
        &.--padding {
          margin-top: 6px !important;
        }
      }

    }

    .swiper-slide {
      border: 1px solid $ken-color-grey-m;
      width: 72px;
      height: 72px;
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }

      &.--active {
        border-color: $ken-color-black;
      }

      @include bp('ken-breakpoint-m') {
        width: 82px;
        height: 82px;
        margin: 0 0 10px;
      }

    }

    .swiper-arrow {
      &--up {
        @extend .ken-icon;
        @extend .ken-icon--arrow-left-grey-xxl;
        height: 13px;
        width: 13px;
        display: flex;
        align-self: center;
        margin: 0 9px 0 0;
      }

      &--down {
        @extend .ken-icon;
        @extend .ken-icon--arrow-right-grey-xxl;
        height: 13px;
        width: 13px;
        display: flex;
        align-self: center;
        margin: 0 0 0 9px;

      }
    }

    .swiper-prev {
      background-color: transparent;
      @extend .swiper-arrow--up;
      flex: 0 0 auto;
      border: none;
      @include bp('ken-breakpoint-m') {
        background-image: url("#{$pathAssets}/images/kenwood/icons/small/up-grey-darkest.svg");
        margin: 7px 0 0 0;
      }

      &.swiper-button-disabled {
        @extend .ken-icon--arrow-left-grey-l;
        @include bp('ken-breakpoint-m') {
          background-image: url("#{$pathAssets}/images/kenwood/icons/small/up-grey-dark.svg");
        }
      }
    }

    .swiper-next {
      background-color: transparent;
      @extend .swiper-arrow--down;
      flex: 0 0 auto;
      border: none;
      @include bp('ken-breakpoint-m') {
        margin: 0;
        background-image: url("#{$pathAssets}/images/kenwood/icons/small/down-grey-darkest.svg");
      }

      &.swiper-button-disabled {
        @extend .ken-icon--arrow-right-grey-l;

        @include bp('ken-breakpoint-m') {
          background-image: url("#{$pathAssets}/images/kenwood/icons/small/down-grey-dark.svg");
        }
      }
    }

    &-video {
      width: 72px;
      display: flex;
      align-items: center;
      margin-right: 18px;
      flex: 0 0 auto;

      @include bp('ken-breakpoint-m') {
        margin-right: 0;
      }

      &-img-wrapper {
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::after {
          background-color: rgba($ken-color-rhubarb, .3);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &::before {
          @extend .ken-icon;
          @extend .ken-icon--play-white;
          width: 30px;
          height: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      }

      img {
        display: block;
        max-width: 100%;
        position: relative;
      }

      @include bp('ken-breakpoint-m') {
        width: 82px;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__main {
    width: 100%;
    height: calc(100vw - 20px);

    @include bp('ken-breakpoint-s') {
      height: auto;
    }

    @include bp('ken-breakpoint-m') {
      width: calc(100% - 200px);
      margin: 0 auto;
    }

    &.--hidden {
      display: none;
    }

    &-video {
      align-self: center;

      .plyr--video {
        background-color: transparent;
      }
    }

    .swiper-button {

      &-prev,
      &-next {
        @include bp('ken-breakpoint-s') {
          &::after {
            border: 1px solid $ken-color-black;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
          }
        }
      }
    }

  }
}
