.page-kenConfiguratorPageStep1,
.page-kenConfiguratorPageStep2,
.page-kenConfiguratorPageStep3 {
  .ken-header__gap {
    height: 67px;

    @include bp('ken-breakpoint-l') {
      height: 56px;
    }
  }

  .ken-footer {
    padding-top: 0;
  }
}

.ken-configurator {
  max-width: 1440px;
  margin: 0 auto;
}

.ken-configurator__step {

  &-one,
  &-two {
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-m') {
      padding: 45px 0;
      background-color: #EFECEA;
      display: flex;
    }
  }

  &-one {
    display: flex;
    flex-direction: column-reverse;

    @include bp('ken-breakpoint-m') {
      flex-direction: row;
      justify-content: center;
    }
  }

  &-two {
    background-color: $ken-color-white;

    &-menu {
      z-index: 1;

      @include bp('ken-breakpoint-m') {
        width: 285px;
        min-width: 285px;
        height: 430px;
        margin: 0 10px;
      }

      @include bp('ken-breakpoint-l') {
        margin-right: 30px;
      }

      @include bp('ken-breakpoint-xl') {
        margin-right: 55px;
      }
    }

    &-summary {
      @include bp('ken-breakpoint-m') {
        background-color: #EFECEA;
        display: block;
        height: 430px;
        width: 100%;
        max-width: 900px;
        margin-right: 10px;
      }
    }
  }

  &-three {
    background-color: $ken-color-white;

    @include bp('ken-breakpoint-m') {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}


.-hidden {
  display: none;
}

.-hidden-s {
  @include bp('ken-breakpoint-s') {
    display: none;
  }
}

.-hidden-m {
  @include bp('ken-breakpoint-m') {
    display: none;
  }
}

.-hidden-l {
  @include bp('ken-breakpoint-l') {
    display: none;
  }
}

.-hidden-xl {
  @include bp('ken-breakpoint-xl') {
    display: none;
  }
}

.-visible {
  display: block;
}

.-visible-s {
  @include bp('ken-breakpoint-s') {
    display: block;
  }
}

.-visible-m {
  @include bp('ken-breakpoint-m') {
    display: block;
  }
}

.-visible-l {
  @include bp('ken-breakpoint-l') {
    display: block;
  }
}

.-visible-xl {
  @include bp('ken-breakpoint-xl') {
    display: block;
  }
}