.ken-relatedproducts {
  @include ken-swiper-scrollbar;
  padding: 40px 20px;

  .swiper-scrollbar {
    margin-top: 30px;
    position: initial;
    width: 100%;
  }

  @include bp('ken-breakpoint-s') {
    padding: 67px 20px 53px;

    .swiper-scrollbar {
      display: none;
    }
  }

  @include bp('ken-breakpoint-m') {
    padding: 67px 0 53px;
  }

  &__title {
    @include ken-text-h2;
    text-align: center;
    margin-bottom: 30px;
  }

  &__content {
    margin-bottom: 30px;

    @include bp('ken-breakpoint-s') {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    @include bp('ken-breakpoint-l') {
      padding: 30px 0;
      margin-bottom: 0;
    }

    .ken-product-tile {
      width: 100%;
      padding: 10px;
      width: calc(50% - 5px);

      @include bp('ken-breakpoint-s') {
        width: calc(50% - 10px);
        margin: 0 5px 20px 5px;

        border: 1px solid transparent;
        &:hover {
          border-color: $ken-color-black;

        }
      }

      @include bp('ken-breakpoint-m') {
        width: calc(33.333333333% - 14px);
      }

      @include bp('ken-breakpoint-l') {
        width: calc(25% - 15px);
      }

      &__image a {
       display: inline-block;
      }
    }
  }
}