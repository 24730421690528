.ken-header-v2 {
  &__buttons {
    display: flex;
    gap: 16px;

    .ken-btnIcon {
      display: flex;
      width: 20px;
      height: 20px;
      border: none;
      background: none;
      cursor: pointer;

      &__wrap {
        position: relative;
        width: 20px;
        height: 20px;

        .ken-miniCart.dln-miniCart {
          right: 362px;
        }
      }

      a,
      button {
        display: flex;
        padding: 0;
      }

      .ken-icon {
        width: 20px;
        height: 20px;
      }

      &--wishlist,
      &--headphones,
      &--account {
        display: none;

        @include bp('ken-breakpoint-m') {
          display: block;
        }

        .ken-drawer-padding {
          display: none;
        }
      }

      &--account {
        display: block;

        .ken-icon--user-loggedin {
          font-size: 10px;
        }
      }

      &--account.hover,
      &--account:hover {
        .ken-drawer-padding {
          position: relative;
          display: block;
          width: 100%;
          height: 6px;
        }

        .dln-drawer--account {
          display: flex;
        }
      }

      &--headphones {
        position: relative;
      }

      &--headphones.hover,
      &--headphones:hover {
        .ken-drawer-padding {
          position: relative;
          display: block;
          width: 100%;
          height: 6px;
        }

        .dln-drawer--customer-support {
          display: flex;
        }
      }

      &__checkoutCounter {
        position: absolute;
        top: -4px;
        right: -10px;
        display: flex;
        align-items: center;
        align-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $ken-color-rhubarb;

        &Text {
          display: inline-block;
          width: auto;
          height: 10px;
          margin: 0 auto;
          padding: 0;
          font-family: var(--fontNameBold);
          font-size: 12px;
          font-weight: 700;
          line-height: 100%;
          text-align: center;
          color: $ken-color-white;
        }
      }

      .utilityLinks {
        a {
          text-decoration: none;
        }
      }
    }
  }

  &__button-icon {
    width: 20px;
    height: 20px;

    &.ken-icon--hamburger {
      display: block;

      @include bp('ken-breakpoint-m') {
        display: none;
      }
    }

    &.ken-icon--close {
      display: block;
    }
  }
}
