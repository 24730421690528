.ken-plp {
  &__wrapper {
    background-color: $ken-color-grey-xs;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 10px;

    &.ken-wrapper--big-grid {
      max-width: 1220px !important;
    }

    @include bp('ken-breakpoint-l') {
      padding: 30px 0;
    }

    .ken-product-tile {
      width: calc(100% - 20px);
      padding: 10px;
      margin: 0 10px 20px;

      @include bp('ken-breakpoint-s') {
        width: calc(50% - 20px);
        border: 1px solid transparent;
        &:hover {
          border-color: $ken-color-black;
          border-bottom-color: transparent;
        }
      }

      @include bp('ken-breakpoint-m') {
        width: calc(33.3% - 20px);
      }

      @include bp('ken-breakpoint-l') {
        width: calc(25% - 20px);
      }
      &__imgWrap {
        display: flex;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
