.ken-promotext {
  background: $ken-color-white;
  padding: 0 20px;

  &--center {
    text-align: center;
  }

  &__title {
    @include ken-text-h1;
    margin-bottom: 15px;

    &.--montserrat-bold {
      font-family: var(--fontNameBold);
      font-weight: bold;
    }
  }

  &__description,
  &__description * {
    font-size: 16px;

    ul, ol {
      margin: 20px 0 20px 15px;
      li {
        padding-left: 3px;
      }
    }

    ol {
      margin-left: 18px;
    }

    a {
      @include ken-button--link;
      font-size: inherit;
      font-family: inherit;
    }

    .ken-promotext--center & {
      ul,
      ol {
        margin-left: 0;
        display: inline-block;
        text-align: left;
        max-width: 60%;
        @include bp('ken-breakpoint-s') {
          max-width: 50%;
        }
      }
    }
  }

  &__link {
    margin-top: 20px;
  }
}
