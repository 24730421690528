.ken-categorycarousel {
  display: flex;
  height: auto;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  color: $ken-color-black;

  @include bp('ken-breakpoint-s') {
    position: relative;
  }

  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      padding: 0;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 130px;
        min-width: 130px;
        padding: 30px;
        text-align: center;

        @include bp('ken-breakpoint-s') {
          padding: 0 0 10px;
          width: 184px;
          min-height: 150px;
          max-width: 184px;
          border-bottom-width: 3px;
          border-bottom-color: transparent;
          border-bottom-style: solid;
        }

        a,
        a .ken-titleimage--text {
          min-height: 30px;
          color: $ken-color-black;
          font-family: var(--fontNameRegular);
          font-size: 12px;
          font-weight: bold;
          line-height: 15px;
          letter-spacing: normal;
          text-decoration: none;
          text-transform: uppercase;
          text-shadow: none;
        }

        .ken-titleimage {

          &--imgcanvas {
            height: 72px;
            width: 130px;

            @include bp('ken-breakpoint-s') {
              height: 84px;
              width: 184px;

            }
          }
        }

        .ken-titleimage--text {
          height: auto;
        }

        &--active,
        &:hover {
          @include bp('ken-breakpoint-s') {
            /*
            border-bottom-color: $ken-color-rhubarb;
            */

            a,
            a .ken-titleimage--text {
              font-family: var(--fontNameBold);
              font-weight: bold;
            }
          }
        }

      }

      @include bp('ken-breakpoint-s') {
        padding: 0;

        &.ken-swiper-idle {
          justify-content: space-evenly;
        }
      }
    }

    @include bp('ken-breakpoint-s') {
      max-width: 79.8334%;
    }
  }

  &__img {
    $size: 40px;
    height: $size;
    width: $size;
  }

  &__text {
    font-family: var(--fontNameRegular);
    font-size: 14px;
    line-height: 1.57;
  }


  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;

    &.swiper-button-lock {
      display: none !important;
    }

    &::after {
      display: none !important;
      text-indent: -99999cm;
    }

    &.swiper-button-disabled {
      cursor: not-allowed;
    }

    @include bp('ken-breakpoint-s') {
      display: flex !important;
      margin: 8px;
      top: calc(50% - 30px);
    }

    &:not(.ken-icon-activewrap),
    &.ken-icon-activewrap::after {
      @extend .ken-icon;
      height: 17px;
      width: 17px;
    }
  }

  .swiper-button-prev {
    left: 3px;
  }
  .swiper-button-next {
    right: 3px;
  }

  .swiper-button-prev::after,
  .swiper-button-prev:not(.ken-icon-activewrap) {
    @extend .ken-icon--arrow-left-rhubarb;

    &.swiper-button-disabled:not(.ken-icon-activewrap),
    &.swiper-button-disabled.ken-icon-activewrap::after {
      @extend .ken-icon--arrow-left-grey;
    }
  }

  .swiper-button-next::after,
  .swiper-button-next:not(.ken-icon-activewrap) {
    @extend .ken-icon--arrow-right-rhubarb;

    &.swiper-button-disabled:not(.ken-icon-activewrap),
    &.swiper-button-disabled.ken-icon-activewrap::after {
      @extend .ken-icon--arrow-right-grey;
    }
  }
}
