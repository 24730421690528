.ken-header-v2 {
  .dln-drawer {
    &__title {
      margin: 0 0 16px;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: $ken-color-rhubarb;
    }

    &--customer-support {
      position: relative;
      visibility: visible;
      flex-direction: column;
      min-width: max-content;
      height: fit-content;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid $ken-color-grey-m;
      border-top: 2px solid $ken-color-rhubarb;
      text-align: start;

      [dir="rtl"] & {
        transform: translateX(76px);
      }

      &:before {
        left: 50%;
        bottom: unset;
        top: -8px;
        transform: translateX(-50%);
        margin-left: 0;
        border: 3px solid transparent;
        border-bottom-color: $ken-color-rhubarb;
      }

      &:after {
        display: none;
      }

      .utilityLinks {
        display: flex;
        flex-direction: column;

        a {
          margin-bottom: 10px;
          font-family: var(--fontNameRegular);
          font-size: 12px;
          letter-spacing: 0.2px;
          color: $ken-color-black;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
