.ken-padding {
  width: 100%;

  &.ken-padding--xl,
  &.ken-padding--l {
    height: 100px;
  }

  &.ken-padding--xl {
    @include bp('ken-breakpoint-m') {
      height: 150px;
    }
  }

  &.ken-padding--l {
    @include bp('ken-breakpoint-m') {
      height: 100px;
    }
  }

  &.ken-padding--m {
    height: 60px;

    @include bp('ken-breakpoint-m') {
      height: 70px;
    }
  }

  &.ken-padding--s {
    height: 30px;

    @include bp('ken-breakpoint-m') {
      height: 50px;
    }
  }

  &.ken-padding--xs {
    height: 20px;

    @include bp('ken-breakpoint-m') {
      height: 30px;
    }
  }
}
