.ken-articleblock-two {
  width: 100%;
  padding: 0 20px 0;
  margin: 60px auto 70px;
  background-color: $ken-color-white;

  @include bp('ken-breakpoint-m') {
    padding: 0;
    margin: 50px auto;
  }

  &__title {
    margin-bottom: 15px;
    @include ken-text-h3;
    line-height: 1.21;

    @include bp('ken-breakpoint-m') {
      line-height: 1.25;
    }
  }

  &__paragraph,
  & p{
    @include ken-text-mont-body-light;
    line-height: 1.43;

    &.--paragraph-one {
      margin: 15px 0 30px;
    }

    &.--paragraph-two {
      margin: 30px 0 60px;
    }

    strong {
      font-weight: bold;
    }

    a {
      @include ken-text-link;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    margin: 30px 0;

    &.image--mobile {
      display: block;

      @include bp('ken-breakpoint-s') {
        display: none;
      }
    }

    &.image--desktop {
      display: none;

      @include bp('ken-breakpoint-s') {
        display: block;
      }
    }
  }

  &__bottom-line {
    border: 0;
    border-bottom: 1px solid $ken-color-grey-l;
  }

  ul, ol, p, table {
    @include ken-text-mont-body-light;
    margin: 30px 0 60px;
  }

  li {
    @include ken-text-mont-body-light;
    list-style-position: inside;
  }

  table {
    border: 1px solid var(--ui-04);
    border-collapse: collapse;
  }

  td, th {
    @include ken-text-mont-body-light;
    text-align: left;
    padding: 8px;
  }

  a, td a, p a, li a {
    @include ken-text-link;
  }
}
