.ken-compare {
  &__popup__content {
    flex: 1;
    overflow-y: auto;
  }

  &__popup__inner-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}