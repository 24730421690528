.ken-pdp__main-info {
  &__stock {
    &__out-of-stock {
      display: block;
      color: $ken-color-white;
      background-color: $ken-color-purple;
      text-transform: uppercase;
      padding: 10px 12px;
      align-items: flex-end;
    }

    &__in-stock {
      display: flex;
      color: $ken-color-stock;
      font-size: 14px;
      align-items: center;

      .ken-icon {
        height: 21px;
        width: 21px;
        position: relative;
        top: -1px;
      }
    }

    &__low-stock {
      text-transform: uppercase;
      color: $ken-color-purple;
    }
  }
}
