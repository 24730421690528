.ken-hero-parallax {
  position: relative;

  &__picture-image {
    display: block;
    object-fit: cover;
    max-width: 100%;

    @include bp('ken-breakpoint-xl') {
      max-width: 1440px;
    }
  }

  &.--reveal {
    .ken-hero-parallax__picture-image {
      position: absolute;
      z-index: -1;
      top: 0;
      transform: translateY(-100%);
      height: 100vh;
      visibility: hidden;

      @include bp('ken-breakpoint-s') {
        height: auto;
        max-width: 100%;
      }

      @include bp('ken-breakpoint-xl') {
        max-width: 1440px;
      }
    }
  }

  &__contentMedia {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding-inline: 10%;
  }

  &__content {
    z-index: 10;
    padding-block: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  &__title,
  &__subtitle {
    color: $ken-color-white;
    text-align: center;
    max-width: 796px;
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-family: var(--fontNameSemibold);
    font-weight: 600;
    @include bp('ken-breakpoint-s') {
      font-size: 72px;
      line-height: 80px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 32px;
    font-family: var(--fontNameRegular);
    padding-inline: 5%;
    @include bp('ken-breakpoint-s') {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
