.ken-product-recall {
  background-color: $ken-color-grey-xs;
  padding: 60px 35px;
  max-width: 1440px;
  margin: 0 auto;

  @include bp('ken-breakpoint-m') {
    padding: 50px 10px;
  }

  &__wrapper {
    @include bp('ken-breakpoint-m') {
      max-width: 745px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    @include bp('ken-breakpoint-m') {
      padding-bottom: 0;
      margin-right: 30px;
    }

    &-img {
      width: 63px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include bp('ken-breakpoint-m') {
      display: block;
    }

    &-text {
      text-align: center;
      font-size: 14px;
      color: $ken-color-grey-xxl;
      font-family: var(--fontNameRegular);
    }

    &-link {
      margin-top: 10px;
      color: $ken-color-rhubarb;
      font-size: 14px;
      font-family: var(--fontNameBold);

      @include bp('ken-breakpoint-m') {
        margin-top: 0;
      }
    }
  }
}